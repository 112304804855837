import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { User } from '../api/users/type';
import avatarImg from '../assets/icons/icon-avatar.svg';

interface MissionUserInfoProps {
  users: User[];
}

export const MissionUserInfo: FC<MissionUserInfoProps> = ({ users }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex justify-center">
        <div className="flex">
          {users.slice(0, users.length).map((user, i) => (
            <div
              key={user.id}
              className={`${
                i > 0 && '-ml-3'
              } wh-7 relative overflow-hidden rounded-full border-4 border-gray-50`}
            >
              <img
                key={user.id}
                src={isEmpty(user.avatar) ? avatarImg : user.avatar}
                className="absolute h-full w-full"
              />
            </div>
          ))}
        </div>

        {users.length > 6 && <p className="">...</p>}
      </div>

      <p className="text-14">
        <span className="font-bold">{users.length}명</span>이 함께하는 중!
      </p>
    </div>
  );
};
