import React, { useEffect, useRef, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FileType } from '../../api/files/enum';
import { agreeSensitivePolicy } from '../../api/users';
import TestExampleImg from '../../assets/img/dna-test-example.png';
import { Button } from '../../components/Button';
import { TopNavBar } from '../../components/TopNavBar';
import { useModal } from '../../components/modal/Modal';
import { useImageUpload } from '../../hooks/files';
import { Action, Bridge } from '../../plugins/bridge';
import {
  getItemInLocalStorage,
  LOCAL_STORAGE_KEY,
  removeItemInLocalStorage,
  setItemInLocalStorage,
} from '../../plugins/local-storage';
import { fetcher } from '../../plugins/react-query';
import { currentRefState } from '../../plugins/ridge';
import { User } from '../../types';

export const StepFourPage = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { goBack, push } = useHistory();
  const [imageUrl, setImageUrl] = useState(TestExampleImg);
  const [categoryName, setCategoryName] = useState('');
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [agreement, setAgreement] = useState(false);

  const { isUploading, handleImageChange } = useImageUpload();
  const { data: me } = useQuery<User>('users/me', fetcher);
  const { checkSensitiveAgreement } = useModal();

  useEffect(() => {
    const category = getItemInLocalStorage(LOCAL_STORAGE_KEY.CATEGORY);
    const categoryName = get(category, 'name', '');
    if (isEmpty(categoryName)) {
      goBack();
      toast('잘못된 접근입니다.');
    } else {
      setCategoryName(categoryName);
    }
  }, []);

  const _handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newImage = e.target.files?.item(0);
    if (!newImage) return;
    const url = await handleImageChange(newImage, FileType.TEST_RESULT);
    if (!url) return;
    setImageUrl(url);
    setIsImageUploaded(true);
  };

  const handleNext = () => {
    if (!isEmpty(imageUrl)) {
      setItemInLocalStorage(LOCAL_STORAGE_KEY.TEST_INFO, {
        testResultImage: imageUrl,
      });
    }
    push('/dna-test/5');
  };

  const handleNextWithoutImg = () => {
    removeItemInLocalStorage(LOCAL_STORAGE_KEY.TEST_INFO);
    push('/dna-test/5');
  };

  const checkAgreement = () => {
    if (me?.sensitiveAgreement || agreement) {
      useCamera();
    } else {
      checkSensitiveAgreement(onAgreeSensitivePolicy, () =>
        push('/dna-test/5')
      );
    }
  };

  const onAgreeSensitivePolicy = () => {
    setAgreement(true);
    agreeSensitivePolicy();
    useCamera();
  };

  const useCamera = () => {
    currentRefState.set(inputRef.current);
    Bridge.postMessage(Action.CHECK_CAMERA_PERMISSION, '');
  };

  return (
    <>
      <TopNavBar />

      <h2 className="pt-8 pb-6 leading-normal">
        선택한 <span className="text-brand-1">‘{categoryName}’</span>에 대한
        검사
        <br />
        결과지를 예시와 같이 유전자 정보가 나오게 촬영하여 올려주세요.
      </h2>

      <div className="relative h-44 w-full overflow-hidden rounded-lg">
        <img
          src={`${imageUrl}`}
          alt=""
          className="absolute h-full w-full bg-gray-100 object-cover"
        />
        <input
          ref={inputRef}
          type="file"
          id="test-result"
          className="hidden"
          accept="image/*"
          onChange={_handleImageChange}
        />
      </div>

      <p className="pb-36 pt-5 text-14 text-gray-500">
        유전자 정보 이외의 다른 개인정보는 나오지 않게 주의하여 촬영하여 주세요.
        유전자 정보는 맞춤형 건강정보 제공을 위해 필요합니다. 어떠한 경우에도 제
        3자에게 제공되지 않으며 아이힐미에서 안전하게 보관합니다.
        <br />
        <br />
        지금 이 단계를 건너 뛸 수 있으며, “마이“ 메뉴에서 언제든지 업데이트 할
        수 있습니다
      </p>

      <div className="fixed bottom-4 -mx-5 flex w-full flex-col space-y-4 px-5 ">
        {isImageUploaded ? (
          <Button className="filled-brand-1" text="다음" onClick={handleNext} />
        ) : (
          <Button
            disabled={isUploading}
            className="filled-brand-1"
            text="촬영하고 +50P 받기"
            onClick={() => {
              checkAgreement();
            }}
          />
        )}
        <Button
          className="outlined-brand-1 bg-white"
          text="나중에 올릴래요."
          onClick={handleNextWithoutImg}
        />
      </div>
    </>
  );
};
