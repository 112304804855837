import React, { useState } from 'react';
import { FC } from 'react';
import { filter } from 'lodash';
import { Icon } from './Icon';
export interface SelectMenuOption {
  label: string;
  value: number;
}
interface SelectMenuProps {
  options: SelectMenuOption[];
  onChange: (option: SelectMenuOption) => void;
  selectedId: number;
}
export const SelectMenu: FC<SelectMenuProps> = ({
  options,
  onChange,
  selectedId,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const selectedLabel = filter(
    options,
    (option) => option.value === selectedId
  )[0].label;

  return (
    <div className="rounded-lg border">
      <div className="relative">
        <button
          type="button"
          className="relative w-full cursor-pointer p-5 text-left"
          aria-haspopup="listbox"
          aria-expanded="true"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <h3 className=" text-brand-1">{selectedLabel}</h3>
          <span className="pointer-events-none absolute inset-y-0 right-1 flex items-center pr-2">
            <Icon.ChevronDown
              className={`${
                menuOpen && 'rotate-180 transform transition-transform'
              } `}
            />
          </span>
        </button>

        <ul
          className={`absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition duration-100
ease-in focus:outline-none sm:text-sm ${
            menuOpen ? 'opacity-100' : 'hidden opacity-0'
          }`}
          tabIndex={-1}
          role="listbox"
          aria-activedescendant="listbox-option-3"
        >
          {options.map((option) => {
            const selected = selectedId === option.value;
            return (
              <li
                key={option.value}
                className={`relative cursor-default select-none px-5 py-3 text-gray-900 hover:bg-gray-100 ${
                  menuOpen ? '' : 'pointer-events-none'
                }`}
                id="listbox-option-0"
                onClick={() => {
                  setMenuOpen(false);
                  onChange(option);
                }}
              >
                <span
                  className={`block truncate ${
                    selected ? 'font-semibold' : 'font-normal'
                  }`}
                >
                  {option.label}
                </span>
                {selected && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-brand-1">
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
                    </svg>
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
