import { stringify } from 'qs';
import { api } from '../../plugins/axios';
import { PATHS } from './paths';
import {
  GetUserMissionsResponse,
  GetUserMissionsByUserDto,
  GetUserMissionsByGroupDto,
} from './type';

export async function getUserMissionsByGroupId(
  getUserMissionsDto: GetUserMissionsByGroupDto
) {
  const queryString = stringify(getUserMissionsDto, {
    addQueryPrefix: true,
    encode: false,
  });
  const { data } = await api.get<GetUserMissionsResponse>(
    `/${PATHS.ROOT}${queryString}`
  );
  return data.userMissions;
}

export async function getUserMissionsByUserId(
  getUserMissionsDto: GetUserMissionsByUserDto
) {
  const queryString = stringify(getUserMissionsDto, {
    addQueryPrefix: true,
    encode: false,
  });
  const { data } = await api.get<GetUserMissionsResponse>(
    `/${PATHS.ROOT}${queryString}`
  );
  return data.userMissions;
}
export async function updateUserMission(id: number) {
  return api.patch(PATHS.getUserMissionById(id));
}
