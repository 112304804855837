import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  LOCAL_STORAGE_KEY,
  removeItemInLocalStorage,
  setItemInLocalStorage,
} from '../../plugins/local-storage';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { modalState, playAnimState } from '../../plugins/ridge';
import { Button } from '../Button';
import { AnimationLayout } from './AnimationLayout';

export interface ModalInfoProps {
  title?: string;
  bodyText?: string;
  primaryButtonText?: string;
  primaryButtonClassName?: string;
  primaryClick?: () => void;
  secondaryButtonText?: string;
  secondaryClick?: () => void;
  secondaryButtonClassName?: string;
  colorTitle?: string;
}

interface ModalProps {
  // left: number;
}

export const Modal: FC<ModalProps> = ({}) => {
  const modal = modalState.useValue();
  const {
    title,
    bodyText,
    primaryClick,
    primaryButtonText,
    primaryButtonClassName,
    secondaryClick,
    secondaryButtonText,
    secondaryButtonClassName,
    colorTitle,
  } = modal;

  if (!modal.title) return <></>;
  return (
    <AnimationLayout
      open={!!title}
      // left={left}
    >
      <div className="my-8 w-full max-w-lg transform space-y-3 overflow-hidden rounded-lg bg-white p-6 text-center shadow-xl transition-all">
        {colorTitle && (
          <h3 className=" font-semibold leading-6 text-brand-1">
            {colorTitle}
          </h3>
        )}
        <h3 className="font-semibold">{title}</h3>
        <p className="whitespace-pre-wrap text-15 text-gray-600">{bodyText}</p>

        <div className="space-y-3 pt-5">
          {primaryButtonText && (
            <Button
              onClick={() => {
                primaryClick && primaryClick();
                modalState.reset();
              }}
              text={primaryButtonText}
              className={`${
                primaryButtonClassName
                  ? primaryButtonClassName
                  : 'filled-brand-1'
              } w-full`}
            />
          )}

          {secondaryButtonText && (
            <Button
              onClick={() => {
                secondaryClick && secondaryClick();
                modalState.reset();
              }}
              text={secondaryButtonText}
              className={`${
                secondaryButtonClassName
                  ? secondaryButtonClassName
                  : 'bg-gray-50'
              } w-full`}
            />
          )}
        </div>
      </div>
    </AnimationLayout>
  );
};

export function useModal() {
  const { push, replace } = useHistory();

  const createMissionComplete = (missionName: string) =>
    modalState.set({
      colorTitle: missionName,
      title: '미션 생성 완료!',
      bodyText: '나만의 습관으로 만들어 보세요!',
      primaryButtonText: '보러가기',
      primaryClick: () => {
        replace('/mission');
      },
    });

  const completeAllMission = (missionId: string) =>
    modalState.set({
      title: '습관이 완성되었습니다!',
      bodyText: `미션을 25일 이상 꾸준히 성공해서\n습관이 완성되었어요.\n그 동안 어떤 변화가 있었는지 알려주세요.`,
      primaryButtonText: '후기 남기고 +100P 받기',
      primaryClick: () => {
        push(`/mission/${missionId}/review`);
      },
      secondaryButtonText: '닫기',
    });

  const completeTodaysMission = (onClickSuccess: () => void) =>
    modalState.set({
      title: '오늘 미션을 성공하셨나요?',
      bodyText: `아직 진행을 못하셨다면 \n
      오늘이 지나기 전에 미션을 진행해주세요!.`,
      primaryButtonText: '성공했어요!',
      primaryClick: () => {
        playAnimState.set(true);
        onClickSuccess();
      },
      secondaryButtonText: '아직이에요.',
    });

  const missionReviewComplete = () =>
    modalState.set({
      title: '후기 작성이 완료되었습니다!',
      bodyText: `미션 후기작성이 완료되었습니다.\n새로운 습관에 도전해보세요!`,
      primaryButtonText: '메인으로',
      primaryClick: () => {
        replace('/mission');
      },
    });

  const inviteFriend = (onClick: () => void) =>
    modalState.set({
      title: '친구 초대하기',
      bodyText: `아직 미션을 진행하고 있지 않은 친구를\n초대하여 건강관리를 함께 해보세요!`,
      primaryButtonText: '미션 링크 복사',
      primaryClick: onClick,
      secondaryButtonText: '닫기',
    });

  const inviteConfirm = (title: string, onClick: any) => {
    modalState.set({
      title: `${title}\n미션 초대장이 있어요!`,
      bodyText: `함께 미션을 진행해보세요!\n오늘부터 새로운 미션으로 추가됩니다.\n미션 관리에서 확인해보세요!`,
      primaryButtonText: '미션 추가하기',
      primaryClick: () => {
        onClick();
        removeItemInLocalStorage(LOCAL_STORAGE_KEY.DYNAMIC_LINK);
      },
      secondaryButtonText: '닫기',
      secondaryClick: () => {
        removeItemInLocalStorage(LOCAL_STORAGE_KEY.DYNAMIC_LINK);
      },
    });
  };

  const inviteComplete = () => {
    modalState.set({
      title: '새로운 미션이 추가되었습니다!',
      bodyText: `함께하는 새로운 미션이 추가되었습니다!\n서로 응원하며 완료해보세요!`,
      primaryButtonText: '닫기',
    });
  };

  const createComment = (onClick: () => void) =>
    modalState.set({
      title: '응원의 한마디를 남겨볼까요?',
      bodyText: `단 포인트 적립은 하루에 같은 친구에게\n첫 댓글만 가능해요!`,
      primaryButtonText: '오늘 응원하고 5P 받기',
      primaryClick: () => {
        hideCommentModal();
        onClick();
      },
      secondaryButtonText: '닫기',
      secondaryClick: () => {
        hideCommentModal();
      },
    });

  const logoutConfirmation = (logout: () => void) =>
    modalState.set({
      title: '로그아웃 하시겠어요?',
      primaryButtonText: '로그아웃하기',
      primaryClick: () => {
        logout();
      },
      secondaryButtonText: '닫기',
    });

  const deleteAccountConfirmation = (deleteAccount: () => void) =>
    modalState.set({
      title: '탈퇴하시겠어요?',
      bodyText:
        '회원탈퇴 시 진행 중인 미션, 미션포인트\n삭제에 동의하는 것으로 간주되며 재가입시\n신규가입으로 가입됩니다.',
      primaryButtonText: '탈퇴하기',
      primaryClick: () => {
        deleteAccount();
      },
      secondaryButtonText: '이전으로',
    });

  const surveyCompleted = (goToContents: () => void) =>
    modalState.set({
      title: '답변이 완료되었습니다!',
      bodyText: `오늘의 설문 답변이 완료되었습니다!\n추천하는 컨텐츠를 확인해보세요!`,
      primaryButtonText: ' 추천 컨텐츠 보러가기',
      primaryClick: () => goToContents(),
      secondaryButtonText: '닫기',
    });

  const deleteCommentConfirmation = (onDelete: () => void) =>
    modalState.set({
      title: '댓글을 삭제하겠습니까?',
      primaryButtonText: ' 삭제하기',
      primaryClick: () => {
        onDelete();
      },
      secondaryButtonText: '닫기',
    });

  const checkSensitiveAgreement = (agree: () => void, disagree?: () => void) =>
    modalState.set({
      title: '검사결과 촬영을 하시려면 하단내용에 동의해주세요!',
      bodyText: `민감정보 수집 및 이용에 동의\n수집항목: 유전자검사 결과\n수집목적: 맞춤 서비스 및 건강 정보제공\n보유기간: 아이힐미서비스 회원 탈퇴시까지\n
       위와 같은 민감정보 수집·이용 동의를 거부할 수 있으나, 동의하지 않으시는 경우 서비스 이용에 제약이 있습니다.`,
      primaryButtonText: ' 동의하기',
      primaryClick: () => {
        agree();
      },
      secondaryButtonText: '건너뛰기',
      secondaryClick: () => {
        disagree && disagree();
      },
    });

  const missionExist = (onClose: () => void) =>
    modalState.set({
      title: '현재 진행중인 미션이 있습니다!',
      bodyText: '미션은 1개씩만 진행 가능합니다.',
      primaryButtonText: '이전으로',
      primaryClick: () => {
        onClose();
      },
    });

  const missionConfirmation = (onConfirm: () => void) =>
    modalState.set({
      title: '선택하신 미션으로 진행할까요?',
      bodyText: `한달간 하나의 미션만 진행할 수 있으며\n미션 진행 중에는 초대장 참여가 불가합니다.\n받은 초대장이 있다면 함께 진행해보세요!`,
      primaryButtonText: '미션 진행하기',
      secondaryButtonText: '이전으로',
      primaryClick: () => {
        onConfirm();
      },
    });

  return {
    createMissionComplete,
    deleteCommentConfirmation,
    surveyCompleted,
    logoutConfirmation,
    deleteAccountConfirmation,
    completeTodaysMission,
    createComment,
    inviteFriend,
    inviteConfirm,
    inviteComplete,
    completeAllMission,
    missionReviewComplete,
    checkSensitiveAgreement,
    missionExist,
    missionConfirmation,
  };
}

const hideCommentModal = () => {
  setItemInLocalStorage(LOCAL_STORAGE_KEY.HIDE_COMMENT_MODAL, {
    date: utcToLocalFormat(new Date().toUTCString(), MomentFormat.YYYY_MM_DD),
  });
};
