import { stringify } from 'qs';
import { api } from '../../plugins/axios';
import { PATHS } from './paths';
import { Comment, CreateCommentDto, GetCommentDto } from './type';

export const createComment = (createCommentDto: CreateCommentDto) => {
  return api.post(PATHS.ROOT, createCommentDto);
};

export const deleteComment = (id: number) => {
  return api.delete(PATHS.getOneById(id));
};

export const getComment = async (getCommentDto: GetCommentDto) => {
  const queryString = stringify(getCommentDto, {
    addQueryPrefix: true,
    encode: false,
  });
  const { data } = await api.get<{ comments: Comment[] }>(
    `/${PATHS.ROOT}${queryString}`
  );
  return data.comments;
};
