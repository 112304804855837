import { useEffect, useState } from 'react';
import { filter, map } from 'lodash';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserDailyMissionStatus } from '../../../../api/user-daily-missions/enum';
import { Button } from '../../../../components/Button';
import { FriendMission } from '../../../../components/mission-community/FriendMission';
import { MyMission } from '../../../../components/mission-community/MyMission';
import { CreateCommentModal } from '../../../../components/modal/CreateCommentModal';
import { useModal } from '../../../../components/modal/Modal';
import { useCreateComments } from '../../../../hooks/comments';
import {
  getCommunityCard,
  useInviteGroup,
  useMissionGroupsById,
} from '../../../../hooks/mission-groups';
import {
  useDailyMission,
  useUpdateDailyMission,
} from '../../../../hooks/user-daily-missions';
import { Action, Bridge } from '../../../../plugins/bridge';
import { utcToIsAfter } from '../../../../plugins/moment';
import { fetcher } from '../../../../plugins/react-query';
import { currentMissionState } from '../../../../plugins/ridge';
import { CommunityCard, User } from '../../../../types';
import {
  CommentData,
  getHideCommentPointModal,
} from '../../../comments/[id]/CommentsPage';

export const CommunityPage = () => {
  const { data: me } = useQuery<User>('users/me', fetcher);
  const { groupId, userMissionId } = currentMissionState.useValue();
  const { inviteFriend, createComment } = useModal();
  const { push } = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [succeeded, setSucceeded] = useState(false);
  const [communityCard, setCommunityCard] = useState<CommunityCard[]>([]);
  const [myCard, setMyCard] = useState<CommunityCard>();
  const { data: dailyMission } = useDailyMission({
    userId: me?.id ?? 0,
    missionGroupId: groupId,
  });
  const { data: missionGroup } = useMissionGroupsById(groupId);
  const { mutate: updateDailySuccess } = useUpdateDailyMission();
  const { mutate: createCommentMutate } = useCreateComments(() => {
    setIsModalOpen(false);
    push(`/comments/${selectedUserId}`);
  });

  const onInviteSuccess = (inviteCode: string) => {
    Bridge.postMessage(Action.BUILD_DYNAMIC_LINK, inviteCode);
  };
  const onInviteFail = () => {
    toast.error('초대링크 생성에 실패했습니다.');
  };
  const { mutate: createInviteGroupMutate } = useInviteGroup(
    onInviteSuccess,
    onInviteFail
  );

  const onClickInvite = () => {
    createInviteGroupMutate(groupId);
  };

  const updateCard = () => {
    if (!missionGroup) return;
    getCommunityCard(missionGroup).then((res) => {
      const myCard = filter(res, (card) => card.id === me?.id)[0];
      const friendCards = filter(res, (card) => card.id !== me?.id);
      setMyCard(myCard as CommunityCard);
      setCommunityCard(friendCards as CommunityCard[]);
    });
  };

  useEffect(() => {
    if (!succeeded && dailyMission?.status === UserDailyMissionStatus.SUCCESS) {
      setSucceeded(true);
    }
  }, [dailyMission]);

  useEffect(() => {
    updateCard();
  }, [missionGroup]);

  const onClickSuccess = () => {
    if (!dailyMission?.id) return;
    updateDailySuccess({
      id: dailyMission.id,
      body: {
        userMissionId,
        status: UserDailyMissionStatus.SUCCESS,
      },
    });
  };

  const clickCreateComment = (userId: number) => {
    setSelectedUserId(userId);
    if (getHideCommentPointModal()) {
      setIsModalOpen(true);
      return;
    }

    createComment(() => setIsModalOpen(true));
  };

  const onCreateComment = (data: CommentData) => {
    createCommentMutate({ ...data, receiverId: selectedUserId });
  };

  if (!me || !dailyMission || !myCard) return <></>;
  const {
    userMission: { endAt },
  } = dailyMission;

  return (
    <>
      <CreateCommentModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onClick={onCreateComment}
      />
      <div className="-mx-5 my-6 h-4 bg-gray-50" />

      <MyMission
        user={me}
        succeeded={succeeded}
        isEnd={utcToIsAfter(endAt)}
        comments={myCard?.comments}
        onSuccess={onClickSuccess}
        onClick={() => push('/comments')}
      />

      <div className="-mx-5 my-6 h-4 bg-gray-50" />

      <h5 className="mb-1 pb-4 font-semibold">{`함께하는 사람 ${communityCard.length}명`}</h5>

      <div className="space-y-5">
        <div className="border-b border-gray-100" />
        {communityCard &&
          map(communityCard, (card, i) => (
            <FriendMission
              key={i + 1}
              data={card}
              createComment={() => clickCreateComment(card.id)}
            />
          ))}
      </div>

      <p className="mt-8 text-gray-600">
        함께하면 더 즐겁고 효과적으로 할수 있어요.
        <br />
        미션을 함께할 친구나 가족을 초대해 보세요.
      </p>

      {!utcToIsAfter(endAt) && (
        <Button
          onClick={() => inviteFriend(onClickInvite)}
          text="함께하고 응원받기"
          className="filled-brand-1 mt-6 w-full"
        />
      )}
    </>
  );
};
