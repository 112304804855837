import { useQuery } from 'react-query';
import { getAdminReview, getReview } from '../../api/reviews';
import { PATHS } from '../../api/reviews/paths';
import { GetAdminReviewDto } from './../../api/reviews/type';

export const useReview = (userMissionId: number) => {
  return useQuery([PATHS.ROOT, userMissionId], () => getReview(userMissionId));
};

export const useAdminReview = (getAdminReviewDto: GetAdminReviewDto) => {
  return useQuery(
    [PATHS.ADMIN, getAdminReviewDto.answerType],
    () => getAdminReview(getAdminReviewDto),
    {
      enabled:
        !!getAdminReviewDto.answerType && !!getAdminReviewDto.questionType,
    }
  );
};
