import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as ContentIconActive } from '../assets/svg/icon-content-active.svg';
import { ReactComponent as ContentIcon } from '../assets/svg/icon-content.svg';
import { ReactComponent as ManageIconActive } from '../assets/svg/icon-manage-active.svg';
import { ReactComponent as ManageIcon } from '../assets/svg/icon-manage.svg';
import { ReactComponent as MissionIconActive } from '../assets/svg/icon-mission-active.svg';
import { ReactComponent as MissionIcon } from '../assets/svg/icon-mission.svg';
import { ReactComponent as MyIconActive } from '../assets/svg/icon-my-active.svg';
import { ReactComponent as MyIcon } from '../assets/svg/icon-my.svg';
import {
  CONTENTS_ROUTE,
  HOME_ROUTE,
  MANAGE_ROUTE,
  MYPAGE_ROUTE,
} from '../pages/constants/router';

export const BottomNavBar = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  return (
    <div className="fixed bottom-0 grid w-full grid-cols-4 border-t border-gray-100 bg-white py-5">
      <div
        onClick={() => push(HOME_ROUTE)}
        className="flex flex-col items-center justify-center space-y-1"
      >
        {pathname === HOME_ROUTE ? <MissionIconActive /> : <MissionIcon />}

        <span
          className={`${
            pathname === HOME_ROUTE ? 'text-gray-900' : 'text-gray-500'
          } text-12`}
        >
          미션
        </span>
      </div>
      <div
        onClick={() => push(MANAGE_ROUTE)}
        className="flex flex-col items-center justify-center space-y-1"
      >
        {pathname === MANAGE_ROUTE ? <ManageIconActive /> : <ManageIcon />}

        <span
          className={`${
            pathname === MANAGE_ROUTE ? 'text-gray-900' : 'text-gray-500'
          } text-12`}
        >
          미션 관리
        </span>
      </div>
      <div
        onClick={() => push(CONTENTS_ROUTE)}
        className="flex flex-col items-center justify-center space-y-1"
      >
        {pathname === CONTENTS_ROUTE ? <ContentIconActive /> : <ContentIcon />}

        <span
          className={`${
            pathname === CONTENTS_ROUTE ? 'text-gray-900' : 'text-gray-500'
          } text-12`}
        >
          콘텐츠
        </span>
      </div>
      <div
        onClick={() => push(MYPAGE_ROUTE)}
        className="flex flex-col items-center justify-center space-y-1"
      >
        {pathname === MYPAGE_ROUTE ? <MyIconActive /> : <MyIcon />}

        <span
          className={`${
            pathname === MYPAGE_ROUTE ? 'text-gray-900' : 'text-gray-500'
          } text-12`}
        >
          마이
        </span>
      </div>
    </div>
  );
};
