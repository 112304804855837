import React from 'react';
import { AxiosError } from 'axios';
import { isEmpty, map } from 'lodash';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../api/contents/paths';
import { ContentsDetailResponse } from '../../api/contents/type';
import { useMainContent } from '../../hooks/contents';
import { Action, Bridge } from '../../plugins/bridge';
import { fetcher } from '../../plugins/react-query';

export const ContentsPage = () => {
  const { push } = useHistory();
  const { data: contentsData } = useContents();
  const { data: content } = useMainContent();
  const contents = contentsData?.contents.filter(
    (data) => data.id !== content?.id
  );

  if (!contents || !content) return null;

  return (
    <div>
      <div className="my-5 -mx-5 border-b pb-5 text-center">콘텐츠</div>

      <div className="mb-8 space-y-3">
        <div
          onClick={() =>
            isEmpty(content.link)
              ? push(`/contents/${content.id}`)
              : Bridge.postMessage(Action.OPEN_LINK, content.link)
          }
          className="aspect-[2/1] overflow-hidden rounded-lg"
        >
          <img
            src={content.thumbnail}
            alt=""
            className="h-full w-full object-cover"
          />
        </div>
        <h4>{content.title}</h4>
      </div>

      <div className="mb-8 grid grid-cols-2 gap-x-5 gap-y-8">
        {map(contents, (content) => (
          <div
            key={content.id}
            onClick={() =>
              isEmpty(content.link)
                ? push(`/contents/${content.id}`)
                : Bridge.postMessage(Action.OPEN_LINK, content.link)
            }
            className="space-y-2"
          >
            <div className="aspect-square overflow-hidden rounded-md">
              <img
                src={content.thumbnail}
                alt=""
                className="h-full w-full object-cover"
              />
            </div>

            <p className="text-13 line-clamp-2">{content.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const useContents = () => {
  return useQuery<{ contents: ContentsDetailResponse[] }, AxiosError>(
    PATHS.ROOT,
    fetcher
  );
};
