import { newRidgeState } from 'react-ridge-state';
import { ModalInfoProps } from '../components/modal/Modal';
import { NAV_BAR } from '../constants';
import { CurrentMission, DnaTest, User } from '../types';
import { api } from './axios';

export const historyState = newRidgeState<any>(null);

export const meState = newRidgeState<User | -1 | 0>(-1);
export const tokenState = newRidgeState<string | null>(null, {
  onSet: async (newState) => {
    if (newState) {
      localStorage.setItem('token', newState);
      const result = await api.get('users/me').catch((e) => {
        meState.set(0);
        console.log(`tokenState error : `, e);
        localStorage.removeItem('token');
      });
      if (result && (result.status === 200 || result.status === 201)) {
        meState.set(result.data);
      }
    } else {
      meState.set(0);
      localStorage.removeItem('token');
    }
  },
});

export const osState = newRidgeState<string>('');

function setInitialState() {
  const token = localStorage.getItem('token');
  tokenState.set(token);
}

export const modalState = newRidgeState<ModalInfoProps>({
  title: '',
  bodyText: '',
  primaryClick: () => {},
  primaryButtonText: '',
  primaryButtonClassName: '',
  secondaryClick: () => {},
  secondaryButtonText: '',
  secondaryButtonClassName: '',
});

export const dnaTestState = newRidgeState<DnaTest>({
  isFirstTime: false,
  isTested: false,
});

export const currentMissionState = newRidgeState<CurrentMission>({
  missionId: 0,
  groupId: 0,
  userMissionId: 0,
  dailyMissionId: 0,
});

export const currentRefState = newRidgeState<HTMLInputElement | null>(null);

export const playAnimState = newRidgeState(false);
export const navBarState = newRidgeState<string>(NAV_BAR.HOME);
export const versionState = newRidgeState<string>('');

setInitialState();
