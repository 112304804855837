import React from 'react';
import { Dropdown } from '../../components/Dropdown';
import { EditAvatar } from '../../components/EditAvatar';
import { Menu } from '../../components/Menu';
import { useAuth } from '../../hooks';

export const AdminHeader = () => {
  const { logout } = useAuth();

  return (
    <div className="">
      <div className="flex-1" />

      <div className="ml-4 flex items-center">
        <Dropdown>
          <Dropdown.View className="right-0 rounded-md">
            <Menu>
              <Menu.Item text="Logout" onClick={logout} />
            </Menu>
          </Dropdown.View>
        </Dropdown>
      </div>
    </div>
  );
};
