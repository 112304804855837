import { stringify } from 'qs';
import { adminApi, api } from '../../plugins/axios';
import { PaginationDto } from './../types/index';
import { PATHS } from './paths';
import {
  CreateSurveyDto,
  UpdateSurveyDto,
  CreateSurveyResultDto,
} from './type';

export function getAdminSurveys(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}

export function createSurvey(createSurveyDto: CreateSurveyDto) {
  return adminApi.post(`/${PATHS.ROOT}`, createSurveyDto);
}

export function updateSurvey(updateSurveyDto: UpdateSurveyDto) {
  return adminApi.patch(
    `/${PATHS.getOneById(updateSurveyDto.id)}`,
    updateSurveyDto
  );
}

export function createSurveyResult(
  createSurveyResultDto: CreateSurveyResultDto
) {
  return api.post(
    `/${PATHS.getOneById(createSurveyResultDto.id)}`,
    createSurveyResultDto
  );
}
