import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getAdminContents } from '../../api/contents';
import { PATHS } from '../../api/contents/paths';
import { AdminContent, ContentsResponse } from '../../api/contents/type';
import { PaginationDto } from '../../api/types';
import { fetcher } from '../../plugins/react-query';
import { Paginated } from '../../types';

export const useMainContent = () => {
  return useQuery<ContentsResponse, AxiosError>(PATHS.MAIN, fetcher);
};

export const useAdminContents = (paginationDto: PaginationDto) => {
  return useQuery<Paginated<AdminContent>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAdminContents(paginationDto),
    { keepPreviousData: true }
  );
};
