import { adminApi } from '../../plugins/axios';
import { PATHS } from './paths';
import { CreateSurveyAnswerDto, UpdateSurveyAnswerDto } from './type';

export const createSurveyAnswer = (
  createSurveyAnswerDto: CreateSurveyAnswerDto
) => {
  return adminApi.post(PATHS.ROOT, createSurveyAnswerDto);
};

export const updateSurveyAnswer = (
  updateSurveyAnswerDto: UpdateSurveyAnswerDto
) => {
  return adminApi.patch(
    PATHS.getOneById(updateSurveyAnswerDto.id),
    updateSurveyAnswerDto
  );
};
