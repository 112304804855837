import axios from 'axios';
import { stringify } from 'qs';
import { api } from '../../plugins/axios';
import { ResetPasswordDto } from './../../../../ihealme-api/apps/domain/auth/dto/request/reset-password.dto';
import { PATHS } from './paths';
import {
  FindEmailDto,
  SignUpDto,
  FindPasswordDto,
  FindPasswordResponse,
} from './type';

export async function findEmail(findEmailDto: FindEmailDto) {
  const { data } = await api.post<{ email: string }>(
    PATHS.FIND_EMAIL,
    findEmailDto
  );
  return data;
}

export async function signUp(signUpDto: SignUpDto) {
  const { data } = await api.post<{ token: string }>(PATHS.SIGNUP, signUpDto);
  return data;
}

export async function findPassword(findPasswordDto: FindPasswordDto) {
  const { data } = await api.post<FindPasswordResponse>(
    PATHS.RESET_PASSWORD,
    findPasswordDto
  );
  return data;
}

export function resetPassword(resetPasswordDto: ResetPasswordDto) {
  return api.patch(PATHS.RESET_PASSWORD, resetPasswordDto);
}

export async function kakaoLogin(idToken: string) {
  const { data } = await api.post<{ token: string }>(PATHS.KAKAO, { idToken });
  return data;
}

export async function appleLogin(idToken: string) {
  const { data } = await api.post<{ token: string }>(PATHS.APPLE, { idToken });
  return data;
}
