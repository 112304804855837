import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createReview } from '../../../../api/reviews';
import {
  FirstAnswer,
  SecondAnswer,
  ThirdAnswer,
} from '../../../../api/reviews/enum';
import { CreateReviewDto } from '../../../../api/reviews/type';
import { Button } from '../../../../components/Button';
import { ChoiceButton } from '../../../../components/ChoiceButton';
import { Icon } from '../../../../components/Icon';
import { useModal } from '../../../../components/modal/Modal';
import { REVIEWS } from '../../../../constants';

export const MissionReviewPage = () => {
  const { mutate: createReviewMutate } = useCreateReview(() =>
    missionReviewComplete()
  );
  const { id } = useParams<{ id: string }>();
  const { missionReviewComplete } = useModal();
  const { push } = useHistory();
  const [first, setFirst] = useState(FirstAnswer.UNDEFINED);
  const [second, setSecond] = useState(SecondAnswer.UNDEFINED);
  const [third, setThird] = useState(ThirdAnswer.UNDEFINED);

  const Q1 = REVIEWS[0];
  const Q2 = REVIEWS[1];
  const Q3 = REVIEWS[2];

  const onSave = () => {
    createReviewMutate({
      userMissionId: +id,
      answers: [first, second, third],
    });
  };

  return (
    <div className="pb-5">
      <div className="my-8 space-y-1">
        <div className="flex items-center justify-between">
          <h3>한 달 간의 미션후기를 알려주세요! </h3>
          <button onClick={() => push(`/mission/${id}/progress`)} className="">
            <Icon.X />
          </button>
        </div>
        <p className="text-15 text-gray-500">
          한 달동안 미션을 진행하며 느꼈던 점을 알려주세요!
          <br /> 어떤 변화가 있었는지 궁금해요.
        </p>
      </div>

      <div className="mt-5 space-y-8 pb-12">
        <div key={Q1.id} className="flex flex-col space-y-4">
          <h4>{Q1.title}</h4>
          <div className="space-y-3">
            {Q1.options.map((option) => (
              <ChoiceButton
                key={option.id}
                onClick={() => setFirst(option.value as FirstAnswer)}
                selected={first === option.value}
                text={option.answer}
              />
            ))}
          </div>
        </div>
        <div key={Q2.id} className="flex flex-col space-y-4">
          <h4>{Q2.title}</h4>
          <div className="space-y-3">
            {Q2.options.map((option) => (
              <ChoiceButton
                key={option.id}
                onClick={() => setSecond(option.value as SecondAnswer)}
                selected={second === option.value}
                text={option.answer}
              />
            ))}
          </div>
        </div>
        <div key={Q3.id} className="flex flex-col space-y-4">
          <h4>{Q3.title}</h4>
          <div className="space-y-3">
            {Q3.options.map((option) => (
              <ChoiceButton
                key={option.id}
                onClick={() => setThird(option.value as ThirdAnswer)}
                selected={third === option.value}
                text={option.answer}
              />
            ))}
          </div>
        </div>
      </div>

      <Button
        disabled={
          first === FirstAnswer.UNDEFINED ||
          second === SecondAnswer.UNDEFINED ||
          third === ThirdAnswer.UNDEFINED
        }
        onClick={onSave}
        text="저장하기"
        className="filled-brand-1 w-full"
      />
    </div>
  );
};

const useCreateReview = (onSuccess: () => void) => {
  return useMutation(
    (createReviewDto: CreateReviewDto) => createReview(createReviewDto),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        toast.error('후기 제출에 실패했습니다.');
      },
    }
  );
};
