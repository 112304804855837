import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  getCategory,
  createCategory,
  updateCategory,
  getAdminCategory,
  getOneCategory,
  getAdminUserCategory,
} from '../../api/categories';
import { PATHS } from '../../api/categories/paths';
import {
  GetCategoryDto,
  Mission,
  UserCategoriesResponse,
  UpdateCategoryDto,
  GetAdminUserCategoryDto,
} from '../../api/categories/type';
import { fetcher } from '../../plugins/react-query';
import {
  CreateCategoryDto,
  GetAdminCategoryDto,
} from './../../api/categories/type';

export const useOneCategory = (id: number) => {
  return useQuery([PATHS, id], () => getOneCategory(id));
};

export const useCategory = (getCategoryDto: GetCategoryDto) => {
  return useQuery(getCategoryDto.categoryType, () =>
    getCategory(getCategoryDto)
  );
};

export const useMissionsByCategory = (id: number) => {
  return useQuery<{ missions: Mission[] }, AxiosError>(
    PATHS.getMissionsById(id),
    fetcher,
    {
      enabled: !!id,
    }
  );
};

export const useCreateCategory = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createCategoryDto: CreateCategoryDto) => createCategory(createCategoryDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.ROOT);
        onSuccess && onSuccess();
      },
      onError: (e) => {
        toast.error('카테고리 생성에 실패했습니다.');
      },
    }
  );
};

export const useUpdateCategory = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateCategoryDto: UpdateCategoryDto) => updateCategory(updateCategoryDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.ROOT);
        onSuccess && onSuccess();
      },
      onError: (e) => {
        toast.error('카테고리 수정에 실패했습니다.');
      },
    }
  );
};

export const useAdminCategory = (getAdminCategoryDto: GetAdminCategoryDto) => {
  return useQuery(PATHS.getOneById(getAdminCategoryDto.id), () =>
    getAdminCategory(getAdminCategoryDto)
  );
};

export const useAdminUserCategory = (
  getAdminUserCategoryDto: GetAdminUserCategoryDto
) => {
  return useQuery(PATHS.MISSIONS, () =>
    getAdminUserCategory(getAdminUserCategoryDto)
  );
};

export const useMyCategory = () => {
  return useQuery<UserCategoriesResponse, AxiosError>(PATHS.ME, fetcher);
};
