import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateSurvey } from '../../../api/surveys';
import { PATHS } from '../../../api/surveys/paths';
import { Survey, UpdateSurveyDto } from '../../../api/surveys/type';
import { Button } from '../../../components/Button';
import { TextField } from '../../../components/TextField';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { adminFetcher } from '../../../plugins/react-query';

interface FormValues {
  title: string;
  subTitle: string;
  recommendLink: string;
  startAt: string;
  endAt: string;
}

export const SurveyInfo = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { data: surveyData } = useQuery<Survey>(
    PATHS.getOneById(+id),
    adminFetcher,
    {
      enabled: !!id,
    }
  );
  const { mutate: updateSurveyMutate } = useUpdateSurvey(() =>
    push('/admin/survey/list')
  );
  const { register, handleSubmit, reset } = useForm<FormValues>();

  useEffect(() => {
    if (surveyData) {
      const { startAt, endAt } = surveyData;
      const defaultValues = {
        ...surveyData,
        startAt: utcToLocalFormat(startAt, MomentFormat.YYYY_MM_DD),
        endAt: utcToLocalFormat(endAt, MomentFormat.YYYY_MM_DD),
      };

      reset(defaultValues);
    }
  }, [surveyData]);

  const handleUpdate = (data: FormValues) => {
    const dto = {
      ...data,
      id: +id,
    };
    updateSurveyMutate(dto);
  };

  return (
    <>
      <form onSubmit={handleSubmit((data) => handleUpdate(data))}>
        <div className="mt-5 space-y-5">
          <TextField label="제목" {...register('title')} />
          <TextField label="서브 제목" {...register('subTitle')} />
          <TextField label="관련 추천 링크" {...register('recommendLink')} />
          <div className="grid grid-cols-2 gap-x-5">
            <TextField label="시작일" type="date" {...register('startAt')} />
            <TextField label="종료일" type="date" {...register('endAt')} />
          </div>
        </div>
        <div className="flex justify-end space-x-4 py-4">
          <Button
            text="저장하기"
            className="filled-gray-900 h-10 text-sm"
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

const useUpdateSurvey = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateSurveyDto: UpdateSurveyDto) => updateSurvey(updateSurveyDto),
    {
      onSuccess: () => {
        toast.success('질문 정보가 수정되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
        onSuccess && onSuccess();
      },
      onError: () => {
        toast.error('질문 정보 수정에 실패했습니다.');
      },
    }
  );
};
