import { useQuery } from 'react-query';
import { getUserSurveyAnswers } from '../../api/user-survey-answers';
import { PATHS } from '../../api/user-survey-answers/paths';
import { GetUserSurveyAnswersDto } from './../../api/user-survey-answers/type';

export const useUserSurveyAnswers = (
  getUserSurveyAnswersDto: GetUserSurveyAnswersDto
) => {
  return useQuery([PATHS.ROOT, getUserSurveyAnswersDto.answerId], () =>
    getUserSurveyAnswers(getUserSurveyAnswersDto)
  );
};
