import { stringify } from 'qs';
import { toast } from 'react-toastify';
import { adminApi, api } from '../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { PATHS } from './paths';
import {
  CreateReviewDto,
  GetAdminReviewDto,
  GetAdminReviewResponse,
  Review,
} from './type';

export async function getReview(userMissionId: number) {
  const queryString = stringify(
    { userMissionId },
    {
      addQueryPrefix: true,
      encode: false,
    }
  );
  const { data } = await api.get<Review>(`/${PATHS.ROOT}${queryString}`);
  return data;
}

export function createReview(createReviewDto: CreateReviewDto) {
  return api.post(PATHS.ROOT, createReviewDto);
}

export const getAdminReview = async (getAdminReviewDto: GetAdminReviewDto) => {
  const queryString = stringify(getAdminReviewDto, {
    addQueryPrefix: true,
    encode: false,
  });
  const { data } = await adminApi.get<GetAdminReviewResponse>(
    `/${PATHS.ADMIN}${queryString}`
  );
  return data.reviews;
};

export const getReviewsXlsx = () => {
  return adminApi
    .get(PATHS.XLSX, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then((res) => {
      const url = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${utcToLocalFormat(
        new Date().toUTCString(),
        MomentFormat.YYYYMMDDHHmmss
      )}_미션후기목록`;
      link.click();
      URL.revokeObjectURL(url);
    })
    .catch((e) => {
      console.log('e :', e);
      toast.error('엑셀 내보내기에 실패했습니다.');
    });
};
