import React, { FC } from 'react';

export const AdminMain: FC = ({ children }) => {
  return (
    <main className="relative flex-1 overflow-y-auto py-6">
      <div className="flex max-w-7xl flex-col space-y-4 px-4 sm:px-6 md:px-8">
        {children}
      </div>
    </main>
  );
};
