import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BottomFixedButton } from '../../components/BottomFixedButton';
import { ChoiceButton } from '../../components/ChoiceButton';
import { TopNavBar } from '../../components/TopNavBar';
import { CATEGORY_TYPES, DNA_TEST } from '../../constants';
import {
  LOCAL_STORAGE_KEY,
  removeItemInLocalStorage,
  setItemInLocalStorage,
} from '../../plugins/local-storage';
import { dnaTestState } from '../../plugins/ridge';

const INSTRUCTION = [
  `관심 있는 건강 예방 습관 항목을\n1가지만 선택하세요.`,
  '내가 받은 유전자검사 종류를\n선택해주세요.',
];

export const StepTwoPage = () => {
  const [categoryTypeId, setCategoryTypeId] = useState(0);
  const { push } = useHistory();
  const { isTested: hasDnaTest } = dnaTestState.useValue();

  useEffect(() => {
    removeItemInLocalStorage(LOCAL_STORAGE_KEY.CATEGORY_TYPE);
    removeItemInLocalStorage(LOCAL_STORAGE_KEY.CATEGORY);
    removeItemInLocalStorage(LOCAL_STORAGE_KEY.TEST_INFO);
  }, []);

  const setMetaCategory = () => {
    const categoryType = { categoryTypeId };
    setItemInLocalStorage(LOCAL_STORAGE_KEY.CATEGORY_TYPE, categoryType);
  };

  const handleNext = () => {
    setMetaCategory();
    push('/dna-test/3');
  };

  return (
    <>
      <TopNavBar />

      <h2 className="whitespace-pre-wrap pt-12 pb-8">
        {hasDnaTest ? INSTRUCTION[DNA_TEST.Y] : INSTRUCTION[DNA_TEST.N]}
      </h2>

      <form>
        {hasDnaTest ? (
          <div className="mb-16 space-y-4">
            {CATEGORY_TYPES[DNA_TEST.Y].map((item) => (
              <ChoiceButton
                key={item.id}
                onClick={() => setCategoryTypeId(item.id)}
                selected={categoryTypeId === item.id}
                text={item.name}
              />
            ))}
            <p className=" pt-6 text-13 text-gray-500">
              미션의 출처가 궁금한 경우 “마이” 메뉴에서 자세히 알아보세요.
            </p>
          </div>
        ) : (
          <>
            <div className="space-y-4 ">
              {CATEGORY_TYPES[DNA_TEST.N].map((item) => (
                <ChoiceButton
                  key={item.id}
                  onClick={() => setCategoryTypeId(item.id)}
                  selected={categoryTypeId === item.id}
                  text={item.name}
                />
              ))}
            </div>

            <p className="mb-16 pt-6 text-13 text-gray-500">
              암(예측) 유전자 검사와 DTC 유전자 검사를 통해서 나에게 꼭 필요한
              예방항목을 알 수 있습니다. <br /> 유전자 검사와 미션출처가 궁금한
              경우 “마이” 메뉴에서 자세히 알아보세요.
            </p>
          </>
        )}
      </form>

      <BottomFixedButton text="다음" onClick={handleNext} />
    </>
  );
};
