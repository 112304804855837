import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  getUserMissionsByUserId,
  updateUserMission,
} from '../../api/user-missions';
import { PATHS } from '../../api/user-missions/paths';
import { fetcher } from '../../plugins/react-query';
import {
  GetUserMissionsByUserDto,
  UserMission,
} from './../../api/user-missions/type';

export const useUserMissionsByUser = (
  getUserMissionsByUserDto: GetUserMissionsByUserDto
) => {
  return useQuery([PATHS.ROOT, getUserMissionsByUserDto.userId], () =>
    getUserMissionsByUserId(getUserMissionsByUserDto)
  );
};

export const useGetUserMission = (id: number) => {
  return useQuery<UserMission>(PATHS.getUserMissionById(id), fetcher);
};

export const useUpdateUserMission = (onSuccess: () => void) => {
  return useMutation((id: number) => updateUserMission(id), {
    onSuccess: () => {
      onSuccess();
    },
    onError: () => {
      toast.error('미션 완료에 실패했습니다.');
    },
  });
};
