import React from 'react';
import { filter, isEmpty, map } from 'lodash';
import { useParams } from 'react-router-dom';
import { MissionStatus } from '../../../api/mission-groups/enum';
import { UserDailyMissionStatus } from '../../../api/user-daily-missions/enum';
import { UserDailyMission } from '../../../api/user-daily-missions/type';
import { Card } from '../../../components/Card';
import { Table } from '../../../components/Table';
import { useAdminUserMissions } from '../../../hooks/users';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';

export const UserMission = () => {
  const { id } = useParams<{ id: string }>();
  const { data: userMissions } = useAdminUserMissions({ id });

  const getSuccessNumber = (dailyMission: UserDailyMission[]) => {
    const successes = filter(dailyMission, {
      status: UserDailyMissionStatus.SUCCESS,
    });
    return `${successes.length}/30`;
  };

  if (!userMissions) return null;
  const { userMissions: missions } = userMissions;

  return (
    <div className="space-y-3 p-6">
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>NO.</Table.Th>
              <Table.Th>진행 중/완료한 미션 정보</Table.Th>
              <Table.Th>시작일</Table.Th>
              <Table.Th>종료일</Table.Th>
              <Table.Th>함께하는 사람</Table.Th>
              <Table.Th>성공 횟수</Table.Th>
              <Table.Th>진행상태</Table.Th>
              <Table.Th>미션설문여부</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(missions, (mission, index) => (
              <Table.Row key={mission.id}>
                <Table.Td>{index + 1}</Table.Td>
                <Table.Td>{mission.mission.title}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(mission.startAt, MomentFormat.YYYYMMDD)}
                </Table.Td>
                <Table.Td>
                  {utcToLocalFormat(mission.endAt, MomentFormat.YYYYMMDD)}
                </Table.Td>
                <Table.Td>{mission.missionGroup.countUser}</Table.Td>
                <Table.Td>
                  {getSuccessNumber(mission.userDailyMissions)}
                </Table.Td>
                <Table.Td>
                  {mission.status === MissionStatus.INPROGRESS
                    ? '진행중'
                    : '종료'}
                </Table.Td>
                <Table.Td>
                  {isEmpty(mission.reviews) ? '미진행' : '완료'}
                </Table.Td>
                <Table.Td className="text-right"></Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </div>
  );
};
