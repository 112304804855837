import { adminApi, api } from '../../plugins/axios';
import { PATHS } from './paths';
import { TestResult, UpdateTestResultDto } from './type';

export const getTestResultsByCategory = async (categoryId: number) => {
  const { data } = await api.get<TestResult>(PATHS.getOneById(categoryId));
  return data;
};

export const updateTestResults = (updateTestResultDto: UpdateTestResultDto) => {
  return adminApi.patch(
    PATHS.getOneById(updateTestResultDto.id),
    updateTestResultDto
  );
};
