import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { MissionCard } from '../types';

export interface DnaCardProps {
  mission: MissionCard;
}

export const DnaCard: FC<DnaCardProps> = ({ mission }) => {
  const { isTested, testImageUrl, categoryId } = mission;
  const { push } = useHistory();

  if (!isEmpty(testImageUrl)) return null;
  if (!isTested)
    return (
      <div className="w-full whitespace-pre rounded-md border border-brand-1 py-5 text-center text-13 font-bold leading-5 text-brand-1">
        <p>유전자 검사를 받으면</p>
        <p>나에게 어떤 습관이 중요한지 알 수 있어요!</p>
      </div>
    );

  return (
    <div
      className="w-full whitespace-pre rounded-md bg-blue-50 py-5 text-center text-13  font-bold text-brand-1"
      onClick={() => push(`/mypage/edit/dna-test?categoryId=${categoryId}`)}
    >
      <p>유전자 검사 결과가 있다면 촬영하고 +50P 받아보세요!</p>
    </div>
  );
};

export default DnaCard;
