import React, { FC, useState } from 'react';
import { filter } from 'lodash';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SurveyQuestion } from '../../api/survey-questions/type';
import { createSurveyResult } from '../../api/surveys';
import {
  CreateSurveyResultDto,
  QuestionResult,
  Survey,
} from '../../api/surveys/type';
import { Action, Bridge } from '../../plugins/bridge';
import {
  LOCAL_STORAGE_KEY,
  removeItemInLocalStorage,
  setItemInLocalStorage,
} from '../../plugins/local-storage';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { Button } from '../Button';
import { Checkbox } from '../Checkbox';
import { Icon } from '../Icon';
import { QuestionMulti } from '../QuestionMulti';
import { QuestionSingle } from '../QuestionSingle';
import { useModal } from '../modal/Modal';
import TransitionLayout from './TransitionLayout';

export interface SurveyData {
  survey: Survey;
  questions: SurveyQuestion[];
}

interface SurveyBottomDrawerProps {
  open: boolean;
  onClose: () => void;
  surveyData: SurveyData;
}

export const SurveyBottomDrawer: FC<SurveyBottomDrawerProps> = ({
  open,
  onClose,
  surveyData,
}) => {
  const { surveyCompleted } = useModal();
  const { survey, questions } = surveyData;
  const [needHide, setNeedHide] = useState(false);
  const { push } = useHistory();

  const [selectedData, setSelectedData] = useState<QuestionResult[]>([]);
  const { mutate: surveyMutate } = useSurveyResult(() =>
    surveyCompleted(goToContents)
  );

  const goToContents = () => {
    const { recommendLink } = survey;
    if (recommendLink.includes('ihealme.link/contents')) {
      const link = recommendLink.split('/');
      const contentsId = link[link.length - 1];
      push(`contents/${contentsId}`);
    } else {
      Bridge.postMessage(Action.OPEN_LINK, recommendLink);
    }
  };

  const onSave = () => {
    surveyMutate({ id: survey.id, surveyQuestions: selectedData });
    setHideSurvey(survey.id);
    onClose();
  };

  const selectMultiAnswer = (questionId: number, answerId: number) => {
    const dto = {
      id: questionId,
      isDuplicated: true,
    };

    if (filter(selectedData, { id: questionId }).length === 0) {
      const newData = { ...dto, answers: [answerId] };
      setSelectedData([...selectedData, newData]);
    } else {
      const question = filter(selectedData, { id: questionId })[0];
      const currentAnswer = question.answers;
      let newAnswers: number[];
      if (currentAnswer.includes(answerId)) {
        newAnswers = filter(currentAnswer, (value) => value !== answerId);
      } else {
        newAnswers = [...currentAnswer, answerId];
      }
      const newData = {
        ...dto,
        answers: newAnswers,
      };
      const filteredData = filter(
        selectedData,
        (data) => data.id !== questionId
      );
      setSelectedData([...filteredData, newData]);
    }
  };

  const selectSingleAnswer = (questionId: number, answerId: number) => {
    const filteredData = filter(selectedData, (data) => data.id !== questionId);
    const newData = {
      id: questionId,
      isDuplicated: false,
      answers: [answerId],
    };
    setSelectedData([...filteredData, newData]);
  };

  const setHideSurvey = (surveyId: number) => {
    removeItemInLocalStorage(LOCAL_STORAGE_KEY.HIDE_SURVEY);
    const surveyData = {
      date: utcToLocalFormat(new Date().toUTCString(), MomentFormat.YYYY_MM_DD),
      surveyId,
    };
    setItemInLocalStorage(LOCAL_STORAGE_KEY.HIDE_SURVEY, surveyData);
  };

  const _onClose = () => {
    if (needHide) {
      setHideSurvey(survey.id);
    }
    onClose();
  };

  return (
    <TransitionLayout open={open} onClose={_onClose}>
      <div className="fixed bottom-0 z-40 max-h-[95vh] w-full overflow-y-auto rounded-t-2xl bg-white p-5">
        <div className="flex w-full justify-between">
          <h3>{survey.title}</h3>
          <button onClick={_onClose}>
            <Icon.X />
          </button>
        </div>

        <p className="mt-3 text-15 text-gray-500">{survey.subTitle}</p>

        <div className="mt-5 space-y-8 pb-6">
          {questions.map((data) =>
            data.isDuplicated ? (
              <QuestionMulti
                key={data.id}
                data={data}
                selectAnswer={selectMultiAnswer}
                answers={
                  filter(selectedData, { id: data.id })[0]?.answers ?? []
                }
              />
            ) : (
              <QuestionSingle
                key={data.id}
                data={data}
                selectAnswer={selectSingleAnswer}
                answers={
                  filter(selectedData, { id: data.id })[0]?.answers ?? []
                }
              />
            )
          )}
        </div>

        <Checkbox
          label="오늘 하루 동안 보지않기"
          checked={needHide}
          onChange={(e) => setNeedHide(e.target.checked)}
        />

        <Button
          onClick={onSave}
          text="답변하고 +5P 받기"
          className="filled-brand-1 mt-8 w-full"
        />
      </div>
    </TransitionLayout>
  );
};

const useSurveyResult = (onSuccess: () => void) => {
  return useMutation(
    (createSurveyResultDto: CreateSurveyResultDto) =>
      createSurveyResult(createSurveyResultDto),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        toast.error('설문 제출에 실패했습니다.');
      },
    }
  );
};
