import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, some } from 'lodash';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Gender } from '../../../api/auth/enum';
import { FileType } from '../../../api/files/enum';
import { VerifyType } from '../../../api/phone-validations/enum';
import { BottomButton } from '../../../components/BottomButton';
import { BottomFixedButton } from '../../../components/BottomFixedButton';
import { Button } from '../../../components/Button';
import { ChoiceButton } from '../../../components/ChoiceButton';
import { EditAvatar } from '../../../components/EditAvatar';
import { PhoneNumber } from '../../../components/PhoneNumber';
import { TextField } from '../../../components/TextField';
import { Toggle } from '../../../components/Toggle';
import { TopNavBar } from '../../../components/TopNavBar';
import { useModal } from '../../../components/modal/Modal';
import { GENDERS } from '../../../constants';
import { useAuth } from '../../../hooks';
import { useImageUpload } from '../../../hooks/files';
import { useDeleteUser, useUpdateUser } from '../../../hooks/users';
import { fetcher } from '../../../plugins/react-query';
import { User } from '../../../types';

const DEFAULT_PASSWORD = 'abcd5678';

interface FormValues {
  name: string;
  age: number;
  height: number;
  weight: number;
  password: string;
  passwordConfirm: string;
  phoneNumber: string;
}

export const EditMyPage = () => {
  const [selectedGender, setSelectedGender] = useState(GENDERS[0]);
  const [avatar, setAvatar] = useState('');
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(false);
  const { goBack, replace } = useHistory();
  const { data: me } = useQuery<User>('users/me', fetcher);
  const { isUploading, handleImageChange } = useImageUpload();
  const { mutate: updateUserMutate } = useUpdateUser(() => {
    onSuccess();
  });
  const { logout } = useAuth();
  const { deleteAccountConfirmation } = useModal();

  const onSuccessDelete = () => {
    logout();
    toast.success('회원 탈퇴가 완료 되었습니다.');
    replace('/');
  };
  const { mutate: deleteUserMutate } = useDeleteUser(onSuccessDelete);

  const deleteUserModal = () => {
    deleteAccountConfirmation(deleteUserMutate);
  };

  const formSchema = Yup.object({
    password: Yup.string()
      .min(8, '최소 8자 이상 가능합니다')
      .max(15, '최대 15자 까지만 가능합니다')
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"])[0-9a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]{8,15}$/,
        '영문 숫자포함 8자리를 입력해주세요.'
      ),
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref('password')],
      '비밀번호가 다릅니다.'
    ),
    phoneNumber: Yup.string()
      .required('휴대폰 번호를 입력해주세요.')
      .matches(/^\d{11}$/, '휴대폰 번호 형식이 아닙니다.'),
    name: Yup.string()
      .required('이름을 입력해주세요.')
      .max(10, '최대 10자 까지만 가능합니다.'),
    age: Yup.number()
      .typeError('숫자만 입력해주세요')
      .required('값을 입력해주세요.'),
    height: Yup.number()
      .typeError('숫자만 입력해주세요')
      .required('값을 입력해주세요.'),
    weight: Yup.number()
      .typeError('숫자만 입력해주세요')
      .required('값을 입력해주세요.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (me) {
      const {
        phoneNumber,
        avatar,
        name,
        gender,
        age,
        height,
        weight,
        marketingAgreement,
      } = me;
      const defaultValues = {
        name,
        age,
        height,
        weight,
        phoneNumber,
        password: DEFAULT_PASSWORD,
        passwordConfirm: DEFAULT_PASSWORD,
      };

      _setGender(gender);
      setAvatar(avatar);
      setMarketingChecked(marketingAgreement ?? false);
      reset(defaultValues);
    }
  }, [me]);

  const _setGender = (gender: Gender) => {
    if (gender === Gender.MALE) {
      setSelectedGender(GENDERS[1]);
    }
  };

  const _handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newImage = e.target.files?.item(0);
    if (!newImage) return;
    const url = await handleImageChange(newImage, FileType.AVATAR);
    if (!url) return;
    setAvatar(url);
  };

  const needVerification = () => {
    if (me?.phoneNumber !== getValues('phoneNumber')) return true;
    return false;
  };

  const isPasswordChanged = () => {
    if (getValues('password') !== DEFAULT_PASSWORD) return true;
    return false;
  };

  const onSuccess = () => {
    toast.success('내 정보 수정이 완료되었습니다.');
    goBack();
  };

  const updateUser = (data: FormValues) => {
    const { name, age, height, weight, phoneNumber, password } = data;
    let defaultData: Partial<FormValues> = {
      name,
      age,
      height,
      weight,
      phoneNumber,
    };
    if (isPasswordChanged()) {
      defaultData = {
        ...defaultData,
        password,
      };
    }
    updateUserMutate({
      ...defaultData,
      avatar,
      gender: selectedGender.data,
      marketingAgreement: marketingChecked,
    });
  };

  return (
    <>
      <TopNavBar title="내 정보 수정" />

      <form
        className="mt-6 space-y-9 pb-32"
        onSubmit={handleSubmit((data) => {
          updateUser(data);
        })}
      >
        <div className="space-y-4">
          <h4>프로필 사진</h4>
          <EditAvatar
            className="wh-20 mt-2"
            src={avatar}
            handleImageChange={_handleImageChange}
          />
        </div>

        <div className="space-y-4">
          <h4>닉네임</h4>
          <TextField
            placeholder="10글자 이하로 입력해주세요."
            {...register('name')}
          />
        </div>

        <div className="space-y-4">
          <h4>성별</h4>
          <div className="grid grid-cols-2 gap-4">
            {GENDERS.map((gender) => (
              <ChoiceButton
                key={gender.id}
                onClick={() => setSelectedGender(gender)}
                selected={selectedGender === gender}
                text={gender.text}
              />
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <h4>나이와 키, 몸무게</h4>
          <div className="flex h-12 items-center rounded-md border border-gray-200 pl-4 pr-7">
            <TextField
              removeGlobalCSS
              parentClassName="w-full"
              className="border-0"
              placeholder="나이"
              {...register('age')}
            />
            <span className="">세</span>
          </div>
          <div className="flex h-12 items-center rounded-md border border-gray-200 pl-4 pr-8">
            <TextField
              removeGlobalCSS
              parentClassName="w-full"
              className="border-0"
              placeholder="키"
              {...register('height')}
            />
            <span className="">cm</span>
          </div>
          <div className="flex h-12 items-center rounded-md border border-gray-200 pl-4 pr-8">
            <TextField
              removeGlobalCSS
              parentClassName="w-full"
              className="border-0"
              placeholder="몸무게"
              {...register('weight')}
            />
            <span className="">KG</span>
          </div>
        </div>
        <div className="space-y-4">
          <h4>이메일</h4>
          <TextField
            disabled
            value={me?.email}
            placeholder="이메일을 입력해주세요."
          />
        </div>

        <TextField
          type="password"
          label="비밀번호"
          placeholder="영문 숫자 포함 8자리를 입력해주세요."
          helper={errors.password?.message}
          {...register('password')}
        />

        <TextField
          type="password"
          label="비밀번호 재입력"
          placeholder="비밀번호를 다시 입력해주세요."
          helper={errors.passwordConfirm?.message}
          {...register('passwordConfirm')}
        />

        <PhoneNumber
          {...register('phoneNumber')}
          value={getValues('phoneNumber')}
          verifyType={VerifyType.SIGNUP}
          isVerified={isPhoneVerified}
          setIsVerified={setIsPhoneVerified}
          helper={errors.phoneNumber?.message}
        />

        <div className="flex space-x-2">
          <Toggle
            checked={marketingChecked}
            onChange={(value) => setMarketingChecked(value)}
          />
          <span>마케팅 약관 동의</span>
        </div>
        <BottomButton
          disabled={some([
            isUploading,
            !isEmpty(errors),
            needVerification() && !isPhoneVerified,
          ])}
          type="submit"
          text="저장하기"
        />
        <div
          className="flex justify-center text-brand-1 underline"
          onClick={() => deleteUserModal()}
        >
          회원탈퇴
        </div>
      </form>
    </>
  );
};
