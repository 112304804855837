import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { Toggle } from '../../../components/Toggle';
import { useCreateMission, useUpdateMission } from '../../../hooks/missions';
import { AdminH1 } from '../../components/AdminH1';

interface FormValues {
  title: string;
  genoType: string;
  description: string;
}

interface StateType {
  categoryId: number;
  missionId?: number;
  title?: string;
  description?: string;
  genoType?: string;
  isOpen?: boolean;
}

export const MissionInfo = () => {
  const [isMissionOpen, setIsMissionOpen] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [missionId, setMissionId] = useState(0);
  const { state } = useLocation<StateType>();
  const { push, goBack } = useHistory();

  const goBackToList = () => {
    push('/admin/mission/list');
  };

  const { mutate: updateMissionMutate } = useUpdateMission(goBackToList);
  const { mutate: createMissionMutate } = useCreateMission(goBackToList);

  const { reset, register, handleSubmit } = useForm<FormValues>();

  useEffect(() => {
    if (!state?.categoryId) {
      toast.error('잘못된 접근입니다.');
      goBack();
    }

    setCategoryId(state.categoryId);
    if (state?.missionId) {
      setMissionId(state.missionId);
      const { title, genoType, description, isOpen } = state;
      if (isOpen) {
        setIsMissionOpen(isOpen);
      }
      const defaultValues = {
        title,
        genoType,
        description,
      };
      reset(defaultValues);
    } else {
      setIsMissionOpen(true);
    }
  }, []);

  const saveMission = (data: FormValues) => {
    const dto = {
      ...data,
      isOpen: isMissionOpen,
      categoryId,
    };
    if (missionId > 0) {
      updateMissionMutate({ ...dto, id: missionId });
    } else {
      createMissionMutate(dto);
    }
  };

  return (
    <>
      <div className="mb-5 flex items-center space-x-3 font-semibold">
        <Icon.ChevronLeft
          onClick={() => history.back()}
          className="cursor-pointer"
        />
        <AdminH1>미션 상세보기</AdminH1>
      </div>
      <form className="w-1/2 space-y-4">
        <TextField label="제목" {...register('title')} />
        <TextField label="유전자형" {...register('genoType')} />
        <TextArea label="설명" className="h-96" {...register('description')} />
        <div className="flex items-center space-x-3 py-6">
          <p>페이지 노출</p>
          <Toggle
            checked={isMissionOpen}
            onChange={(value) => setIsMissionOpen(value)}
          />
        </div>
        <div className="flex justify-end space-x-4 py-4">
          <Button
            text="저장하기"
            className="filled-gray-900 h-10 text-sm"
            onClick={handleSubmit((data) => {
              saveMission(data);
            })}
          />
        </div>
      </form>
    </>
  );
};
