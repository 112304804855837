export const PATHS = {
  ROOT: 'users',
  ME: 'users/me',
  EXIST: 'users/exists',
  IS_TESTED: 'users/me/is-tested',
  TEST_RESULT: 'users/me/test-result',
  AVATARS: 'users/avatars',
  SENSITIVE_AGREE: 'users/me/sensitive-agreement',
  ONE_SIGNAL: 'users/me/one-signal',
  getUserById: (id: string) => `users/${id}`,
  getUserMissionsById: (id: string) => `users/${id}/missions`,
  getUserPointsById: (id: string) => `users/${id}/points`,
};
