import React from 'react';
import { map } from 'lodash';
import { parse } from 'qs';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SurveyStatus } from '../../../api/surveys/enum';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE, SURVEY_STATUS } from '../../../constants';
import { useAdminSurveys } from '../../../hooks/surveys';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { AdminH1 } from '../../components/AdminH1';

export const SurveyList = () => {
  const { search } = useLocation();
  const { page, limit } = parse(search, { ignoreQueryPrefix: true });
  const { push } = useHistory();
  const { data: surveyResponse } = useAdminSurveys({
    page: typeof page === 'string' ? +page : 1,
    itemsPerPage: typeof limit === 'string' ? +limit : ITEMS_PER_PAGE,
    sort: JSON.stringify({ id: 'DESC' }),
  });

  if (!surveyResponse) return null;
  const { items, pagination } = surveyResponse;

  return (
    <div className="space-y-3 p-6">
      <div className="flex items-center space-x-5">
        <AdminH1>설문관리</AdminH1>
        <Button
          text="추가"
          className="filled-gray-100 h-10 text-sm text-black"
          onClick={() => push('/admin/survey/add')}
        />
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>NO.</Table.Th>
              <Table.Th>제목</Table.Th>
              <Table.Th>시작일</Table.Th>
              <Table.Th>종료일</Table.Th>
              <Table.Th>진행상태</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(items, (survey) => (
              <Table.Row key={survey.id}>
                <Table.Td>{survey.id}</Table.Td>
                <Table.Td>{survey.title}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    survey.startAt.toLocaleString(),
                    MomentFormat.YYYYMMDD
                  )}
                </Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    survey.endAt.toLocaleString(),
                    MomentFormat.YYYYMMDD
                  )}
                </Table.Td>
                <Table.Td
                  className={`
                  font-medium
                    ${
                      survey.status === SurveyStatus.INPROGRESS
                        ? 'text-green-600'
                        : survey.status === SurveyStatus.SCHEDULED
                        ? 'text-brand-1'
                        : ''
                    }
                  `}
                >
                  {SURVEY_STATUS[survey.status]}
                </Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/survey/${survey.id}/info`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={pagination.currentItemCount}
            total={pagination.totalItemCount}
          />
          <Pagination.Nav
            basePath={window.location.pathname}
            total={pagination.totalItemCount}
          />
        </Pagination>
      </Card>
    </div>
  );
};
