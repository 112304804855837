import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { VerifyType } from '../../../api/phone-validations/enum';
import { BottomFixedButton } from '../../../components/BottomFixedButton';
import { PhoneNumber } from '../../../components/PhoneNumber';
import { TopNavBar } from '../../../components/TopNavBar';

interface FormValues {
  phoneNumber: string;
}

export const FindEmailPage = () => {
  const { push } = useHistory();
  const formSchema = Yup.object({
    phoneNumber: Yup.string()
      .required('휴대폰 번호를 입력해주세요.')
      .matches(/^\d{11}$/, '휴대폰 번호 형식이 아닙니다.'),
  });

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const {
    register,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <TopNavBar title="이메일 찾기" />

      <h2 className="mt-10 leading-normal">
        가입하실때 입력한
        <br />
        핸드폰 번호를 입력해주세요.
      </h2>

      <form className="mt-6 space-y-6">
        <PhoneNumber
          {...register('phoneNumber')}
          value={getValues('phoneNumber')}
          verifyType={VerifyType.FINDEMAIL}
          isVerified={isPhoneVerified}
          setIsVerified={setIsPhoneVerified}
          helper={errors.phoneNumber?.message}
        />

        <BottomFixedButton
          disabled={!isPhoneVerified || !isEmpty(errors)}
          onClick={() =>
            push({
              pathname: '/find/email/success',
              state: { phoneNumber: getValues('phoneNumber') },
            })
          }
          text="다음"
        />
      </form>
    </>
  );
};
