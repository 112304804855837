import { useState } from 'react';
import { map } from 'lodash';
import { CategoryType } from '../../../api/categories/enum';
import { useMissionsByCategory } from '../../../hooks/categories';
import { useCategory } from '../../../hooks/categories';
import { useCreateMission } from '../../../hooks/missions';

const typeList: CategoryType[] = [
  CategoryType.CANCER,
  CategoryType.HEALTH,
  CategoryType.NUTRIENT,
  CategoryType.SKINCARE,
];

export default function CategoriesApiTest() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [genoType, setGenoType] = useState('');
  const [selectedType, setSelectedType] = useState<CategoryType>(
    CategoryType.CANCER
  );
  const [categoryId, setCategoryId] = useState(-1);
  const { data: missionsData } = useMissionsByCategory(categoryId);
  const { mutate: createMissionMutate } = useCreateMission();
  const { data: categories } = useCategory({ categoryType: selectedType });
  const missions = missionsData?.missions;

  const createMission = () => {
    createMissionMutate({
      title,
      description,
      genoType,
      isOpen: true,
      categoryId,
    });
    setTitle('');

    setDescription('');
    setGenoType('');
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <h2>Categories</h2>
      <fieldset className="my-4">
        <legend>select categoryType</legend>
        {typeList &&
          map(typeList, (type) => (
            <div key={type} className="m-2 flex">
              <input
                name={type}
                value={type}
                type="radio"
                onChange={() => {
                  setSelectedType(type);
                }}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                checked={selectedType === type}
              />
              <label
                htmlFor={type}
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {type}
              </label>
            </div>
          ))}
      </fieldset>
      <div className="m-4">
        {categories &&
          map(categories, (category) => (
            <div
              key={category.id}
              className="rounded-min mr-3 inline-block bg-red-500 px-2 py-1 text-xs font-bold text-white"
              onClick={() => {
                setCategoryId(category.id);
              }}
            >
              {category.name}
            </div>
          ))}
      </div>

      <h3>현재 타입에 미션 추가</h3>
      <div className="mt-2 mb-4 flex-col">
        <input
          className="w-full"
          placeholder="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <input
          className="w-full"
          placeholder="description"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <input
          className="w-full"
          placeholder="genoType"
          type="text"
          value={genoType}
          onChange={(e) => setGenoType(e.target.value)}
        />
        <button
          className="m-1 block h-10 w-full bg-black text-white"
          disabled={title === ''}
          onClick={createMission}
        >
          추가
        </button>
      </div>
      <div>
        {missions &&
          map(missions, (mission) => (
            <div
              key={mission.id}
              className="rounded-min mr-3 inline-block bg-red-500 px-2 py-1 text-xs font-bold text-white"
            >
              {mission.title}
            </div>
          ))}
      </div>
    </div>
  );
}
