import React, { useEffect } from 'react';
import { includes } from 'lodash';
import {
  useLocation,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import TestPage from '../admin/pages/test';
import { AuthRoute } from '../components/AuthRoute';
import { BottomNavBar } from '../components/BottomNavBar';
import { CongratsAnim } from '../components/CongratsAnim';
import { historyState, playAnimState } from '../plugins/ridge';
import { MyCommentsPage } from './comments/MyCommentsPage';
import { CommentsPage } from './comments/[id]/CommentsPage';
import {
  CONTENTS_ROUTE,
  HOME_ROUTE,
  MANAGE_ROUTE,
  MYPAGE_ROUTE,
  SHOW_BOTTOM_NAVBAR_ROUTES,
} from './constants/router';
import { ContentsPage } from './contents/ContentsPage';
import { ContentDetailPage } from './contents/[id]/ContentDetailPage';
import { StepFivePage } from './dna-test/StepFivePage';
import { StepFourPage } from './dna-test/StepFourPage';
import { StepOnePage } from './dna-test/StepOnePage';
import { StepThreePage } from './dna-test/StepThreePage';
import { StepTwoPage } from './dna-test/StepTwoPage';
import { SuccessPage } from './dna-test/SuccessPage';
import { FindEmailPage } from './find/email/FindEmailPage';
import { FindEmailSuccessPage } from './find/email/success/FindEmailSuccessPage';
import { FindPasswordPage } from './find/password/FindPasswordPage';
import { ResetPasswordPage } from './find/password/reset/ResetPasswordPage';
import { LoginPage } from './login/LoginPage';
import { LoginEmailPage } from './login/email/LoginEmailPage';
import { ManagePage } from './manage/ManagePage';
import { HomePage } from './mission/HomePage';
import { MissionDetailPage } from './mission/[id]/MissionDetailPage';
import { MissionReviewPage } from './mission/[id]/review/MissionReviewPage';
import { MyPage } from './mypage/MyPage';
import { EditMyPage } from './mypage/edit/EditMyPage';
import { EditDnaTestPage } from './mypage/edit/dna-test/EditDnaTestPage';
import { SelectDnaTestPage } from './mypage/edit/dna-test/SelectDnaTestPage';
import { MyPointsPage } from './mypage/points/MyPointsPage';
import { OnboardingPage } from './onboarding/OnboardingPage';
import { SignupPage } from './signup/SignupPage';
import { MyInfoPage } from './signup/my-info/MyInfoPage';
import { SocialSignupPage } from './signup/social/SocialSignupPage';
import { SignUpSuccessPage } from './signup/success/SignUpSuccessPage';

export const Router = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [play, setPlay] = playAnimState.use();

  useEffect(() => {
    historyState.set(history);
  }, []);

  useEffect(() => {
    if (!play) return;
    setTimeout(() => {
      setPlay(false);
    }, 2000);
  }, [play]);

  return (
    <div className="flex min-h-screen flex-1 flex-col">
      <main className="relative flex flex-1 flex-col px-5 pb-8">
        <Switch>
          <Route path="/dna-test/success" component={SuccessPage} />
          <Route path="/dna-test/5" component={StepFivePage} />
          <Route path="/dna-test/4" component={StepFourPage} />
          <Route path="/dna-test/3" component={StepThreePage} />
          <Route path="/dna-test/2" component={StepTwoPage} />
          <Route path="/dna-test/1" component={StepOnePage} />
          <Route path="/signup/success" component={SignUpSuccessPage} />
          <Route path="/signup/my-info" component={MyInfoPage} />
          <Route path="/signup/social" component={SocialSignupPage} />
          <Route path="/signup" component={SignupPage} />
          <Route path="/login/email" component={LoginEmailPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/find/password/reset" component={ResetPasswordPage} />
          <Route path="/find/password" component={FindPasswordPage} />
          <Route path="/find/email/success" component={FindEmailSuccessPage} />
          <Route path="/find/email" component={FindEmailPage} />
          <Route path="/mypage/points" component={MyPointsPage} />
          <Route
            path="/mypage/edit/select-dna-test"
            component={SelectDnaTestPage}
          />
          <Route path="/mypage/edit/dna-test" component={EditDnaTestPage} />
          <Route path="/mypage/edit" component={EditMyPage} />
          <Route path="/contents/:id" component={ContentDetailPage} />

          <Route path="/mission/:id/review" component={MissionReviewPage} />
          <Route path="/comments/:userId" component={CommentsPage} />
          <Route path="/comments" component={MyCommentsPage} />

          <Route path="/mission/:id" component={MissionDetailPage} />
          <Route path="/onboarding" component={OnboardingPage} />
          <Route path="/manage" component={ManagePage} />
          <Route path={CONTENTS_ROUTE} component={ContentsPage} />
          <Route path={MYPAGE_ROUTE} component={MyPage} />
          {/* TODO: 테스트용 라우터 */}
          <Route path="/test" component={TestPage} />
          <Route path={HOME_ROUTE} component={HomePage} />
          <Route path="/">
            <Redirect to={'/login'} />
          </Route>
        </Switch>
      </main>

      {includes(SHOW_BOTTOM_NAVBAR_ROUTES, pathname) && (
        <div className="pt-20">
          <BottomNavBar />
        </div>
      )}
      {play && <CongratsAnim play={play} />}
    </div>
  );
};
