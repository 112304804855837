import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { TextField } from '../../../components/TextField';
import { useAdminAuth } from '../../../hooks';
import { meState } from '../../../plugins/ridge';
import { Role } from '../../../types';

interface FormValues {
  email: string;
  password: string;
}

export const AdminLogin = () => {
  const me = meState.useValue();
  const { replace } = useHistory();
  const { login, apiErrorMsg } = useAdminAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (me === 0 || me === -1) {
      return;
    }
    if (me.role === Role.ADMIN) {
      replace('/admin/users');
    }
  }, [me]);

  return (
    <div className="flex flex-col items-center justify-center py-8">
      <Icon.AdminLogo className="h-8" />
      <form
        className="w-1/3 space-y-5 py-8"
        onSubmit={handleSubmit((data) => login(data))}
      >
        <h3 className="mt-8">관리자 로그인</h3>
        <TextField
          label="아이디"
          placeholder="아이디를 입력해주세요."
          helper={errors.email?.message || apiErrorMsg?.email}
          {...register('email', {
            pattern: {
              value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/,
              message: '올바른 이메일 주소가 아닙니다.',
            },
          })}
        />
        <TextField
          label="비밀번호"
          placeholder="비밀번호를 입력해주세요."
          type="password"
          helper={apiErrorMsg?.password}
          {...register('password')}
        />

        <div className="flex flex-col py-8">
          <Button text="로그인" className="filled-brand-1" type="submit" />
        </div>
      </form>
    </div>
  );
};
