import React, { ButtonHTMLAttributes, FC } from 'react';

export interface ChoiceButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean;
  text: string;
}

export const ChoiceButton: FC<ChoiceButtonProps> = ({
  selected,
  text,
  ...props
}) => {
  return (
    <>
      <button
        {...props}
        type="button"
        className={`${
          selected
            ? ' border-brand-1 font-bold text-brand-1'
            : 'border-gray-100 bg-gray-50 text-gray-600'
        } w-full whitespace-pre rounded-md border py-5`}
      >
        {text}
      </button>
    </>
  );
};
