import React, { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PostMissionGroupsResponse } from '../../api/mission-groups/type';
import { ReactComponent as FlagIllustration } from '../../assets/svg/flag-illustration.svg';
import { BottomFixedButton } from '../../components/BottomFixedButton';
import {
  getItemInLocalStorage,
  LOCAL_STORAGE_KEY,
  removeItemInLocalStorage,
} from '../../plugins/local-storage';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { dnaTestState } from '../../plugins/ridge';

type Mission = Partial<PostMissionGroupsResponse>;

export const SuccessPage = () => {
  const { state } = useLocation<PostMissionGroupsResponse>();
  const [mission, setMission] = useState<Mission>({});
  const [categoryName, setCategoryName] = useState('');
  const { goBack } = useHistory();
  const { title, startAt, endAt } = mission;

  useEffect(() => {
    const category = getItemInLocalStorage(LOCAL_STORAGE_KEY.CATEGORY);
    const categoryName = get(category, 'name', '');
    if (
      state?.title &&
      state?.startAt &&
      state?.endAt &&
      !isEmpty(categoryName)
    ) {
      const { title, startAt, endAt } = state;
      setMission({ title, startAt, endAt });
      setCategoryName(categoryName);

      dnaTestState.reset();
      removeItemInLocalStorage(LOCAL_STORAGE_KEY.CATEGORY);
      removeItemInLocalStorage(LOCAL_STORAGE_KEY.CATEGORY_TYPE);
      removeItemInLocalStorage(LOCAL_STORAGE_KEY.TEST_INFO);
    } else {
      toast.error('잘못된 접근입니다.');
      goBack();
    }
  }, []);

  return (
    <>
      <div className="grid h-[90vh] place-content-center text-center ">
        <FlagIllustration className="relative mx-auto h-[240px] w-[240px]" />

        <h4 className="pt-8 text-gray-600">예방 습관 미션이 설정되었어요!</h4>

        <h3 className="mt-6">{categoryName}예방</h3>

        <h1 className="my-3 text-brand-1">{title}</h1>

        <div className="mt-2 flex justify-center space-x-4 text-14">
          <span className="text-gray-600">시작</span>
          <span className="">
            {startAt &&
              utcToLocalFormat(startAt.toLocaleString(), MomentFormat.LLdd)}
          </span>
        </div>
        <div className="flex justify-center space-x-4 text-14">
          <span className="text-gray-600">종료</span>
          <span className="">
            {endAt &&
              utcToLocalFormat(endAt.toLocaleString(), MomentFormat.LLdd)}
          </span>
        </div>
      </div>

      <BottomFixedButton text="목표 습관 보러가기" to="/" />
    </>
  );
};
