import React, { useState } from 'react';
import { map } from 'lodash';
import { parse } from 'qs';
import { Link, useLocation } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Search } from '../../../components/Search';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE } from '../../../constants';
import { useAdminUsers } from '../../../hooks/users';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { AdminH1 } from '../../components/AdminH1';

export const UserList = () => {
  const [searchValue, setSearchValue] = useState('');
  const { search } = useLocation();
  const { page, limit } = parse(search, { ignoreQueryPrefix: true });
  const { data: usersResponse } = useAdminUsers({
    page: typeof page === 'string' ? +page : 1,
    itemsPerPage: typeof limit === 'string' ? +limit : ITEMS_PER_PAGE,
    filter: JSON.stringify({
      email: { ilike: searchValue },
    }),
    sort: JSON.stringify({ id: 'DESC' }),
  });

  if (!usersResponse) return null;
  const { items, pagination } = usersResponse;

  return (
    <div className="space-y-3 p-6">
      <div className="items-center justify-between">
        <AdminH1>유저리스트</AdminH1>
        <div className="flex items-end space-x-3">
          <div>
            <Search className="h-10" setValue={setSearchValue} />
          </div>
        </div>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>NO.</Table.Th>
              <Table.Th>가입일시</Table.Th>
              <Table.Th>닉네임</Table.Th>
              <Table.Th>이메일</Table.Th>
              <Table.Th>휴대폰번호</Table.Th>
              <Table.Th>유전자 검사 결과</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(items, (user, index) => (
              <Table.Row key={user.id}>
                <Table.Td>{index + 1}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(user.createdAt, MomentFormat.YYYYMMDD)}
                </Table.Td>
                <Table.Td>{user.name}</Table.Td>
                <Table.Td>{user.email}</Table.Td>
                <Table.Td>{user.phoneNumber}</Table.Td>
                <Table.Td>{user.isTested ? 'O' : 'X'}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/users/${user.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={pagination.currentItemCount}
            total={pagination.totalItemCount}
          />
          <Pagination.Nav
            basePath={window.location.pathname}
            total={pagination.totalItemCount}
          />
        </Pagination>
      </Card>
    </div>
  );
};
