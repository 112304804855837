import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { resetPassword } from '../../../../api/auth';
import {
  FindPasswordResponse,
  ResetPasswordDto,
} from '../../../../api/auth/type';
import { BottomFixedButton } from '../../../../components/BottomFixedButton';
import { TextField } from '../../../../components/TextField';
import { TopNavBar } from '../../../../components/TopNavBar';

interface StateType {
  userId: number;
  uuid: string;
}

interface FormValues {
  password: string;
  passwordConfirm: string;
}

export const ResetPasswordPage = () => {
  const [userData, setUserData] = useState<FindPasswordResponse>({
    uuid: '',
    userId: 0,
  });
  const { push, goBack } = useHistory();
  const { state } = useLocation<StateType>();

  const { mutate: resetPasswordMutate } = useResetPassword(() => {
    push('/login/email');
  });

  const formSchema = Yup.object({
    password: Yup.string()
      .required('영문, 숫자포함 8자리를 입력해주세요.')
      .min(8, '최소 8자 이상 가능합니다')
      .max(15, '최대 15자 까지만 가능합니다')
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"])[0-9a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]{8,15}$/,
        '영문 숫자포함 8자리를 입력해주세요.'
      ),
    passwordConfirm: Yup.string()
      .required('비밀번호를 한번 더 입력해주세요')
      .oneOf([Yup.ref('password')], '비밀번호가 다릅니다.'),
  });

  const {
    register,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (state?.userId && state?.uuid) {
      const { userId, uuid } = state;
      setUserData({
        uuid,
        userId,
      });
    } else {
      toast.error('잘못된 접근입니다.');
      goBack();
    }
  }, []);

  const onClickSubmit = (password: string) => {
    const { uuid, userId } = userData;
    const resetPasswordDto = {
      uuid,
      userId,
      password,
    };
    resetPasswordMutate(resetPasswordDto);
  };

  const passwordHelper =
    !errors.password?.message && getValues('password')?.length >= 8
      ? '사용 가능한 비밀번호입니다.'
      : errors.password?.message;

  const passwordConfirmHelper =
    !errors.passwordConfirm?.message &&
    getValues('passwordConfirm')?.length >= 8
      ? '비밀번호가 일치합니다.'
      : errors.passwordConfirm?.message;

  return (
    <>
      <TopNavBar title="비밀번호 변경" />

      <h2 className="mt-10 leading-normal">새 비밀번호를 설정해주세요.</h2>

      <form
        className="mt-6 space-y-6"
        onSubmit={handleSubmit((data) => {
          onClickSubmit(data.password);
        })}
      >
        <TextField
          {...register('password')}
          type="password"
          label="비밀번호"
          placeholder="영문 숫자 포함 8자리를 입력해주세요."
          helper={passwordHelper}
          isError={errors.password?.message ? true : false}
        />

        <TextField
          {...register('passwordConfirm')}
          type="password"
          label="비밀번호 재입력"
          placeholder="비밀번호를 재입력해주세요."
          helper={passwordConfirmHelper}
          isError={errors.passwordConfirm?.message ? true : false}
        />

        <BottomFixedButton
          disabled={
            !isEmpty(errors) || !watch('password') || !watch('passwordConfirm')
          }
          type="submit"
          text="변경하기"
        />
      </form>
    </>
  );
};

const useResetPassword = (onSuccess?: () => void) => {
  return useMutation(
    (resetPasswordDto: ResetPasswordDto) => resetPassword(resetPasswordDto),
    {
      onSuccess: () => {
        toast.success('비밀번호 변경이 완료되었습니다.');
        onSuccess && onSuccess();
      },
      onError: (e) => {
        console.log(e);
        toast.error('비밀번호 변경에 실패했습니다.');
      },
    }
  );
};
