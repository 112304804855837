import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from './Icon';

interface TopNavBarProps {
  title?: string;
  goBackTo?: () => void;
  backButton?: boolean;
}

export const TopNavBar: FC<TopNavBarProps> = ({
  title = '',
  goBackTo,
  backButton = true,
}) => {
  const { goBack } = useHistory();
  const handleBack = () => {
    if (goBackTo) {
      goBackTo();
    } else {
      goBack();
    }
  };
  return (
    <div className="grid place-content-center py-4">
      {backButton && (
        <button onClick={handleBack} className="absolute left-5 top-4">
          <Icon.ArrowLeft />
        </button>
      )}

      {title && <h5 className="">{title}</h5>}
    </div>
  );
};
