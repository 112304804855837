import { Fragment, ReactNode } from 'react';
import { Popover, Transition } from '@headlessui/react';

interface TransitionLayoutProps {
  children: ReactNode;
  onClose: () => void;
  open: boolean;
}

export default function TransitionLayout({
  children,
  onClose,
  open,
}: TransitionLayoutProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="fixed top-0 bottom-0 z-[100] -mx-5 flex h-[100%] w-full max-w-md flex-col">
        <div
          className="h-full w-full  bg-gray-900 bg-opacity-20 transition-opacity"
          onClick={() => onClose()}
        />

        <div className="inline-block  w-full max-w-md  bg-gray-900 bg-opacity-20 transition-opacity">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4"
          >
            {children}
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
}
