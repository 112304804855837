import React, { useEffect, useState } from 'react';
import { get, isEmpty, map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Mission } from '../../api/categories/type';
import { PostMissionGroupsResponse } from '../../api/mission-groups/type';
import { BottomFixedButton } from '../../components/BottomFixedButton';
import { SelectPrevention } from '../../components/SelectPrevention';
import { TopNavBar } from '../../components/TopNavBar';
import { useModal } from '../../components/modal/Modal';
import { useMissionsByCategory } from '../../hooks/categories';
import { usePostMissionGroups } from '../../hooks/mission-groups';
import { useUpdateTestResult } from '../../hooks/users';
import {
  getItemInLocalStorage,
  LOCAL_STORAGE_KEY,
  removeItemInLocalStorage,
} from '../../plugins/local-storage';
import { dnaTestState } from '../../plugins/ridge';

export const StepFivePage = () => {
  const [categoryId, setCategoryId] = useState(-1);
  const [selectedMission, setSelectedMission] = useState<Mission | undefined>();
  const { createMissionComplete, missionExist } = useModal();
  const { data: missionData } = useMissionsByCategory(categoryId);
  const { mutate: postMissionMutate } = usePostMissionGroups(
    (data: PostMissionGroupsResponse) => onSuccess(data),
    () => onError()
  );
  const { mutate: testResultMutate } = useUpdateTestResult();
  const { missionConfirmation } = useModal();

  const { goBack, push, replace } = useHistory();
  const { isTested, isFirstTime } = dnaTestState.useValue();
  const missions = missionData?.missions;

  const handleNext = () => {
    if (!selectedMission) return;

    missionConfirmation(() =>
      postMissionMutate({ missionId: selectedMission.id })
    );
  };

  const updateTestResult = () => {
    const testResultDto = getItemInLocalStorage(LOCAL_STORAGE_KEY.TEST_INFO);
    const testResultImage = get(testResultDto, 'testResultImage', '');
    if (!isEmpty(testResultImage)) {
      testResultMutate({ categoryId, testResultImage, isTested });
    } else {
      testResultMutate({ categoryId, isTested });
    }
  };

  const onSuccess = (data: PostMissionGroupsResponse) => {
    updateTestResult();
    if (isFirstTime) {
      push({
        pathname: '/dna-test/success',
        state: data,
      });
    } else {
      selectedMission && createMissionComplete(selectedMission.title);
    }
  };

  const onError = () => {
    missionExist(() => {
      removeItemInLocalStorage(LOCAL_STORAGE_KEY.TEST_INFO);
      replace('/dna-test/3');
    });
  };

  useEffect(() => {
    const category = getItemInLocalStorage(LOCAL_STORAGE_KEY.CATEGORY);
    const selectedCategoryId = get(category, 'id', -1);
    if (selectedCategoryId === -1) {
      goBack();
      toast('잘못된 접근입니다.');
    } else {
      setCategoryId(selectedCategoryId);
    }
  }, []);

  return (
    <>
      <TopNavBar />

      <h2 className="pt-8 pb-2">이제 마지막 단계예요!</h2>
      <p className="mb-10 text-15 leading-normal text-gray-500">
        아래 예방 미션 중 1가지를 선택하여 매일 루틴으로 만들어 보세요. 매일
        실천 여부를 아이힐미에 기록하고 30일간 도전하여 나의 습관으로
        만들어보세요!
      </p>

      <form className="pb-28">
        {!isEmpty(missions) ? (
          map(missions, (mission) => (
            <div
              className="mb-6 border-b border-gray-100 pb-6"
              key={mission.id}
            >
              <SelectPrevention
                prevention={mission}
                selected={mission.id === selectedMission?.id}
                onClick={() => setSelectedMission(mission)}
              />
            </div>
          ))
        ) : (
          <div className="flex h-48 items-center justify-center rounded-lg border border-gray-100 bg-gray-50 p-5">
            <div className="text-center text-15 font-bold text-brand-1">
              아직 확실하게 알려진 예방수칙이 없습니다.
              <br />
              다른 미션에 도전해 보세요!
              <br />
              추후 예방 습관 미션이 업데이트 될 수 있습니다.
            </div>
          </div>
        )}
      </form>

      <BottomFixedButton
        text="다음"
        onClick={handleNext}
        disabled={!selectedMission}
      />
    </>
  );
};
