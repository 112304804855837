import React from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { AdminTab } from '../../components/AdminTab';
import { SurveyAnswer } from './SurveyAnswer';
import { SurveyInfo } from './SurveyInfo';
import { SurveyQuestion } from './SurveyQuestion';

export const SurveyDetail = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { id } = useParams() as { id: string };
  return (
    <div>
      <div className="flex w-full space-x-4 border-b pt-6">
        <AdminTab
          text="질문 정보"
          selected={pathname === `/admin/survey/${id}/info`}
          onClick={() => push(`/admin/survey/${id}/info`)}
        />
        <AdminTab
          text="질문 리스트"
          selected={pathname === `/admin/survey/${id}/question`}
          onClick={() => push(`/admin/survey/${id}/question`)}
        />
        <AdminTab
          text="답변 정보"
          selected={pathname === `/admin/survey/${id}/answer`}
          onClick={() => push(`/admin/survey/${id}/answer`)}
        />
      </div>
      <Switch>
        <Route component={SurveyAnswer} path="/admin/survey/:id/answer" />
        <Route component={SurveyQuestion} path="/admin/survey/:id/question" />
        <Route component={SurveyInfo} path="/admin/survey/:id/info" />
      </Switch>
    </div>
  );
};
