import React, { forwardRef, InputHTMLAttributes, useState } from 'react';
import { isEmpty, some } from 'lodash';
import { VerifyType } from '../api/phone-validations/enum';
import {
  useSendPhoneValidation,
  useValidateCode,
} from '../hooks/phone-validations';
import { Button } from './Button';
import { Label } from './Label';
import { TextField } from './TextField';

interface PhoneNumberProps extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  isVerified: boolean;
  setIsVerified: React.Dispatch<React.SetStateAction<boolean>>;
  verifyType: VerifyType;
  helper?: string;
}

export const PhoneNumber = forwardRef<HTMLInputElement, PhoneNumberProps>(
  ({ ...props }, ref) => {
    const {
      isVerified,
      setIsVerified,
      verifyType,
      value: phoneNumber,
      helper,
      onChange,
      onBlur,
      name,
    } = props;
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isWrongCode, setIsWrongCode] = useState(false);
    const [authCode, setAuthCode] = useState('');
    const { mutate: sendPhoneValidationMutate } = useSendPhoneValidation(() =>
      setIsCodeSent(true)
    );
    const { mutate: validateCodeMutate } = useValidateCode(
      () => {
        setIsVerified(true);
        setIsWrongCode(false);
      },
      () => setIsWrongCode(true)
    );

    return (
      <div>
        <Label text="핸드폰 번호" />
        <div className="mt-1 mb-4">
          <TextField
            ref={ref}
            type="tel"
            maxLength={11}
            pattern="\d{11}"
            placeholder="'-'를 제외하고 번호만 입력해주세요."
            disabled={isVerified}
            helper={helper}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
          />
        </div>

        <Button
          type="button"
          onClick={() => {
            sendPhoneValidationMutate({ phoneNumber, verifyType });
          }}
          text={isCodeSent ? '인증번호 재전송' : '인증번호 전송'}
          className="outlined-brand-1 w-full"
          disabled={some([isVerified, !!helper, isEmpty(phoneNumber)])}
        />

        {isCodeSent && (
          <>
            <div className="mt-4 grid grid-cols-4 gap-x-4">
              <div className="col-span-3">
                <TextField
                  placeholder="인증번호를 입력해주세요."
                  type="number"
                  disabled={isVerified}
                  value={authCode}
                  onChange={(e) => setAuthCode(e.target.value)}
                />
              </div>

              <Button
                onClick={() => {
                  validateCodeMutate({ phoneNumber, validCode: authCode });
                }}
                type="button"
                text="확인"
                className="filled-brand-1 h-12 w-full min-w-16 font-normal"
              />
            </div>

            {isVerified && (
              <p className="mt-2 text-13 text-gray-800">
                *인증이 완료되었습니다.
              </p>
            )}
            {isWrongCode && (
              <p className="mt-2 text-13 text-red-500">*인증번호가 틀립니다.</p>
            )}
          </>
        )}
      </div>
    );
  }
);
