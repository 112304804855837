import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { getMission } from '../../../api/missions';
import { Tab } from '../../../components/Tab';
import { TopNavBar } from '../../../components/TopNavBar';
import { NAV_BAR } from '../../../constants';
import { currentMissionState, navBarState } from '../../../plugins/ridge';
import { CommunityPage } from './community/CommunityPage';
import { ProgressPage } from './progress/ProgressPage';

const TAB = {
  PROGRESS: 0,
  COMMUNITY: 1,
};

export const MissionDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { missionId } = currentMissionState.useValue();
  const [selectedTab, setSelectedTab] = useState(TAB.PROGRESS);
  const navBar = navBarState.useValue();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const { data: mission } = useQuery(
    `mission-${missionId}`,
    () => getMission(missionId),
    { enabled: !!missionId }
  );

  useEffect(() => {
    const tab = pathname.split('/');
    const tabName = tab[tab.length - 1];
    if (tabName === 'progress') {
      setSelectedTab(TAB.PROGRESS);
    } else {
      setSelectedTab(TAB.COMMUNITY);
    }
  }, [pathname]);

  if (!mission) return null;

  return (
    <div>
      <TopNavBar
        goBackTo={() => {
          if (navBar === NAV_BAR.MANAGE) {
            push(`/manage?tab=1`);
          } else {
            push(`/mission`);
          }
        }}
      />

      <div className="-mx-5 my-5 grid grid-cols-2 border-t border-b border-gray-100">
        <Tab
          text="진행률"
          onClick={() => {
            push(`/mission/${id}/progress`);
          }}
          selected={selectedTab === TAB.PROGRESS}
        />
        <Tab
          text="함께하는 사람"
          onClick={() => {
            push(`/mission/${id}/community`);
          }}
          selected={selectedTab === TAB.COMMUNITY}
        />
      </div>

      <div className="space-y-4 text-center">
        <div className="space-y-1 ">
          <h4>{mission.title}</h4>
          <p className="text-13 text-gray-500">{`${mission.category.name} 예방 습관을 진행중입니다!`}</p>
        </div>
        <div className="bg-gray-50 py-4 text-15">️{mission.description}</div>
      </div>

      <Switch>
        <Route path={`/mission/:id/progress`} component={ProgressPage} />
        <Route path={`/mission/:id/community`} component={CommunityPage} />

        <Route path="/">
          <Redirect to={`/mission/${id}/progress`} />
        </Route>
      </Switch>
    </div>
  );
};
