import { ButtonHTMLAttributes, FC } from 'react';
import { Button } from './Button';

interface BottomFixedButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  to?: string;
  text: string;
}

export const BottomFixedButton: FC<BottomFixedButtonProps> = ({
  to,
  text,
  ...props
}) => {
  return (
    <div className="fixed bottom-4 -mx-5 w-full px-5 pt-12">
      <Button
        {...props}
        text={text}
        to={to}
        className="filled-brand-1 w-full"
      />
    </div>
  );
};
