export const HOME_ROUTE = '/mission';
export const MANAGE_ROUTE = '/manage';
export const CONTENTS_ROUTE = '/contents';
export const MYPAGE_ROUTE = '/mypage';

export const SHOW_BOTTOM_NAVBAR_ROUTES = [
  HOME_ROUTE,
  MANAGE_ROUTE,
  CONTENTS_ROUTE,
  MYPAGE_ROUTE,
];
