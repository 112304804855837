import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, some } from 'lodash';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { findPassword } from '../../../api/auth';
import { FindPasswordDto, FindPasswordResponse } from '../../../api/auth/type';
import { VerifyType } from '../../../api/phone-validations/enum';
import { BottomFixedButton } from '../../../components/BottomFixedButton';
import { PhoneNumber } from '../../../components/PhoneNumber';
import { TextField } from '../../../components/TextField';
import { TopNavBar } from '../../../components/TopNavBar';

interface FormValues {
  email: string;
  phoneNumber: string;
}

export const FindPasswordPage = () => {
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const { push } = useHistory();

  const formSchema = Yup.object({
    email: Yup.string()
      .required('이메일을 입력해주세요')
      .email('이메일 형식이 아닙니다.'),
    phoneNumber: Yup.string()
      .required('휴대폰 번호를 입력해주세요.')
      .matches(/^\d{11}$/, '휴대폰 번호 형식이 아닙니다.'),
  });

  const {
    register,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  const goResetPasswordPage = (res: FindPasswordResponse) => {
    const { uuid, userId } = res;
    push({
      pathname: '/find/password/reset',
      state: {
        uuid,
        userId,
      },
    });
  };

  const { mutate: findPasswordMutate } = useFindPassword(goResetPasswordPage);

  const onSubmit = (data: FormValues) => {
    const { email, phoneNumber } = data;
    findPasswordMutate({ email, phoneNumber });
  };

  return (
    <>
      <TopNavBar title="비밀번호 변경" />

      <h2 className="mt-10 leading-normal">
        비밀번호 변경을 위해 <br />
        핸드폰 인증을 해주세요.
      </h2>

      <form
        className="mt-6 space-y-6"
        onSubmit={handleSubmit((data) => {
          onSubmit(data);
        })}
      >
        <TextField
          label="이메일"
          placeholder="이메일을 입력해주세요."
          helper={errors.email?.message}
          {...register('email')}
        />

        <PhoneNumber
          {...register('phoneNumber')}
          value={getValues('phoneNumber')}
          verifyType={VerifyType.RESETPASSWORD}
          isVerified={isPhoneVerified}
          setIsVerified={setIsPhoneVerified}
          helper={errors.phoneNumber?.message}
        />

        <BottomFixedButton
          disabled={some([
            !isPhoneVerified,
            !isEmpty(errors),
            !watch('email'),
            !watch('phoneNumber'),
          ])}
          type="submit"
          text="다음"
        />
      </form>
    </>
  );
};

const useFindPassword = (onSuccess?: (res: FindPasswordResponse) => void) => {
  return useMutation(
    (findPasswordDto: FindPasswordDto) => findPassword(findPasswordDto),
    {
      onSuccess: (res: FindPasswordResponse) => {
        onSuccess && onSuccess(res);
      },
      onError: (e) => {
        console.log(e);
        toast.error('가입된 이메일이 없습니다.');
      },
    }
  );
};
