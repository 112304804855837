export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum HealthCare {
  EXERCISE = 'EXERCISE',
  NUTRIENTS = 'NUTRIENTS',
  FOODMANAGEMENT = 'FOODMANAGEMENT',
  NONE = 'NONE',
}
