import { ButtonHTMLAttributes, FC } from 'react';
import { Button } from './Button';

interface BottomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

export const BottomButton: FC<BottomButtonProps> = ({ text, ...props }) => {
  return (
    <div className="bottom-4 w-full">
      <Button {...props} text={text} className="filled-brand-1 w-full" />
    </div>
  );
};
