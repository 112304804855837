import React, { FC, ImgHTMLAttributes, useRef } from 'react';
import { isEmpty } from 'lodash';
import { ReactComponent as AvatarIcon } from '../assets/icons/icon-avatar.svg';
import { ReactComponent as PencilIcon } from '../assets/icons/icon-pencil.svg';
import { Action, Bridge } from '../plugins/bridge';
import { currentRefState } from '../plugins/ridge';

export interface EditAvatarProps extends ImgHTMLAttributes<HTMLImageElement> {
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const EditAvatar: FC<EditAvatarProps> = ({
  className = '',
  src,
  alt = 'avatar',
  handleImageChange,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onClickImage = () => {
    currentRefState.set(inputRef.current);
    Bridge.postMessage(Action.CHECK_CAMERA_PERMISSION, '');
  };

  return (
    <>
      <label htmlFor="avatar">
        <button type="button" className="relative" onClick={onClickImage}>
          {!isEmpty(src) ? (
            <div
              className={`${
                className ? className : 'wh-16'
              }  relative overflow-hidden rounded-full`}
            >
              <img
                src={src}
                alt={alt}
                {...props}
                className="absolute h-full w-full object-cover"
              />
            </div>
          ) : (
            <AvatarIcon className={`${className ? className : 'wh-16'} `} />
          )}

          <PencilIcon className="absolute right-0 bottom-0" />
        </button>
      </label>
      <input
        ref={inputRef}
        type="file"
        id="avatar"
        name="avatar"
        className="sr-only"
        accept="image/png, image/jpeg"
        onChange={handleImageChange}
      />
    </>
  );
};
