import React, { FC, useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';

export interface CongratsAnimProps {
  play: boolean;
}

export const CongratsAnim: FC<CongratsAnimProps> = ({ play }) => {
  const [data, setData] = useState<any>();
  useEffect(() => {
    import('../assets/img/congrats.json').then(setData);
  }, []);
  return (
    <div className="absolute flex h-screen w-full">
      <div className="m-auto">
        <Lottie animationData={data} play={play} />
      </div>
    </div>
  );
};
