import { AxiosResponse } from 'axios';
import { map } from 'lodash';
import { Paginated } from '../types';
import { adminApi, api } from './axios';

export function fetcher({ queryKey }: any) {
  return api.get(queryKey[0]).then(({ data }) => data);
}

export function adminFetcher({ queryKey }: any) {
  return adminApi.get(queryKey[0]).then(({ data }) => data);
}

export function getNextPageParam(lastPage: AxiosResponse<Paginated<any>>) {
  const {
    data: {
      pagination: { currentPage, totalPage },
    },
  } = lastPage;
  const nextPage = currentPage + 1;
  return nextPage <= totalPage ? nextPage : undefined;
}

export function getArrayQuery(param: string, values: number[]) {
  return map(values, (value) => `${param}=${value}`).join('&');
}
