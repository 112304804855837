import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PATHS as POINT_PATH } from '../../api/points/paths';
import { PointTotalResponse } from '../../api/points/type';
import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Button';
import { Icon } from '../../components/Icon';
import { useModal } from '../../components/modal/Modal';
import { AGREEMENT_LINK } from '../../constants';
import { useAuth } from '../../hooks';
import { Action, Bridge } from '../../plugins/bridge';
import { fetcher } from '../../plugins/react-query';
import { versionState } from '../../plugins/ridge';
import { User } from '../../types';

export const MyPage = () => {
  const { replace, push } = useHistory();
  const { logoutConfirmation } = useModal();
  const { logout } = useAuth();
  const { data: me } = useQuery<User>('users/me', fetcher);
  const { data: point } = useQuery<PointTotalResponse>(
    POINT_PATH.TOTAL,
    fetcher
  );
  const version = versionState.get();

  const _logout = () => {
    logout();
    toast.success('로그아웃 되었습니다.');
    replace('/');
  };

  if (!me || !point) return <></>;

  return (
    <>
      <div className="mt-5 flex space-x-3">
        <Avatar src={me.avatar} className="wh-14" />
        <div className="space-y-1">
          <h3>반가워요, {me.name}님</h3>
          <div
            onClick={() => push('/mypage/points')}
            className="flex  text-15 text-gray-500"
          >
            <h5 className="pr-3">힐링 스코어</h5> |{' '}
            <p className="ml-4 font-bold text-brand-1">
              {point.total.toLocaleString()}P
            </p>
            <Icon.ChevronRight className=" w-5 text-brand-1" />
          </div>
        </div>
      </div>
      <Button
        type="button"
        to="/mypage/edit/select-dna-test"
        className="filled-brand-1 mt-6"
        text={'검사결과 입력하기'}
      />

      <div className="-mx-5 my-6 h-4 bg-gray-50" />

      <InfoItem title={'내 정보 수정'} onClick={() => push('/mypage/edit')} />
      <InfoItem
        title={'알림 설정'}
        onClick={() => {
          Bridge.postMessage(Action.OPEN_APP_SETTING);
        }}
      />
      <InfoItem
        title={'서비스 이용약관'}
        onClick={() => {
          Bridge.postMessage(Action.OPEN_LINK, AGREEMENT_LINK[0]);
        }}
      />
      <InfoItem
        title={'개인정보취급방침'}
        onClick={() => {
          Bridge.postMessage(Action.OPEN_LINK, AGREEMENT_LINK[1]);
        }}
      />
      <InfoItem
        title={'민감정보 수집 /이용'}
        onClick={() => {
          Bridge.postMessage(Action.OPEN_LINK, AGREEMENT_LINK[2]);
        }}
      />
      <InfoItem
        title={'미션출처'}
        onClick={() => {
          Bridge.postMessage(Action.OPEN_LINK, AGREEMENT_LINK[3]);
        }}
      />

      <InfoItem
        title={'로그아웃'}
        onClick={() => logoutConfirmation(_logout)}
      />
      <div className="text-right text-gray-400">
        {isEmpty(version) ? '' : `v${version}`}
      </div>
    </>
  );
};

interface InfoItemProps {
  onClick: () => void;
  title: string;
}

export const InfoItem: FC<InfoItemProps> = ({ onClick, title }) => {
  return (
    <div onClick={onClick} className="mb-4 border-b border-gray-100 pb-4">
      {title}
    </div>
  );
};
