import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Admin } from './admin/Admin';
import { AdminRoute } from './components/AdminRoute';
import { ScrollToTop } from './components/ScrollToTop';
import { Modal } from './components/modal/Modal';
import { Router } from './pages/Router';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <Modal />
      <ScrollToTop />
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/" component={Router} />
      </Switch>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
