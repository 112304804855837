import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { AdminCategory } from '../../../api/categories/type';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Section } from '../../../components/Section';
import { Table } from '../../../components/Table';
import { CategoryAddModal } from '../../components/CategoryAddModal';

interface SubCategoryProps {
  categories: AdminCategory[];
  mutateCategory: (name: string, isOpen: boolean, id?: number) => void;
  selectedCategoryId: number;
  setSelectedCategoryId: Dispatch<SetStateAction<number>>;
}

const EMPTY_CATEGORY = {
  id: -1,
  name: '',
  isOpen: true,
};
export const SubCategory: FC<SubCategoryProps> = ({
  categories,
  mutateCategory,
  selectedCategoryId,
  setSelectedCategoryId,
}) => {
  const [open, setOpen] = useState(false);
  const [categoryData, setCategoryData] = useState(EMPTY_CATEGORY);

  const _setSelectedCategoryId = (id: number) => {
    setSelectedCategoryId(id);
  };

  const showUpdateCategoryModal = (data: AdminCategory) => {
    const { id, name, isOpen } = data;
    setCategoryData({ id, name, isOpen });
    setOpen(true);
  };

  const resetData = () => {
    setCategoryData(EMPTY_CATEGORY);
  };

  return (
    <Section className="space-y-3">
      <CategoryAddModal
        open={open}
        onClose={() => setOpen(false)}
        onClick={mutateCategory}
        data={categoryData}
        resetData={resetData}
      />
      <div className="flex items-center justify-between pb-2">
        관련 항목
        <Button
          text="추가"
          className="h-10 bg-gray-200 px-6"
          onClick={() => setOpen(true)}
        />
      </div>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>관련 항목</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {categories.map((data) => (
              <Table.Row key={data.id}>
                <Table.Td
                  className={selectedCategoryId === data.id ? 'text-black' : ''}
                  onClick={() => _setSelectedCategoryId(data.id)}
                >
                  {data.name}
                </Table.Td>

                <Table.Td>
                  <p
                    className="font-normal text-indigo-600"
                    onClick={() => {
                      showUpdateCategoryModal(data);
                    }}
                  >
                    수정
                  </p>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </Section>
  );
};
