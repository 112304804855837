export const LOCAL_STORAGE_KEY = {
  CREATE_USER_INFO: 'create-user-info',
  CATEGORY_TYPE: 'category-type',
  CATEGORY: 'category',
  ONBORDING: 'onboarding',
  TEST_INFO: 'test-info',
  HIDE_SURVEY: 'hide-survey',
  SOCIAL_LOGIN: 'social-login',
  HIDE_COMMENT_MODAL: 'hide-comment-modal',
  DYNAMIC_LINK: 'dynamic-link',
};

export const getItemInLocalStorage = (key: string) => {
  const item = localStorage.getItem(key);
  if (!item) {
    return null;
  }
  try {
    return JSON.parse(item);
  } catch {
    return item;
  }
};

export const setItemInLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeItemInLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
