export enum FirstAnswer {
  FUN = 'FUN', // 재밌었어요
  IFFY = 'IFFY', // 그저 그랫어요
  HARD = 'HARD', // 힘들었어요
  UNDEFINED = 'UNDEFINED',
}

export enum SecondAnswer {
  HELPED = 'HELPED', // 네, 도움이 되었어요
  NOPE = 'NOPE', // 잘모르겠어요
  UNDEFINED = 'UNDEFINED',
}

export enum ThirdAnswer {
  GOODCHANGE = 'GOODCHANGE', // 몸 상태에 좋은 변화가 있었어요
  NOTYET = 'NOTYET', // 아직은 잘 모르겠어요
  WILLING = 'WILLING', //꾸준히 해야겠다는 의지가 생겼어요
  UNDEFINED = 'UNDEFINED',
}

export enum QuestionType {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
}
