import React, { FC } from 'react';
import { Progress } from '../Progress';

interface MissionProgressBarProps {
  title: string;
  success: number;
}

export const MissionProgressBar: FC<MissionProgressBarProps> = ({
  title,
  success,
}) => {
  return (
    <div className="flex flex-col space-y-1">
      <div className="flex justify-between text-13">
        <div className="flex space-x-3 font-semibold">
          <span className="">{title}</span>
        </div>
        <div className="flex w-64 justify-end space-x-1">
          <span className="text-brand-1">{success}회 성공</span>
          <span className="text-gray-500">{success}/30회</span>
        </div>
      </div>
      <Progress max={100} value={(success / 30) * 100} />
    </div>
  );
};
