import React from 'react';
import { map } from 'lodash';
import { parse } from 'qs';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE } from '../../../constants';
import { useAdminContents } from '../../../hooks/contents';
import { copyToClipboard } from '../../../plugins/clipboard';
import isDev from '../../../plugins/dev-detect';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { AdminH1 } from '../../components/AdminH1';

export const ContentsList = () => {
  const { search } = useLocation();
  const { page, limit } = parse(search, { ignoreQueryPrefix: true });
  const { push } = useHistory();
  const { data: contentsResponse } = useAdminContents({
    page: typeof page === 'string' ? +page : 1,
    itemsPerPage: typeof limit === 'string' ? +limit : ITEMS_PER_PAGE,
    sort: JSON.stringify({ order: 'ASC' }),
  });

  if (!contentsResponse) return null;
  const { items, pagination } = contentsResponse;

  const getContentUrl = (id: number) => {
    if (isDev()) {
      return `https://stage.ihealme.link/contents/${id}`;
    } else {
      return `https://ihealme.link/contents/${id}`;
    }
  };

  return (
    <div className="space-y-3 p-6">
      <div className="flex items-center space-x-5">
        <AdminH1>콘텐츠 리스트</AdminH1>
        <Button
          text="추가"
          className="filled-gray-100 h-10 text-sm text-black"
          onClick={() => push('/admin/contents/add')}
        />
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>노출순위</Table.Th>
              <Table.Th>등록일시</Table.Th>
              <Table.Th>컨텐츠 제목</Table.Th>
              <Table.Th>URL</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(items, (content) => (
              <Table.Row key={content.id}>
                <Table.Td>{content.order}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    content.createdAt.toLocaleString(),
                    MomentFormat.YYYYMMDD
                  )}
                </Table.Td>
                <Table.Td>{content.title}</Table.Td>
                {content.hasDetail ? (
                  <Table.Td>
                    <button
                      className="text-indigo-600"
                      onClick={(e) => {
                        e.preventDefault();
                        copyToClipboard({
                          value: getContentUrl(content.id),
                        });
                      }}
                    >
                      {getContentUrl(content.id)}
                    </button>
                  </Table.Td>
                ) : (
                  <Table.Td></Table.Td>
                )}

                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/contents/${content.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={pagination.currentItemCount}
            total={pagination.totalItemCount}
          />
          <Pagination.Nav
            basePath={window.location.pathname}
            total={pagination.totalItemCount}
          />
        </Pagination>
      </Card>
    </div>
  );
};
