import { useState } from 'react';
import { parse, stringify } from 'qs';
import { useLocation, useHistory } from 'react-router-dom';
import { adminApi, api } from './plugins/axios';
import { meState, tokenState } from './plugins/ridge';

interface ErrorMessage {
  email?: string;
  password?: string;
}

const _getErrorMsg = (status: number): ErrorMessage => {
  if (status === 401) {
    return { password: '*입력한 비밀번호가 틀립니다.' };
  } else if (status === 404) {
    return { email: '*가입되지 않은 이메일입니다.' };
  }
  return { email: '*로그인에 실패했습니다.' };
};

export const useAuth = () => {
  const [token, setToken] = tokenState.use();
  const [apiErrorMsg, setApiErrorMsg] = useState<ErrorMessage>({});
  const authenticated = token !== null;
  const signup = (data: any) =>
    api
      .post('/auth/signup', data)
      .then(({ data: { token } }) => setToken(token));
  const login = (data: any) =>
    api
      .post('/auth/login', data)
      .then(({ data: { token } }) => setToken(token))
      .catch((err) => {
        setApiErrorMsg(_getErrorMsg(err.response?.status));
      });
  const logout = () => {
    tokenState.reset();
    meState.reset();
  };
  return { token, authenticated, signup, login, logout, apiErrorMsg };
};

export const useAdminAuth = () => {
  const [token, setToken] = tokenState.use();
  const [apiErrorMsg, setApiErrorMsg] = useState<ErrorMessage>({});
  const authenticated = token !== null;
  const login = (data: any) =>
    adminApi
      .post('/auth/login', data)
      .then(({ data: { token } }) => setToken(token))
      .catch((err) => {
        setApiErrorMsg(_getErrorMsg(err.response?.status));
      });
  const logout = () => tokenState.reset();
  return { token, authenticated, login, logout, apiErrorMsg };
};

export function useQueryString(queryObject: any = {}) {
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });
  const searchObject = {
    page: '1',
    limit: '10',
    sort: { id: 'DESC' },
    ...parsed,
    ...queryObject,
  };
  return stringify(searchObject, { addQueryPrefix: true, encode: false });
}
