import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createSurvey } from '../../../api/surveys';
import { CreateSurveyDto } from '../../../api/surveys/type';
import { Button } from '../../../components/Button';
import { TextField } from '../../../components/TextField';

interface FormValues {
  title: string;
  subTitle: string;
  recommendLink: string;
  startAt: string;
  endAt: string;
}

export const SurveyAdd = () => {
  const { register, handleSubmit } = useForm<FormValues>();
  const { push } = useHistory();

  const { mutate: createSurveyMutate } = useCreateSurvey(() =>
    push('/admin/survey/list')
  );

  return (
    <>
      <h2>설문 추가하기</h2>
      <form
        onSubmit={handleSubmit((data) => {
          createSurveyMutate(data);
        })}
      >
        <div className="mt-5 space-y-5">
          <TextField label="제목" {...register('title')} />
          <TextField label="서브 제목" {...register('subTitle')} />
          <TextField label="관련 추천 링크" {...register('recommendLink')} />
          <div className="grid grid-cols-2 gap-x-5">
            <TextField label="시작일" type="date" {...register('startAt')} />
            <TextField label="종료일" type="date" {...register('endAt')} />
          </div>
        </div>
        <div className="flex justify-end space-x-4 py-4">
          <Button
            text="저장하기"
            className="filled-gray-900 h-10 text-sm"
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

const useCreateSurvey = (onSuccess?: () => void) => {
  return useMutation(
    (createSurveyDto: CreateSurveyDto) => createSurvey(createSurveyDto),
    {
      onSuccess: () => {
        toast.success('설문이 생성되었습니다.');
        onSuccess && onSuccess();
      },
      onError: () => {
        toast.error('설문 생성에 실패했습니다.');
      },
    }
  );
};
