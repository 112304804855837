import React, { useEffect, useState } from 'react';
import { isEmpty, map } from 'lodash';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Gender } from '../../../api/auth/enum';
import { PATHS } from '../../../api/survey-questions/paths';
import { SurveyQuestionResponse } from '../../../api/survey-questions/type';
import { getUserSurveyAnswersXlsx } from '../../../api/user-survey-answers';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { Table } from '../../../components/Table';
import { useUserSurveyAnswers } from '../../../hooks/user-survey-answers';
import { adminFetcher } from '../../../plugins/react-query';
import { AdminH1 } from '../../components/AdminH1';

export const SurveyAnswer = () => {
  const { id } = useParams<{ id: string }>();
  const [questionIdx, setQuestionIdx] = useState(-1);
  const [answerId, setAnswerId] = useState(0);
  const { data: questionData } = useQuery<SurveyQuestionResponse>(
    PATHS.getOneBySurveyId(+id),
    adminFetcher,
    {
      enabled: !!id,
    }
  );
  const { data: userData } = useUserSurveyAnswers({ answerId });

  useEffect(() => {
    if (questionIdx === -1) {
      setAnswerId(0);
    }
  }, [questionIdx]);

  const exportXlsx = () => {
    getUserSurveyAnswersXlsx();
  };

  if (!questionData) return <></>;
  const { surveyQuestions } = questionData;

  if (isEmpty(surveyQuestions))
    return (
      <div className="m-6">
        <h2>질문 리스트가 없어 답변 정보를 불러올 수 없습니다.</h2>
      </div>
    );
  return (
    <div className="mt-5 space-y-5">
      <Button
        text="엑셀 Export"
        className="filled-black h-10 font-normal"
        onClick={exportXlsx}
      />
      <Select label="질문" onChange={(e) => setQuestionIdx(+e.target.value)}>
        <option key={0} value={-1}>
          {'--- 선택 ---'}
        </option>
        {map(surveyQuestions, (q, i) => (
          <option key={q.id} value={i}>
            {q.question}
          </option>
        ))}
      </Select>
      <Select label="답변" onChange={(e) => setAnswerId(+e.target.value)}>
        <option key={answerId} value={answerId}>
          {'--- 선택 ---'}
        </option>
        {questionIdx >= 0 &&
          map(surveyQuestions[questionIdx].surveyQuestionAnswers, (a) => (
            <option key={a.id} value={a.id}>
              {a.body}
            </option>
          ))}
      </Select>
      <div className="space-y-3">
        <AdminH1>답변한 유저리스트</AdminH1>

        <Card>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Th>NO.</Table.Th>
                <Table.Th>유저명</Table.Th>
                <Table.Th>성별</Table.Th>
                <Table.Th>몸무게</Table.Th>
                <Table.Th>나이</Table.Th>
                <Table.Th>키</Table.Th>
                <Table.Th>진행한 미션 수</Table.Th>
                <Table.Th>성공한 미션 수</Table.Th>
                <Table.Th>선택한 카테고리</Table.Th>
                <Table.Th>진행 중인 미션명</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {map(userData, (user, i) => (
                <Table.Row key={user.id}>
                  <Table.Td>{i + 1}</Table.Td>
                  <Table.Td>{user.name}</Table.Td>
                  <Table.Td>
                    {user.gender === Gender.MALE ? '남자' : '여자'}
                  </Table.Td>
                  <Table.Td>{`${user.weight}kg`}</Table.Td>
                  <Table.Td>{user.age}</Table.Td>
                  <Table.Td>{user.height}</Table.Td>
                  <Table.Td>{user.totalMissionCount}</Table.Td>
                  <Table.Td>{user.successMissionCount}</Table.Td>
                  <Table.Td>
                    {user.userMissions[0]?.mission.category.name ?? ''}
                  </Table.Td>
                  <Table.Td>
                    {user.userMissions[0]?.mission.title ?? ''}
                  </Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
      </div>
    </div>
  );
};
