import { FC, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { EmojiType } from '../../api/comments/enum';
import { UserDailyMissionStatus } from '../../api/user-daily-missions/enum';
import { ReactComponent as TearsEmoji } from '../../assets/svg/emoji-laugh.svg';
import { ReactComponent as BirthdayEmoji } from '../../assets/svg/emoji-party.svg';
import { ReactComponent as StarEmoji } from '../../assets/svg/emoji-starstruck.svg';
import { utcToIsAfter } from '../../plugins/moment';
import { CommunityCard } from '../../types';
import { Icon } from '../Icon';
import { CreateCommentButton } from './CreateCommentButton';
import { UserCard } from './UserCard';

const STAR = 0;
const BIRTHDAY = 1;
const TEARS = 2;

interface UserMissionProps {
  data: CommunityCard;
  createComment: () => void;
}

export const FriendMission: FC<UserMissionProps> = ({
  data,
  createComment,
}) => {
  const [starEmoji, setStarEmoji] = useState(0);
  const [tearsEmoji, setTearsEmoji] = useState(0);
  const [birthdayEmoji, setBirthdayEmoji] = useState(0);
  const { id, name, avatar, comments, status, endAt } = data;
  const { push } = useHistory();

  const getEmoji = () => {
    const emoji = [0, 0, 0];

    for (const comment of comments) {
      if (comment.emoji === EmojiType.STAR) {
        emoji[STAR]++;
        continue;
      }
      if (comment.emoji === EmojiType.BIRTHDAY) {
        emoji[BIRTHDAY]++;
        continue;
      }
      if (comment.emoji === EmojiType.TEARS) {
        emoji[2]++;
        continue;
      }
    }
    setStarEmoji(emoji[STAR]);
    setBirthdayEmoji(emoji[BIRTHDAY]);
    setTearsEmoji(emoji[TEARS]);
  };

  useEffect(() => {
    getEmoji();
  }, []);

  return (
    <div className="space-y-4">
      <UserCard
        isMe={false}
        name={name}
        avatar={avatar}
        succeeded={status === UserDailyMissionStatus.SUCCESS}
        isEnd={utcToIsAfter(endAt)}
        onClick={() => push(`/comments/${id}`)}
      />

      <div className="flex space-x-2">
        {birthdayEmoji !== 0 && (
          <div className="flex items-center space-x-1 rounded-md bg-gray-50 p-2 text-13 font-medium">
            <BirthdayEmoji />
            <span>{birthdayEmoji}</span>
          </div>
        )}

        {starEmoji !== 0 && (
          <div className="flex items-center space-x-1 rounded-md bg-gray-50 p-2 text-13 font-medium">
            <StarEmoji />
            <span>{starEmoji}</span>
          </div>
        )}

        {tearsEmoji !== 0 && (
          <div className="flex items-center space-x-1 rounded-md bg-gray-50 p-2 text-13 font-medium">
            <TearsEmoji />
            <span>{tearsEmoji}</span>
          </div>
        )}

        {!isEmpty(comments) && (
          <div className="flex items-center space-x-1 rounded-md bg-gray-50 p-2 text-12 font-medium">
            <Icon.Comment className="w-4" />
            <span>{comments.length}개의 응원댓글</span>
          </div>
        )}
      </div>

      <CreateCommentButton onClick={createComment} />
    </div>
  );
};
