import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IllustrationSvg } from '../../../assets/svg/login-illustration.svg';
import { BottomFixedButton } from '../../../components/BottomFixedButton';
import { dnaTestState } from '../../../plugins/ridge';

export const SignUpSuccessPage = () => {
  const { replace } = useHistory();

  return (
    <>
      <div className="grid h-[80vh] place-content-center space-y-6 text-center">
        <IllustrationSvg className="w-full" />

        <h2>회원가입이 완료되었습니다!</h2>
        <h5>이제 건강 예방 습관을 만들 준비가 되었어요.</h5>
      </div>

      <BottomFixedButton
        text="건강한 예방 습관 만들러 가기"
        onClick={() => {
          dnaTestState.set({ isFirstTime: true, isTested: false });
          replace('/dna-test/1');
        }}
      />
    </>
  );
};
