import { stringify } from 'qs';
import { toast } from 'react-toastify';
import { adminApi } from '../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from './../../plugins/moment';
import { PATHS } from './paths';
import { GetUserSurveyAnswersDto, UserSurveyAnswersResponse } from './type';

export const getUserSurveyAnswers = async (
  getUserSurveyAnswersDto: GetUserSurveyAnswersDto
) => {
  const queryString = stringify(getUserSurveyAnswersDto, {
    addQueryPrefix: true,
    encode: false,
  });
  const { data } = await adminApi.get<UserSurveyAnswersResponse>(
    `/${PATHS.ADMIN}${queryString}`
  );
  return data.users;
};

export const getUserSurveyAnswersXlsx = () => {
  return adminApi
    .get(PATHS.XLSX, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then((res) => {
      const url = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${utcToLocalFormat(
        new Date().toUTCString(),
        MomentFormat.YYYYMMDDHHmmss
      )}_답변목록`;
      link.click();
      URL.revokeObjectURL(url);
    })
    .catch((e) => {
      console.log('e :', e);
      toast.error('엑셀 내보내기에 실패했습니다.');
    });
};
