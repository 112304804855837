import { Gender, HealthCare } from './api/auth/enum';
import { Comment } from './api/comments/type';
import { MissionStatus } from './api/mission-groups/enum';
import { GroupMissionUser } from './api/mission-groups/type';
import { UserDailyMissionStatus } from './api/user-daily-missions/enum';

export interface Paginated<T> {
  items: T[];
  pagination: {
    totalItemCount: number;
    currentItemCount: number;
    totalPage: number;
    currentPage: number;
    itemsPerPage: number;
  };
}

export interface User {
  id: number;
  email: string;
  role: Role;
  name: string;
  avatar: string;
  adminMemo?: string;
  categoryId?: number;
  gender: Gender;
  age: number;
  height: number;
  weight: number;
  phoneNumber: string;
  testGenome?: string;
  testResultImage?: string;
  testType?: string;
  isTested?: boolean;
  healthcare: HealthCare[];
  sensitiveAgreement?: boolean;
  marketingAgreement?: boolean;
  createdAt: string;
}

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface DnaTest {
  isTested: boolean;
  isFirstTime: boolean;
}

export interface CurrentMission {
  missionId: number;
  groupId: number;
  userMissionId: number;
  dailyMissionId: number;
}

export interface MissionCard {
  groupId: number;
  countUser: number;
  users: GroupMissionUser[];
  missionId: number;
  title: string;
  category: string;
  userMissionId: number;
  userMissionStatus: MissionStatus;
  dDay: number;
  startAt: string;
  endAt: string;
  userDailyMissionId: number;
  status: UserDailyMissionStatus;
  isTested: boolean;
  testImageUrl: string;
  categoryId: number;
}

export interface CommunityCard {
  id: number;
  name: string;
  avatar: string;
  comments: Comment[];
  status: UserDailyMissionStatus;
  endAt: string;
}
