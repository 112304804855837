import React from 'react';
import { map } from 'lodash';
import { useParams } from 'react-router-dom';
import { PointType } from '../../../api/points/enum';
import { Card } from '../../../components/Card';
import { Table } from '../../../components/Table';
import { POINT_DESC } from '../../../constants';
import { useAdminUserPoints } from '../../../hooks/users';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';

export const UserPoint = () => {
  const { id } = useParams<{ id: string }>();
  const { data: userPoints } = useAdminUserPoints({ id });

  const getPointName = (type: PointType) => {
    return POINT_DESC[type];
  };

  if (!userPoints) return null;
  const { points } = userPoints;

  return (
    <div className="space-y-3 p-6">
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>NO.</Table.Th>
              <Table.Th>힐링 스코어 적립내역</Table.Th>
              <Table.Th>적립 포인트</Table.Th>
              <Table.Th>누적 힐링 스코어</Table.Th>
              <Table.Th>적립일</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(points, (point, index) => (
              <Table.Row key={point.id}>
                <Table.Td>{index + 1}</Table.Td>
                <Table.Td>{getPointName(point.type)}</Table.Td>
                <Table.Td>{point.amount}</Table.Td>
                <Table.Td>{point.totalAmount}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(point.createdAt, MomentFormat.YYYYMMDD)}
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </div>
  );
};
