import axios from 'axios';
import { stringify } from 'qs';
import { api } from '../../plugins/axios';
import { PATHS } from './paths';
import {
  UploadFileDto,
  CreatePresignedUrlDto,
  PresignedUrlResponse,
  CreateAndUploadFileDto,
} from './type';

export async function createPresignedUrl(
  createPresignedUrlDto: CreatePresignedUrlDto
) {
  const queryString = stringify(createPresignedUrlDto, {
    addQueryPrefix: true,
    encode: false,
  });

  const { data } = await api.get<PresignedUrlResponse>(
    `/${PATHS.FILE_UPLOAD}${queryString}`
  );
  return data;
}

export function uploadFile(uploadFileDto: UploadFileDto) {
  const {
    file,
    presignedUrlResponse: { uploadUrl, fields },
  } = uploadFileDto;
  const formData = new FormData();
  Object.entries(fields).forEach(([name, value]) =>
    formData.append(name, value as any)
  );
  formData.append('file', file);
  return axios.post(uploadUrl, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function createFileAndUpload(
  createAndUploadFileDto: CreateAndUploadFileDto
) {
  const { file, fileName, fileType } = createAndUploadFileDto;
  const { uploadUrl, fields } = await createPresignedUrl({
    fileName,
    fileType,
  });
  const uploadFileDto = {
    file,
    presignedUrlResponse: { uploadUrl, fields },
  };
  await uploadFile(uploadFileDto);
  return `${uploadUrl}/${fields.key}`;
}
