import React, { FC } from 'react';
import { User } from '../../types';
import { Avatar } from '../Avatar';
import { Icon } from '../Icon';

interface UserCardProps {
  isMe: boolean;
  name: string;
  avatar: string;
  succeeded: boolean;
  isEnd: boolean;
  onClick: () => void;
}

export const UserCard: FC<UserCardProps> = ({
  isMe,
  name,
  avatar,
  succeeded,
  isEnd,
  onClick,
}) => {
  return (
    <>
      <div className="flex items-center justify-between" onClick={onClick}>
        <div className="flex items-center space-x-4">
          <Avatar src={avatar} className=" wh-14" />

          <div className="">
            <h4>{isMe ? `${name}(나)` : `${name}`}</h4>

            <span className="text-13 text-gray-500">
              {isEnd
                ? '미션진행 기간이 완료되었습니다.'
                : succeeded
                ? '오늘 미션을 성공했어요!'
                : '오늘 미션을 아직 진행하지 않았어요!'}
            </span>
          </div>
        </div>

        <Icon.ChevronRight />
      </div>
    </>
  );
};
