import React, { FC, useEffect, useState } from 'react';
import { isEmpty, map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { MissionStatus } from '../../../api/mission-groups/enum';
import { EmptyMissionCard } from '../../../components/EmptyMissionCard';
import { Icon } from '../../../components/Icon';
import { NAV_BAR } from '../../../constants';
import {
  getMissionGroupCard,
  useMissionGroups,
} from '../../../hooks/mission-groups';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { currentMissionState, navBarState } from '../../../plugins/ridge';
import { MissionCard } from '../../../types';

export const CompletePage = () => {
  const { push } = useHistory();
  const [currentMission, setCurrentMission] = currentMissionState.use();
  const [missionDoneCard, setMissionDoneCard] = useState<MissionCard[]>([]);
  const [missionSuccessCard, setMissionSuccessCard] = useState<MissionCard[]>(
    []
  );
  const [isDoneLoading, setIsDoneLoading] = useState(true);
  const [isSuccessLoading, setIsSuccessLoading] = useState(true);
  const { data: missionDataDone } = useMissionGroups({
    missionStatus: MissionStatus.DONE,
  });
  const { data: missionDataSuccess } = useMissionGroups({
    missionStatus: MissionStatus.SUCCESS,
  });

  useEffect(() => {
    const missionGroups = missionDataDone?.missionGroups;
    if (!missionGroups) return;
    getMissionGroupCard(missionGroups).then((res) => {
      setMissionDoneCard(res as MissionCard[]);
      setIsDoneLoading(false);
    });
  }, [missionDataDone]);

  useEffect(() => {
    const missionGroups = missionDataSuccess?.missionGroups;
    if (!missionGroups) return;
    getMissionGroupCard(missionGroups).then((res) => {
      setMissionSuccessCard(res as MissionCard[]);
      setIsSuccessLoading(false);
    });
  }, [missionDataSuccess]);

  const goToDetail = (mission: MissionCard) => {
    const { groupId, missionId, userMissionId, userDailyMissionId } = mission;
    const missionState = {
      groupId,
      missionId,
      userMissionId,
      dailyMissionId: userDailyMissionId,
    };
    setCurrentMission(missionState);

    push({
      pathname: `/mission/${mission.userMissionId}`,
    });
  };

  if (isDoneLoading || isSuccessLoading) return <></>;

  const missionCard = [...missionDoneCard, ...missionSuccessCard];
  return (
    <>
      <div className="space-y-5">
        {isEmpty(missionCard) ? (
          <EmptyMissionCard status={MissionStatus.DONE} />
        ) : (
          <>
            {map(missionCard, (mission) => (
              <CompleteCard
                key={mission.groupId}
                data={mission}
                goToDetail={goToDetail}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

interface CompleteCardProps {
  data: MissionCard;
  goToDetail: (mission: MissionCard) => void;
}

export const CompleteCard: FC<CompleteCardProps> = ({ data, goToDetail }) => {
  const { title, startAt, endAt, userMissionStatus, category } = data;
  return (
    <div
      className="space-y-2 rounded-lg border border-gray-100 bg-gray-50 p-5"
      onClick={() => {
        goToDetail(data);
        navBarState.set(NAV_BAR.MANAGE);
      }}
    >
      <h6 className="text-13 text-brand-1">
        {`[${category}] ${
          userMissionStatus === MissionStatus.SUCCESS
            ? '습관만들기 성공! 🎉'
            : '미션종료'
        }`}
      </h6>

      <div className="flex items-center justify-between">
        <h4 className="font-bold">{title}</h4>

        <Icon.ChevronRight />
      </div>

      <p className="space-x-2 text-13">
        <span className="text-gray-500">진행기간</span>
        <span>
          {utcToLocalFormat(startAt, MomentFormat.YYYYMMDD)}~
          {utcToLocalFormat(endAt, MomentFormat.YYYYMMDD)}
        </span>
      </p>
    </div>
  );
};
