export enum UserDailyMissionStatus {
  // 미션 성공
  SUCCESS = 'SUCCESS',
  // 미션 실패
  FAILED = 'FAILED',
  //미션 당일
  TODAY = 'TODAY',
  //미션 진행 예정
  SCHEDULED = 'SCHEDULED',
}
