import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createComment, deleteComment, getComment } from '../../api/comments';
import { PATHS } from '../../api/comments/paths';
import { CreateCommentDto } from '../../api/comments/type';
import { GetCommentDto } from './../../api/comments/type';

export const useCreateComments = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createCommentDto: CreateCommentDto) => createComment(createCommentDto),
    {
      onSuccess: () => {
        onSuccess();
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('댓글 생성에 실패했습니다.');
      },
    }
  );
};

export const useDeleteComment = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteComment(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(PATHS.ROOT);
    },
    onError: () => {
      toast.error('댓글 삭제에 실패했습니다.');
    },
  });
};

export const useGetComments = (getCommentDto: GetCommentDto) => {
  return useQuery([PATHS.ROOT, getCommentDto.receiverId], () =>
    getComment(getCommentDto)
  );
};
