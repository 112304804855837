import { stringify } from 'qs';
import { adminApi, api } from '../../plugins/axios';
import { User } from '../../types';
import { PaginationDto } from '../types';
import { PATHS } from './paths';
import {
  EmailExistDto,
  GetUserDto,
  GetAdminUserMissions,
  GetAdminUserPoints,
  TestResultDto,
  UpdateOneSignalDto,
} from './type';

interface isEmailExistRes {
  result: boolean;
}

export async function isEmailExist(emailExistDto: EmailExistDto) {
  const { data } = await api.post<isEmailExistRes>(PATHS.EXIST, emailExistDto);
  return data;
}

export function patchTestResult(testResultDto: TestResultDto) {
  return api.patch(PATHS.TEST_RESULT, testResultDto);
}

export function agreeSensitivePolicy() {
  return api.patch(PATHS.SENSITIVE_AGREE, { agreement: true });
}

export function updateUser(updateUserDto: Partial<User>) {
  return api.patch(PATHS.ME, updateUserDto);
}

export async function getUser(getUserDto: GetUserDto) {
  const { data } = await api.get<User>(PATHS.getUserById(getUserDto.id));
  return data;
}

export function deleteUser() {
  return api.delete(PATHS.ME);
}

export function getAdminUsers(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}

export function getAdminUser(getUserDto: GetUserDto) {
  const queryString = stringify(getUserDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi
    .get<User>(`/${PATHS.getUserById(getUserDto.id)}${queryString}`)
    .then(({ data }) => data);
}

export function updateAdminUser(updateUserDto: Partial<User>) {
  const id = updateUserDto.id!.toString();
  return adminApi.patch(PATHS.getUserById(id), updateUserDto);
}

export function getAdminUserMissions(getUserDto: GetUserDto) {
  return adminApi
    .get<GetAdminUserMissions>(PATHS.getUserMissionsById(getUserDto.id))
    .then(({ data }) => data);
}

export function getAdminUserPoints(getUserDto: GetUserDto) {
  return adminApi
    .get<GetAdminUserPoints>(PATHS.getUserPointsById(getUserDto.id))
    .then(({ data }) => data);
}

export function updateOneSignal(updateOneSignalDto: UpdateOneSignalDto) {
  return api.patch(PATHS.ONE_SIGNAL, updateOneSignalDto);
}
