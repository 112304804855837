import { stringify } from 'qs';
import { api } from '../../plugins/axios';
import { PATHS } from './paths';
import {
  GetUserDailyMissionDto,
  UserDailyMissionResponse,
  TodayDailyMissionDto,
  TodayDailyMissionResponse,
  UpdateDailyMissionDto,
} from './type';

export async function getUserDailyMissions(
  getUserDailyMissionDto: GetUserDailyMissionDto
) {
  const queryString = stringify(getUserDailyMissionDto, {
    addQueryPrefix: true,
    encode: false,
  });

  const { data } = await api.get<UserDailyMissionResponse>(
    `/${PATHS.ROOT}${queryString}`
  );
  return data.userDailyMissions;
}

export async function getTodayDailyMission(
  todayDailyMissionDto: TodayDailyMissionDto
) {
  const queryString = stringify(todayDailyMissionDto, {
    addQueryPrefix: true,
    encode: false,
  });

  const { data } = await api.get<TodayDailyMissionResponse>(
    `/${PATHS.TODAY}${queryString}`
  );
  return data;
}

export function updateDailyMission(
  updateDailyMissionDto: UpdateDailyMissionDto
) {
  const { id, body } = updateDailyMissionDto;
  return api.patch(PATHS.updateDailyById(id), body);
}
