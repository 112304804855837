import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { createContent } from '../../../api/contents';
import { PATHS } from '../../../api/contents/paths';
import { CreateContentDto } from '../../../api/contents/type';
import { FileType } from '../../../api/files/enum';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { Toggle } from '../../../components/Toggle';
import { useImageUpload } from '../../../hooks/files';

interface FormValues {
  title: string;
  description: string;
  link: string;
  order: number;
}

export const ContentsAdd = () => {
  const { goBack, push } = useHistory();
  const [imgPath, setImaPath] = useState<string>('');
  const [isMain, setIsMain] = useState(true);
  const [hasDetail, setHasDetail] = useState(true);

  const { mutate: createContentMutate } = useCreateContent(() =>
    push('/admin/contents/list')
  );

  const { isUploading, handleImageChange } = useImageUpload();

  const formSchema = Yup.object().shape({
    title: Yup.string().required('제목을 입력해주세요.'),
    description: Yup.string(),
    link: Yup.string(),
    order: Yup.number()
      .typeError('숫자를 입력해주세요')
      .required('값을 입력해주세요.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  });

  const _handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newImage = e.target.files?.item(0);
    if (!newImage) return;
    const url = await handleImageChange(newImage, FileType.THUMBNAIL);
    if (!url) return;
    setImaPath(url);
  };

  const handleSave = (data: FormValues) => {
    const dto = {
      ...data,
      thumbnail: imgPath,
      isMain,
      hasDetail,
    };
    createContentMutate(dto);
  };

  return (
    <form
      className="space-y-5"
      onSubmit={handleSubmit((data) => handleSave(data))}
    >
      <Icon.ArrowLeft onClick={() => goBack()} className="cursor-pointer" />
      <TextField
        label="노출 우선순위"
        placeholder="노출 우선순위를 정해주세요 (숫자가 낮을수록 상단에 노출)"
        helper={errors.order?.message}
        {...register('order')}
      />
      <div>
        <label htmlFor="imgPath">
          <div className="mb-2 text-sm">이미지</div>

          {isEmpty(imgPath) ? (
            <div className="grid aspect-[3/1] place-items-center rounded border text-gray-500">
              <div className="flex flex-col items-center space-y-2 text-center text-xs">
                <Icon.Image />
                <p>
                  파일 또는 이미지를 업로드 해주세요 (필수)
                  <br />
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="relative aspect-[3/1] border">
                <img
                  className="absolute h-full w-full object-cover"
                  src={imgPath}
                  alt=""
                />
              </div>
              <div className="mt-5">
                <label
                  htmlFor="imgPath"
                  className="filled-black cursor-pointer px-6 py-2 font-normal"
                >
                  이미지 수정하기
                </label>
              </div>
            </>
          )}
        </label>
        <input
          type="file"
          id="imgPath"
          className="hidden"
          accept="image/*"
          onChange={_handleImageChange}
        />
      </div>
      <div className="flex items-center space-x-3">
        <div>관련 링크</div>
        <Toggle
          checked={!hasDetail}
          onChange={(value) => setHasDetail(!value)}
        />
      </div>
      <TextField
        disabled={hasDetail}
        placeholder="관련 링크를 입력해주세요."
        helper={errors.link?.message}
        {...register('link')}
      />
      <TextField
        label="제목"
        placeholder="제목을 입력해주세요."
        helper={errors.title?.message}
        {...register('title')}
      />
      <TextArea
        disabled={!hasDetail}
        label="설명"
        className="h-44"
        placeholder="설명을 입력해주세요."
        helper={errors.description?.message}
        {...register('description')}
      />
      <div className="flex items-center space-x-3">
        <div>페이지 노출</div>
        <Toggle checked={isMain} onChange={(value) => setIsMain(value)} />
      </div>
      <div className="flex justify-end space-x-4 py-4">
        <Button
          disabled={isUploading || isEmpty(imgPath)}
          text="저장하기"
          className="filled-gray-900 h-10 text-sm"
        />
      </div>
    </form>
  );
};

const useCreateContent = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createContentDto: CreateContentDto) => createContent(createContentDto),
    {
      onSuccess: () => {
        toast.success('컨텐츠가 생성되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
        onSuccess();
      },
      onError: () => {
        toast.error('컨텐츠 생성에 실패했습니다.');
      },
    }
  );
};
