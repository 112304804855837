import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { findEmail, kakaoLogin, signUp } from '../../api/auth';
import { tokenState } from '../../plugins/ridge';
import { FindEmailDto, SignUpDto } from './../../api/auth/type';
import {
  removeItemInLocalStorage,
  LOCAL_STORAGE_KEY,
} from './../../plugins/local-storage';

export const useFindEmail = (onSuccess?: (result: string) => void) => {
  return useMutation((findEmailDto: FindEmailDto) => findEmail(findEmailDto), {
    onSuccess: (res: { email: string }) => {
      onSuccess && onSuccess(res.email);
    },
    onError: (e) => {
      console.log(e);
    },
  });
};

export const useSignUp = (onSuccess?: () => void) => {
  return useMutation((signUpDto: SignUpDto) => signUp(signUpDto), {
    onSuccess: (res: { token: string }) => {
      tokenState.set(res.token);
      removeItemInLocalStorage(LOCAL_STORAGE_KEY.CREATE_USER_INFO);
      onSuccess && onSuccess();
    },
    onError: () => {
      toast.error('회원가입에 실패했습니다.');
    },
  });
};
