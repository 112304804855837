import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { sendPhoneValidation, validateCode } from '../../api/phone-validations';
import {
  SendPhoneValidationDto,
  ValidateCodeDto,
} from './../../api/phone-validations/type';

export const useSendPhoneValidation = (onSuccess: () => void) => {
  return useMutation(
    (sendPhoneValidationDto: SendPhoneValidationDto) =>
      sendPhoneValidation(sendPhoneValidationDto),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (e: AxiosError) => {
        if (e.response?.data?.message) {
          toast.error(e.response?.data?.message);
        } else {
          toast.error('인증번호 전송에 실패했습니다.');
        }
      },
    }
  );
};

export const useValidateCode = (onSuccess: () => void, onError: () => void) => {
  return useMutation(
    (validateCodeDto: ValidateCodeDto) => validateCode(validateCodeDto),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (e) => {
        console.log('Error in useValidateCode: ', e);
        onError();
      },
    }
  );
};
