import React, { FC } from 'react';
import { Comment } from '../../api/comments/type';
import { meState } from '../../plugins/ridge';
import { Avatar } from '../Avatar';
import { useModal } from '../modal/Modal';

interface CommentCardProps {
  comment: Comment;
  onDelete?: (id: number) => void;
}

export const CommentCard: FC<CommentCardProps> = ({ comment, onDelete }) => {
  const {
    id,
    body,
    writerId,
    writer: { avatar, name },
  } = comment;
  const { deleteCommentConfirmation } = useModal();
  const me = meState.useValue();

  if (me === 0 || me === -1) return null;

  const isWriter = writerId === me?.id;

  const _onClick = () => {
    if (onDelete) {
      deleteCommentConfirmation(() => onDelete(id));
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-3 text-13">
        <Avatar className="wh-8" src={avatar} />
        <p className="">{name}</p>

        {isWriter && (
          <button onClick={_onClick} className="absolute right-4 text-red-500">
            삭제
          </button>
        )}
      </div>

      <p className="ml-10 mt-2 whitespace-pre-wrap bg-gray-50 p-2 text-13">
        {body}
      </p>
    </div>
  );
};
