import React, { FC, ImgHTMLAttributes } from 'react';
import { isEmpty } from 'lodash';
import { ReactComponent as AvatarIcon } from '../assets/icons/icon-avatar.svg';

export interface AvatarProps extends ImgHTMLAttributes<HTMLImageElement> {}

export const Avatar: FC<AvatarProps> = ({
  className = '',
  src,
  alt = 'avatar',
  ...props
}) => {
  return (
    <div className={`relative w-max ${className}`}>
      {!isEmpty(src) ? (
        <div
          className={`${
            className ? className : 'wh-16'
          }  relative overflow-hidden rounded-full`}
        >
          <img
            src={src}
            alt={alt}
            {...props}
            className="absolute h-full w-full object-cover"
          />
        </div>
      ) : (
        <AvatarIcon className={`${className ? className : 'wh-16'} `} />
      )}
    </div>
  );
};
