import { AxiosError } from 'axios';
import { map } from 'lodash';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import {
  getTodayDailyMission,
  updateDailyMission,
} from '../../api/user-daily-missions';
import { PATHS } from './../../api/user-daily-missions/paths';
import {
  TodayDailyMissionDto,
  TodayDailyMissionResponse,
  UpdateDailyMissionDto,
} from './../../api/user-daily-missions/type';

export const useDailyMissionsFromGroups = (
  missionGroupIds: number[],
  userId: number
) => {
  return useQueries(
    map(missionGroupIds, (missionGroupId) => ({
      queryKey: `daily-${userId}-mission-${missionGroupId}`,
      queryFn: () => getTodayDailyMission({ missionGroupId, userId }),
    }))
  );
};

export const useDailyMission = (todayDailyMissionDto: TodayDailyMissionDto) => {
  return useQuery<TodayDailyMissionResponse, AxiosError>(
    [PATHS.TODAY, todayDailyMissionDto.userId],
    () => getTodayDailyMission(todayDailyMissionDto)
  );
};

export const useUpdateDailyMission = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    (updateDailyMissionDto: UpdateDailyMissionDto) =>
      updateDailyMission(updateDailyMissionDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PATHS.TODAY]);
        onSuccess && onSuccess();
      },
    }
  );
};
