import React, { Dispatch, FC, HTMLAttributes, SetStateAction } from 'react';
import { Pagination } from 'swiper';
import { Swiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import './swiper.css';

type SplashSwiper = {
  onReachEnd: () => void;
};

export type SwiperCardProps = HTMLAttributes<HTMLDivElement> & SplashSwiper;

export const SwiperCard: FC<SwiperCardProps> = ({
  onReachEnd,
  children,
  className = '',
}) => {
  return (
    <Swiper
      pagination={true}
      modules={[Pagination]}
      className={`mySwiper w-full ${className} `}
      onReachEnd={onReachEnd}
    >
      {children}
    </Swiper>
  );
};
