import { adminApi, api } from '../../plugins/axios';
import { PATHS } from './paths';
import { CreateMissionDto, GetMissionResponse, UpdateMissionDto } from './type';

export function createMission(createMissionDto: CreateMissionDto) {
  return adminApi.post(PATHS.ROOT, createMissionDto);
}

export function updateMission(updateMissionDto: UpdateMissionDto) {
  return adminApi.patch(
    PATHS.getMissionById(updateMissionDto.id),
    updateMissionDto
  );
}

export async function getMission(id: number) {
  const { data } = await api.get<GetMissionResponse>(PATHS.getMissionById(id));
  return data;
}
