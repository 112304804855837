import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { getSurveyQuestions } from '../../api/survey-questions';
import { getAdminSurveys } from '../../api/surveys';
import { PATHS } from '../../api/surveys/paths';
import { Survey } from '../../api/surveys/type';
import { PaginationDto } from '../../api/types';
import { Paginated } from '../../types';

export const useAdminSurveys = (paginationDto: PaginationDto) => {
  return useQuery<Paginated<Survey>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAdminSurveys(paginationDto),
    { keepPreviousData: true }
  );
};
