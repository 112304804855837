import React, { FC } from 'react';
import { every, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Comment } from '../../api/comments/type';
import { User } from '../../types';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { useModal } from '../modal/Modal';
import { UserCard } from './UserCard';

interface UserCardProps {
  succeeded: boolean;
  isEnd: boolean;
  onSuccess: () => void;
  user: User;
  comments: Comment[];
  onClick: () => void;
}

export const MyMission: FC<UserCardProps> = ({
  succeeded,
  isEnd,
  onSuccess,
  user,
  comments,
  onClick,
}) => {
  const { completeTodaysMission } = useModal();
  const { name, avatar } = user;

  return (
    <>
      <UserCard
        isMe={true}
        name={name}
        avatar={avatar}
        succeeded={succeeded}
        isEnd={isEnd}
        onClick={onClick}
      />

      {!isEmpty(comments) && (
        <div className="mt-4 flex w-max items-center space-x-1 rounded-md bg-gray-50 p-2 text-12 font-medium">
          <Icon.Comment className="w-4" />
          <span>{comments.length}개의 응원댓글</span>
        </div>
      )}

      {every([!succeeded, !isEnd]) && (
        <div className="mt-6 flex w-full items-center space-x-4">
          <span
            className={`${
              succeeded ? 'text-gray-300' : 'text-brand-1'
            } text-14 `}
          >
            미션을 성공하셨나요?
          </span>
          <Button
            onClick={() => completeTodaysMission(onSuccess)}
            disabled={succeeded}
            text="오늘 결과 기록하기"
            className="filled-brand-1 flex-1"
          />
        </div>
      )}
    </>
  );
};
