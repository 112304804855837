import { GroupMissionUser } from './api/mission-groups/type';
import { meState } from './plugins/ridge';

export function range(length: number, start = 0) {
  return Array.from({ length }, (_, i) => i + start);
}

export const filterMe = (users: GroupMissionUser[]) => {
  const me = meState.get();
  if (me === 0 || me === -1) return [];
  return users.filter((user) => user.id !== me.id);
};
