import React, { FC } from 'react';
import { UserDailyMissionStatus } from '../api/user-daily-missions/enum';
import { useUpdateDailyMission } from '../hooks/user-daily-missions';
import { MissionCard as MissionData } from '../types';
import { filterMe } from '../utils';
import { Button } from './Button';
import { Icon } from './Icon';
import { MissionUserInfo } from './MissionUserInfo';
import { useModal } from './modal/Modal';

interface MissionCardProps {
  mission: MissionData;
  onClick: () => void;
  update: () => void;
}

export const MissionCard: FC<MissionCardProps> = ({
  mission,
  onClick,
  update,
}) => {
  const {
    status,
    dDay,
    title,
    userDailyMissionId,
    userMissionId,
    countUser,
    users,
    category,
  } = mission;
  const { completeTodaysMission } = useModal();
  const succeeded = status === UserDailyMissionStatus.SUCCESS;
  const { mutate: updateDailySuccess } = useUpdateDailyMission(() => update());

  const onClickSuccess = () => {
    updateDailySuccess({
      id: userDailyMissionId,
      body: {
        userMissionId,
        status: UserDailyMissionStatus.SUCCESS,
      },
    });
  };

  return (
    <div
      className={`mb-5 rounded-lg p-5 ${
        succeeded ? 'border border-brand-1 bg-[#EDF2FF]' : 'bg-gray-50'
      } `}
      onClick={onClick}
    >
      <div className="flex justify-between text-13 text-brand-1">
        <h6 className="pb-2 text-13 font-bold text-brand-1">
          {dDay === 0 ? <>D-Day</> : <>D-{dDay}</>}
        </h6>
        <span>진행률 보기</span>
      </div>
      <span className="text-14 font-bold">{`[${category}] `}</span>
      <span className="text-14">예방미션</span>
      <div className="mb-4 flex items-center justify-between">
        <div className="space-x-2">
          {succeeded && <Icon.CheckCircle className="aspect-[1/1] min-w-5" />}
          <h4>{title}</h4>
        </div>
      </div>

      {!succeeded && (
        <>
          {countUser > 1 ? (
            <MissionUserInfo users={filterMe(users)} />
          ) : (
            <>
              <p className="text-14 text-gray-600">
                미션을 공유하여 함께 할 사람들을 초대해보세요!
              </p>
            </>
          )}

          <div className="mt-6 flex w-full items-center space-x-4">
            <span className="text-14 text-brand-1">미션을 성공하셨나요?</span>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                completeTodaysMission(onClickSuccess);
              }}
              text="오늘 결과 기록하기"
              className="filled-brand-1 flex-1"
            />
          </div>
        </>
      )}
    </div>
  );
};
