import React, { FC } from 'react';
import { Icon } from '../Icon';

interface CreateCommentButtonProps {
  onClick: () => void;
}

export const CreateCommentButton: FC<CreateCommentButtonProps> = ({
  onClick,
}) => {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className="flex h-14 w-full items-center justify-center space-x-2 bg-gray-50 text-13 font-semibold"
    >
      <Icon.Comment />
      <span>응원의 댓글 남기기</span>
    </button>
  );
};
