import React, { useEffect, useState } from 'react';
import { parse } from 'qs';
import { useLocation } from 'react-router-dom';
import { Tab } from '../../components/Tab';
import { CompletePage } from './complete/CompletePage';
import { InvitationsPage } from './invitations/InvitationsPage';

const INVITATION_TAB = 0;
const COMPLETED_TAB = 1;

export const ManagePage = () => {
  const { search } = useLocation();
  const { tab } = parse(search, { ignoreQueryPrefix: true });
  const [selectedTab, setSelectedTab] = useState(INVITATION_TAB);

  useEffect(() => {
    tab && setSelectedTab(COMPLETED_TAB);
  }, [tab]);

  return (
    <div>
      <div className="-mx-5 my-5 text-center">미션 관리</div>

      <div className="-mx-5 mb-5 grid grid-cols-2 border-t border-b border-gray-100">
        <Tab
          text="초대장"
          onClick={() => {
            setSelectedTab(INVITATION_TAB);
          }}
          selected={selectedTab === INVITATION_TAB}
        />
        <Tab
          text="완료"
          onClick={() => {
            setSelectedTab(COMPLETED_TAB);
          }}
          selected={selectedTab === COMPLETED_TAB}
        />
      </div>
      {selectedTab === INVITATION_TAB && <InvitationsPage />}
      {selectedTab === COMPLETED_TAB && <CompletePage />}
    </div>
  );
};
