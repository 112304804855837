import React, { useEffect, useState } from 'react';
import { filter, get, isEmpty, map } from 'lodash';
import { useHistory } from 'react-router-dom';
import { BottomFixedButton } from '../../components/BottomFixedButton';
import { ChoiceButton } from '../../components/ChoiceButton';
import { Label } from '../../components/Label';
import { SelectMenu, SelectMenuOption } from '../../components/SelectMenu';
import { TopNavBar } from '../../components/TopNavBar';
import { CATEGORY_TYPES, DNA_TEST } from '../../constants';
import { useCategory } from '../../hooks/categories';
import { useTestResults } from '../../hooks/test-results';
import {
  getItemInLocalStorage,
  LOCAL_STORAGE_KEY,
  setItemInLocalStorage,
} from '../../plugins/local-storage';
import { dnaTestState } from '../../plugins/ridge';

const MAIN_INSTRUCTION = [
  '가족이 병력을 가지고 있는 암 종류를 1가지 선택하세요. ',
  '이제 유전자 검사 결과지를 준비해주세요!',
];

const SUB_INSTRUCTION = [
  '없는 경우, 한국인이 잘 걸리는 *10대 암 중 1가지를 선택하세요. 한국인의 10대 암: 갑상선암, 폐암, 위암, 대장암, 유방암, 전립선암, 간암, 췌장암, 담낭담도암, 신장암 (국가암정보센터 2019 년 통계 기준)',
  '관리 또는 주의가 필요하다고 결과가 나온 항목을 1가지만 선택하세요.',
];

const SELECT_LABEL = [
  '내가 선택한 건강 예방 습관 항목',
  '내가 선택한 유전자검사 종류',
];

const NONE = -1;

export const StepThreePage = () => {
  const { push } = useHistory();
  const [selectedTypeId, setSelectedTypeId] = useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState(NONE);
  const { isTested: hasDnaTest } = dnaTestState.get();
  const categoryTypes = hasDnaTest
    ? CATEGORY_TYPES[DNA_TEST.Y]
    : CATEGORY_TYPES[DNA_TEST.N];
  const { data: categoriesData } = useCategory({
    categoryType: categoryTypes[selectedTypeId].type,
  });
  const { data: testResult } = useTestResults(selectedCategoryId);

  useEffect(() => {
    const selectedCategoryType = getItemInLocalStorage(
      LOCAL_STORAGE_KEY.CATEGORY_TYPE
    );
    const selectedCategory = getItemInLocalStorage(LOCAL_STORAGE_KEY.CATEGORY);
    const categoryTypeId = get(selectedCategoryType, 'categoryTypeId', 0);
    const categoryId = get(selectedCategory, 'id', NONE);
    setSelectedTypeId(categoryTypeId);
    setSelectedCategoryId(categoryId);
  }, []);

  const typesToOption = (item: { name: string; id: number; type: string }) => {
    return { label: item.name, value: item.id };
  };

  const handleChangeType = (option: SelectMenuOption) => {
    if (selectedTypeId !== option.value) {
      setSelectedTypeId(option.value);
      setSelectedCategoryId(NONE);
    }
  };

  const setCategory = () => {
    const categoryTypeId = { categoryTypeId: selectedTypeId };
    const category = filter(
      categoriesData,
      (category) => category.id === selectedCategoryId
    )[0];
    setItemInLocalStorage(LOCAL_STORAGE_KEY.CATEGORY_TYPE, categoryTypeId);
    setItemInLocalStorage(LOCAL_STORAGE_KEY.CATEGORY, category);
  };

  const handleNext = () => {
    setCategory();
    if (hasDnaTest && isEmpty(testResult?.testImageUrl)) {
      push('/dna-test/4');
    } else {
      push('/dna-test/5');
    }
  };

  return (
    <>
      <TopNavBar />

      {selectedTypeId !== 0 && !hasDnaTest ? (
        <h2 className="whitespace-pre-wrap pt-12 pb-8 ">
          {'관심있는 분야의 예방습관 항목을 1가지 선택하세요.'}
        </h2>
      ) : (
        <>
          <h2 className="whitespace-pre-wrap pt-12 pb-8 ">
            {hasDnaTest
              ? MAIN_INSTRUCTION[DNA_TEST.Y]
              : MAIN_INSTRUCTION[DNA_TEST.N]}
          </h2>

          <h5 className="text-gray-600">
            {hasDnaTest
              ? SUB_INSTRUCTION[DNA_TEST.Y]
              : SUB_INSTRUCTION[DNA_TEST.N]}
          </h5>
        </>
      )}

      <div className="space-y-2 pt-8">
        <Label className="font-semibold">
          {hasDnaTest ? SELECT_LABEL[DNA_TEST.Y] : SELECT_LABEL[DNA_TEST.N]}
        </Label>
        <SelectMenu
          options={map(categoryTypes, typesToOption)}
          selectedId={selectedTypeId}
          onChange={handleChangeType}
        />
      </div>

      <div className="mt-5 grid grid-cols-2 gap-4 pb-28">
        {map(categoriesData, (category) => (
          <ChoiceButton
            key={category.id}
            onClick={() => setSelectedCategoryId(category.id)}
            selected={category.id === selectedCategoryId}
            text={category.name}
          />
        ))}
      </div>

      <BottomFixedButton
        disabled={selectedCategoryId === NONE}
        text="다음"
        onClick={handleNext}
      />
    </>
  );
};
