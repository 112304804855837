import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { every, isEmpty, some } from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { VerifyType } from '../../api/phone-validations/enum';
import { CreateUserDto } from '../../api/users/type';
import { Agreement } from '../../components/Agreement';
import { BottomFixedButton } from '../../components/BottomFixedButton';
import { Checkbox } from '../../components/Checkbox';
import { PhoneNumber } from '../../components/PhoneNumber';
import { TextField } from '../../components/TextField';
import { TopNavBar } from '../../components/TopNavBar';
import { AGREEMENT_LINK } from '../../constants';
import { useEmailExist } from '../../hooks/users';
import {
  LOCAL_STORAGE_KEY,
  setItemInLocalStorage,
} from '../../plugins/local-storage';

const CHECKBOX_LABELS = [
  '[필수] 서비스 이용약관에 동의',
  '[필수] 개인정보 수집 / 이용 동의',
  '[선택] 민감정보 수집 / 이용 동의',
  '[선택] 마케팅 / 프로모션 정보 제공 동의',
];

interface FormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  phoneNumber: string;
}

export const SignupPage = () => {
  const { push } = useHistory();
  const [emailHelper, setEmailHelper] = useState('');

  const formSchema = Yup.object({
    email: Yup.string()
      .required('이메일을 입력해주세요')
      .email('이메일 형식이 아닙니다.'),
    password: Yup.string()
      .required('영문, 숫자포함 8자리를 입력해주세요.')
      .min(8, '최소 8자 이상 가능합니다')
      .max(15, '최대 15자 까지만 가능합니다')
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"])[0-9a-zA-Z\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]{8,15}$/,
        '영문 숫자포함 8자리를 입력해주세요.'
      ),
    passwordConfirm: Yup.string().oneOf(
      [Yup.ref('password')],
      '비밀번호가 다릅니다.'
    ),
    phoneNumber: Yup.string()
      .required('휴대폰 번호를 입력해주세요.')
      .matches(/^\d{11}$/, '휴대폰 번호 형식이 아닙니다.'),
  });

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [policyState, setPolicyState] = useState({
    isTermsOfServiceChecked: false,
    isPersonalInformationChecked: false,
    isSensitiveChecked: false,
    isMarketingChecked: false,
  });
  const {
    isTermsOfServiceChecked,
    isPersonalInformationChecked,
    isSensitiveChecked,
    isMarketingChecked,
  } = policyState;

  useEffect(() => {
    setIsAllChecked(every(Object.values(policyState)));
  }, [
    isTermsOfServiceChecked,
    isPersonalInformationChecked,
    isMarketingChecked,
  ]);

  const handleExistUser = () => {
    setEmailHelper('이미 가입된 이메일입니다.');
  };

  const handleNewUser = () => {
    const userInfo: CreateUserDto = {
      email: getValues('email'),
      password: getValues('password'),
      phoneNumber: getValues('phoneNumber'),
      marketingAgreement: isMarketingChecked,
      sensitiveAgreement: isSensitiveChecked,
    };
    setItemInLocalStorage(LOCAL_STORAGE_KEY.CREATE_USER_INFO, userInfo);
    push('/signup/my-info');
  };

  const { mutate: isEmailExistMutate } = useEmailExist(
    handleExistUser,
    handleNewUser
  );

  const _onChangePolicy = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setPolicyState({
      ...policyState,
      [name]: checked,
    });
  };

  const _onChangeAllPolicy = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllChecked(e.target.checked);
    if (e.target.checked) {
      _onAllPolicy();
    } else {
      _offAllPolicy();
    }
  };

  const _onAllPolicy = () => {
    setPolicyState({
      isTermsOfServiceChecked: true,
      isPersonalInformationChecked: true,
      isSensitiveChecked: true,
      isMarketingChecked: true,
    });
  };

  const _offAllPolicy = () => {
    setPolicyState({
      isTermsOfServiceChecked: false,
      isPersonalInformationChecked: false,
      isSensitiveChecked: false,
      isMarketingChecked: false,
    });
  };

  const passwordHelper =
    !errors.password?.message && getValues('password')?.length >= 8
      ? '사용 가능한 비밀번호입니다.'
      : errors.password?.message;

  const passwordConfirmHelper =
    !errors.passwordConfirm?.message &&
    getValues('passwordConfirm')?.length >= 8
      ? '비밀번호가 일치합니다.'
      : errors.passwordConfirm?.message;

  return (
    <>
      <TopNavBar />

      <h2 className="mt-10 mb-3 leading-normal">가입할 정보를 알려주세요!</h2>
      <p className="text-15 text-gray-500">
        아이힐미에 가입할 정보를 입력해주세요.
      </p>

      <form
        className="mt-6 flex flex-col space-y-2 pb-32"
        onSubmit={handleSubmit((data) => {
          isEmailExistMutate({ email: data.email });
        })}
      >
        <TextField
          type="email"
          label="이메일"
          placeholder="이메일을 입력해주세요."
          helper={errors.email?.message || emailHelper}
          {...register('email')}
        />

        <TextField
          type="password"
          label="비밀번호"
          placeholder="영문 숫자 포함 8자리를 입력해주세요."
          helper={passwordHelper}
          isError={errors.password?.message ? true : false}
          {...register('password')}
        />

        <TextField
          type="password"
          label="비밀번호 재입력"
          placeholder="비밀번호를 다시 입력해주세요."
          helper={passwordConfirmHelper}
          isError={errors.passwordConfirm?.message ? true : false}
          {...register('passwordConfirm')}
        />

        <PhoneNumber
          {...register('phoneNumber')}
          value={getValues('phoneNumber')}
          verifyType={VerifyType.SIGNUP}
          isVerified={isPhoneVerified}
          setIsVerified={setIsPhoneVerified}
          helper={errors.phoneNumber?.message}
        />

        <div className="space-y-4 pt-6">
          <Checkbox
            label="전체 동의하기"
            labelClassName="font-bold text-16"
            checked={isAllChecked}
            name="isAllChecked"
            onChange={_onChangeAllPolicy}
          />
          <Agreement
            checked={isTermsOfServiceChecked}
            name="isTermsOfServiceChecked"
            onChange={_onChangePolicy}
            label={CHECKBOX_LABELS[0]}
            link={AGREEMENT_LINK[0]}
          />
          <Agreement
            checked={isPersonalInformationChecked}
            name="isPersonalInformationChecked"
            onChange={_onChangePolicy}
            label={CHECKBOX_LABELS[1]}
            link={AGREEMENT_LINK[1]}
          />
          <Agreement
            checked={isSensitiveChecked}
            name="isSensitiveChecked"
            onChange={_onChangePolicy}
            label={CHECKBOX_LABELS[2]}
            link={AGREEMENT_LINK[2]}
          />
          <Agreement
            checked={isMarketingChecked}
            name="isMarketingChecked"
            onChange={_onChangePolicy}
            label={CHECKBOX_LABELS[3]}
          />
        </div>
        <BottomFixedButton
          disabled={some([
            !isTermsOfServiceChecked,
            !isPersonalInformationChecked,
            !isPhoneVerified,
            !isEmpty(errors),
          ])}
          text="다음"
        />
      </form>
    </>
  );
};
