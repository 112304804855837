import { toast } from 'react-toastify';

interface ICopyToClipboard {
  value: string;
}

export const copyToClipboard = async ({ value }: ICopyToClipboard) => {
  try {
    if (!navigator.clipboard) {
      throw new Error("Browser don't have support for native clipboard.");
    }
    await navigator.clipboard.writeText(value);
    toast.success('링크가 복사되었습니다.');
  } catch (error) {
    console.log(error);
  }
};
