import React from 'react';
import { every, filter, map } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { MissionStatus } from '../../../../api/mission-groups/enum';
import { UserDailyMissionStatus } from '../../../../api/user-daily-missions/enum';
import { BottomFixedButton } from '../../../../components/BottomFixedButton';
import { Button } from '../../../../components/Button';
import { DateStamp } from '../../../../components/DateStamp';
import { Progress } from '../../../../components/Progress';
import { useModal } from '../../../../components/modal/Modal';
import { useReview } from '../../../../hooks/reviews';
import {
  useGetUserMission,
  useUpdateUserMission,
} from '../../../../hooks/user-missions';
import {
  MomentFormat,
  utcToDayDiff,
  utcToIsAfter,
  utcToLocalFormat,
} from '../../../../plugins/moment';

export const ProgressPage = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { completeAllMission } = useModal();
  const { data: userMission } = useGetUserMission(Number(id));
  const { data: missionReview } = useReview(Number(id));
  const { mutate: updateMissionMutate } = useUpdateUserMission(() =>
    completeMission()
  );
  const completeMission = () => {
    if (progress > 24) {
      completeAllMission(id);
    } else {
      goReview();
    }
  };

  const onClickComplete = () => {
    updateMissionMutate(Number(id));
  };

  const goReview = () => {
    push(`/mission/${id}/review`);
  };

  if (!userMission) return <></>;
  const { userDailyMissions: dailyResults } = userMission;
  const lastDailyMission = dailyResults[dailyResults.length - 1];
  const lastDate = lastDailyMission.createdAt;

  const progress = filter(dailyResults, {
    status: UserDailyMissionStatus.SUCCESS,
  }).length;

  const isInProgress = userMission.status === MissionStatus.INPROGRESS;
  const completeLastDay =
    utcToDayDiff(lastDate) === 0 &&
    lastDailyMission.status === UserDailyMissionStatus.SUCCESS;
  const afterLastDay = utcToIsAfter(lastDate);
  const canComplete = isInProgress && (completeLastDay || afterLastDay);

  return (
    <>
      <div className="-mb-1 mt-4 flex justify-between text-13">
        <span className="text-brand-1">{`${progress}회 완료`}</span>
        <span className="text-gray-500">총 30회</span>
      </div>

      <Progress max={30} value={progress} />
      <div className="mx-auto mt-2 grid grid-cols-7 space-y-2 pb-28">
        {map(dailyResults, (daily, index) => (
          <DateStamp
            key={daily.id}
            index={+index}
            date={utcToLocalFormat(daily.createdAt, MomentFormat.MMDD)}
            status={daily.status}
          />
        ))}
      </div>

      {isInProgress && (
        <div className="fixed bottom-4 -mx-5 w-full bg-white px-5">
          <div className="mb-1 text-center text-14 font-medium">{`${utcToLocalFormat(
            lastDate,
            MomentFormat.YYYYMMDD
          )} 에 버튼이 활성화됩니다.`}</div>
          <Button
            disabled={!canComplete}
            text={
              progress < 25 && canComplete ? '미션 완료하기' : '습관 완성하기'
            }
            className="filled-brand-1 w-full"
            onClick={onClickComplete}
          />
        </div>
      )}

      {every([!isInProgress, !missionReview]) && (
        <BottomFixedButton
          text={progress < 25 ? '후기 작성하기' : '후기 남기고 +100P 받기'}
          className="filled-brand-1 w-full"
          onClick={goReview}
        />
        // <Button
        //   text={progress < 25 ? '후기 작성하기' : '후기 남기고 +100P 받기'}
        //   className="filled-brand-1 w-full"
        //   onClick={goReview}
        // />
      )}
    </>
  );
};
