import React, { useState } from 'react';
import { filter } from 'lodash';
import { useQuery } from 'react-query';
import { getAdminCategories } from '../../../api/categories';
import { PATHS } from '../../../api/categories/paths';
import { DNA_TEST_TYPES } from '../../../constants';
import {
  useCreateCategory,
  useUpdateCategory,
} from '../../../hooks/categories';
import { AdminH1 } from '../../components/AdminH1';
import { CategoryMission } from './CategoryMission';
import { DnaCategory } from './DnaCategory';
import { SubCategory } from './SubCategory';

export const MissionList = () => {
  const [selectedTypeId, setSelectedTypeId] = useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const { data: categories } = useQuery(PATHS.ROOT, () => getAdminCategories());
  const categoryData = filter(categories?.items, {
    categoryType: DNA_TEST_TYPES[selectedTypeId].type,
  });

  const selectedCategory = filter(categoryData, { id: selectedCategoryId })[0];

  const { mutate: createCategoryMutate } = useCreateCategory();
  const { mutate: updateCategoryMutate } = useUpdateCategory();

  const mutateCategory = (name: string, isOpen: boolean, id?: number) => {
    const dto = {
      categoryType: DNA_TEST_TYPES[selectedTypeId].type,
      name: name,
      isOpen: isOpen,
    };
    if (!id) {
      createCategoryMutate(dto);
    } else {
      const updateDto = { id, body: dto };
      updateCategoryMutate(updateDto);
    }
  };

  const _setSelectedTypeId = (id: number) => {
    setSelectedCategoryId(0);
    setSelectedTypeId(id);
  };

  return (
    <div className="space-y-6">
      <AdminH1>카테고리 및 미션 관리</AdminH1>

      <div className="flex space-x-12">
        <DnaCategory
          selectedTypeId={selectedTypeId}
          setSelectedTypeId={_setSelectedTypeId}
        />
        <SubCategory
          categories={categoryData}
          mutateCategory={mutateCategory}
          selectedCategoryId={selectedCategoryId}
          setSelectedCategoryId={setSelectedCategoryId}
        />
        {selectedCategoryId > 0 && (
          <CategoryMission
            selectedCategoryId={selectedCategoryId}
            missions={selectedCategory?.missions}
          />
        )}
      </div>
    </div>
  );
};
