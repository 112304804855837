import React, { useEffect, useState } from 'react';
import { filter, isEmpty, map } from 'lodash';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CategoryType } from '../../../api/categories/enum';
import { Category } from '../../../api/categories/type';
import { TestResult } from '../../../api/test-results/type';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Section } from '../../../components/Section';
import { Table } from '../../../components/Table';
import { TextField } from '../../../components/TextField';
import { DNA_TEST_TYPES } from '../../../constants';
import { useAdminUserCategory } from '../../../hooks/categories';
import { useUpdateTestResults } from '../../../hooks/test-results';

interface FormValues {
  testType: string;
  testGenome: string;
}

export const UserTest = () => {
  const { id } = useParams<{ id: string }>();
  const [categoryTypes, setCategoryTypes] = useState<CategoryType[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedTypeIdx, setSelectedTypeIdx] = useState(-1);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [testResult, setTestResult] = useState<TestResult | null>(null);
  const { mutate: updateTestResultsMutate } = useUpdateTestResults(() =>
    onUpdateSuccess()
  );
  const { data: userCategoryData } = useAdminUserCategory({
    userId: Number(id),
  });
  const userCategories = userCategoryData?.categories;
  const { handleSubmit, register, reset } = useForm<FormValues>();

  const _handleSubmit = (data: FormValues) => {
    if (!testResult?.id) return;
    if (!window.confirm('저장하시겠습니까?')) return;

    const { testType, testGenome } = data;
    const body = {
      testType,
      testGenome,
      id: testResult.id,
    };
    updateTestResultsMutate(body);
  };

  const onUpdateSuccess = () => {
    toast.success('정보가 수정되었습니다.');
  };

  const resetData = () => {
    setSelectedCategoryId(0);
    setTestResult(null);
  };

  const _setSelectedTypeIdx = (id: number) => {
    resetData();
    setSelectedTypeIdx(id);
  };

  useEffect(() => {
    if (!userCategories) return;
    const types = map(userCategories, (category) => category.categoryType);
    const uniqueTypes = filter(types, (type, i) => types.indexOf(type) === i);
    setCategoryTypes(uniqueTypes);
  }, [userCategories]);

  useEffect(() => {
    if (selectedTypeIdx === -1 || !userCategories) return;
    const currentCategories = userCategories.filter(
      (category) => category.categoryType === categoryTypes[selectedTypeIdx]
    );
    setCategories(currentCategories);
  }, [selectedTypeIdx]);

  useEffect(() => {
    if (!userCategories || selectedCategoryId === 0) return;
    const testResults = userCategories.filter(
      (data) => data.id === selectedCategoryId
    )[0].testResults;
    const result = testResults[0];
    setTestResult(testResults[0]);
    reset({
      testGenome: result?.testGenome ?? '',
      testType: result?.testType ?? '',
    });
  }, [selectedCategoryId]);

  return (
    <div>
      {isEmpty(userCategories) ? (
        <div>아직 진행한 미션이 없습니다.</div>
      ) : (
        <>
          <div className="mt-10 flex space-x-6">
            <Section>
              <Card>
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.Th>유형</Table.Th>
                      <Table.Th></Table.Th>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {map(categoryTypes, (data, idx) => (
                      <Table.Row key={idx}>
                        <Table.Td
                          className={
                            selectedTypeIdx === idx ? 'text-black' : ''
                          }
                          onClick={() => _setSelectedTypeIdx(idx)}
                        >
                          {
                            DNA_TEST_TYPES.filter(
                              (item) => item.type === data
                            )[0].name
                          }
                        </Table.Td>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Card>
            </Section>
            <Section>
              <Card>
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.Th>미션 진행 항목</Table.Th>
                      <Table.Th></Table.Th>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {map(categories, (data) => (
                      <Table.Row key={data.id}>
                        <Table.Td
                          className={
                            selectedCategoryId === data.id ? 'text-black' : ''
                          }
                          onClick={() => setSelectedCategoryId(data.id)}
                        >
                          {data.name}
                        </Table.Td>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Card>
            </Section>
            {testResult !== null && (
              <Section className="flex flex-1 flex-col">
                <form
                  className="space-y-4"
                  onSubmit={handleSubmit((data) => _handleSubmit(data))}
                >
                  <TextField
                    label="유전자 검사 유무"
                    disabled
                    value={testResult?.isTested ? '결과 있음' : '결과 없음'}
                  />
                  <TextField
                    label="검사항목 입력"
                    placeholder="유저의 검사항목을 입력해주세요"
                    {...register('testType')}
                  />
                  <TextField
                    label="변이 유전자 입력"
                    placeholder="유저의 변이유전자를 입력해주세요"
                    {...register('testGenome')}
                  />
                  <div className="relative inline-block h-96 w-full" key="">
                    <p>검사결과지</p>
                    <div className="flex h-5/6 justify-center rounded-md border">
                      {!isEmpty(testResult?.testImageUrl) && (
                        <img
                          className="h-full object-contain"
                          src={testResult?.testImageUrl}
                          alt="test result"
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <Button
                      text="저장하기"
                      disabled={isEmpty(testResult)}
                      className="filled-gray-900 h-10 text-sm"
                      type="submit"
                    />
                  </div>
                </form>
              </Section>
            )}
          </div>
        </>
      )}
    </div>
  );
};
