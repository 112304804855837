import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { isIOS } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { ReactComponent as HeroImg } from '../../assets/svg/login-illustration.svg';
import { ReactComponent as Logo } from '../../assets/svg/login-top.svg';
import { Button } from '../../components/Button';
import { Icon } from '../../components/Icon';
import { Action, Bridge } from '../../plugins/bridge';
import {
  getItemInLocalStorage,
  LOCAL_STORAGE_KEY,
} from '../../plugins/local-storage';
import { meState } from '../../plugins/ridge';
import { Role } from '../../types';

export const LoginPage = () => {
  const { push, replace } = useHistory();
  const me = meState.useValue();
  const [isLoading, setIsLoading] = useState(true);

  const newUserToOnboarding = () => {
    const onboarding = getItemInLocalStorage(LOCAL_STORAGE_KEY.ONBORDING);
    if (get(onboarding, 'end', 0) === 0) {
      replace('/onboarding');
    }
  };

  useEffect(() => {
    newUserToOnboarding();

    if (me === -1) {
      return;
    }

    if (me === 0) {
      setIsLoading(false);
      return;
    }

    Bridge.postMessage(Action.SEND_ONE_SIGNAL);
    Bridge.postMessage(Action.GET_VERSION);

    const { role } = me;
    if (role === Role.ADMIN) {
      replace('/admin');
    } else {
      replace('/mission');
    }
  }, [me]);

  if (isLoading) return <></>;

  return (
    <div className="-mx-5 -mb-8 flex h-screen flex-col items-center bg-brand-2 px-5 pt-12">
      <Logo />
      <HeroImg />

      <div className="absolute bottom-2 w-full space-y-4 px-5">
        <Button
          className="flex w-full items-center justify-center space-x-3 bg-[#FFE812]"
          onClick={() => Bridge.postMessage(Action.KAKAO_LOGIN, '')}
        >
          <Icon.Kakao />
          <h5 className="">카카오톡 로그인</h5>
        </Button>
        {isIOS && (
          <Button
            className="flex w-full items-center justify-center space-x-3 bg-gray-900"
            onClick={() => Bridge.postMessage(Action.APPLE_LOGIN, '')}
          >
            <Icon.Apple />
            <h5 className="text-white">Apple로 로그인</h5>
          </Button>
        )}

        <button
          onClick={() => push('/login/email')}
          className="w-full py-4 text-center"
        >
          이메일로 시작하기
        </button>
      </div>
    </div>
  );
};
