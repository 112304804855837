import { useEffect } from 'react';
import { isEmpty, some, every } from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { TextField } from '../../../components/TextField';
import { useAuth } from '../../../hooks';
import { meState } from '../../../plugins/ridge';

interface FormValues {
  email: string;
  password: string;
}

export const LoginEmailPage = () => {
  const { push, replace } = useHistory();
  const { login, apiErrorMsg } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const me = meState.useValue();

  useEffect(() => {
    if (me === 0 || me === -1) {
      return;
    }
    replace('/mission');
  }, [me]);

  return (
    <>
      <h5 className="-mx-5 items-center border-b py-4 text-center">로그인</h5>

      <Icon.Logo className=" my-8" />

      <form
        className="flex min-h-screen-10 flex-col space-y-5"
        onSubmit={handleSubmit((data) => login(data))}
      >
        <TextField
          label="이메일"
          placeholder="가입한 이메일을 입력해주세요."
          helper={errors.email?.message || apiErrorMsg?.email}
          {...register('email', {
            pattern: {
              value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/,
              message: '올바른 이메일 주소가 아닙니다.',
            },
          })}
        />
        <TextField
          label="비밀번호"
          placeholder="영문 숫자 포함 8자리를 입력해주세요."
          type="password"
          helper={apiErrorMsg?.password}
          {...register('password')}
        />

        <div className="flex flex-row-reverse text-13 text-gray-700">
          <button type="button" onClick={() => push('/find/password')}>
            비밀번호 찾기
          </button>
          <span className="mx-2">|</span>
          <button type="button" onClick={() => push('/find/email')}>
            이메일 찾기
          </button>
        </div>

        <div className="flex-1" />

        <div className="flex flex-col space-y-5">
          <Button
            text="로그인"
            className="filled-brand-1 "
            type="submit"
            disabled={some([
              !isEmpty(errors),
              !every(Object.values(watch(['email', 'password']))),
            ])}
          />
          <Button
            type="button"
            to="/signup"
            text="회원가입"
            className="text-brand-1"
          />
        </div>
      </form>
    </>
  );
};
