import React, { FC } from 'react';

interface TabProps {
  selected: boolean;
  onClick: () => void;
  text: string;
}

export const Tab: FC<TabProps> = ({ selected, onClick, text }) => {
  return (
    <div
      onClick={onClick}
      className={`${
        selected ? 'bg-[#EDF2FF] font-bold text-brand-1' : ''
      } py-5 text-center text-14`}
    >
      {text}
    </div>
  );
};
