import { adminApi } from '../../plugins/axios';
import { PATHS } from './paths';
import {
  CreateSurveyQuestionDto,
  SurveyQuestionResponse,
  UpdateSurveyQuestionDto,
} from './type';

export const getSurveyQuestions = async (id: number) => {
  const { data } = await adminApi.get<SurveyQuestionResponse>(
    PATHS.getOneBySurveyId(id)
  );
  return data.surveyQuestions;
};

export const createSurveyQuestion = (
  createSurveyQuestionDto: CreateSurveyQuestionDto
) => {
  return adminApi.post(PATHS.ROOT, createSurveyQuestionDto);
};

export const updateSurveyQuestion = (
  updateSurveyQuestionDto: UpdateSurveyQuestionDto
) => {
  return adminApi.patch(
    PATHS.getOneById(updateSurveyQuestionDto.id),
    updateSurveyQuestionDto
  );
};
