import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BottomFixedButton } from '../../components/BottomFixedButton';
import { ChoiceButton } from '../../components/ChoiceButton';
import { TopNavBar } from '../../components/TopNavBar';
import { dnaTestState } from '../../plugins/ridge';

export const StepOnePage = () => {
  const [hasDnaTest, setHasDnaTest] = useState<boolean>();
  const { isFirstTime } = dnaTestState.useValue();
  const { push, replace } = useHistory();

  const handleNext = () => {
    if (hasDnaTest === undefined) {
      return;
    }
    dnaTestState.set({ isFirstTime, isTested: hasDnaTest });
    push('/dna-test/2');
  };

  return (
    <>
      <TopNavBar backButton goBackTo={() => replace('/')} />
      <h2 className="pt-20">
        암(예측) 유전자 검사 또는
        <br /> DTC 유전자 검사를 받은 적이
        <br /> 있으신가요?
      </h2>

      <form className="mt-8 grid grid-cols-2 gap-4">
        <ChoiceButton
          onClick={() => setHasDnaTest(true)}
          selected={hasDnaTest === true}
          text={'예'}
        />
        <ChoiceButton
          onClick={() => setHasDnaTest(false)}
          selected={hasDnaTest === false}
          text={'아니요'}
        />
      </form>

      <span className="mt-16 mb-12 text-13 leading-normal text-gray-500">
        <h5 className="text-gray-700">암(예측) 유전자 검사</h5>
        개인의 선천적 유전자 변이를 분석하여 특정 암에 대한 발병 가능성을
        예측하는 검사로 의료기관에서 건강 검진 시 또는 별개로 받을 수 있으며,
        암을 진단하는 목적의 유전자 검사와는 다름.
        <br />
        <br />
        <h5 className="text-gray-700">DTC 유전자 검사</h5>
        개인의 선천적 유전자 변이를 분석하여 영양소 결핍, 피부-모발, 식습관,
        개인특성 및 건강관리에 관한 정보를 주는 유전자 검사로서 보건복지부
        고시(제2020-35호 「의료기관이 아닌 유전자검사 기관이 직접 실시할 수 있는
        유전자검사 항목에 관한 규정」)에 따라 개인이 의료기관을 거치지 않고
        인터넷 등을 통해서 유전자검사기관에 직접 의뢰할 수 있는 검사를 의미함.
      </span>

      <BottomFixedButton
        text="다음"
        disabled={hasDnaTest === undefined}
        onClick={handleNext}
      />
    </>
  );
};
