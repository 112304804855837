import { api } from '../../plugins/axios';
import { PATHS } from './paths';
import { SendPhoneValidationDto, ValidateCodeDto } from './type';

export function sendPhoneValidation(
  sendPhoneValidationDto: SendPhoneValidationDto
) {
  return api.post(PATHS.FRONT, sendPhoneValidationDto);
}

export function validateCode(validateCodeDto: ValidateCodeDto) {
  return api.post(PATHS.VERIFY, validateCodeDto);
}
