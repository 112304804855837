import React, { Dispatch, FC, SetStateAction } from 'react';
import { Card } from '../../../components/Card';
import { Table } from '../../../components/Table';
import { DNA_TEST_TYPES } from '../../../constants';

interface DnaCategoryProps {
  selectedTypeId: number;
  setSelectedTypeId: (id: number) => void;
}

export const DnaCategory: FC<DnaCategoryProps> = ({
  selectedTypeId,
  setSelectedTypeId,
}) => {
  const _setSelectedTypeId = (id: number) => {
    setSelectedTypeId(id);
  };

  return (
    <div className="space-y-3">
      <div className="pb-6 pt-4">유전자 검사 종류</div>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>유형</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {DNA_TEST_TYPES.map((data) => (
              <Table.Row key={`type-${data.id}`}>
                <Table.Td
                  className={selectedTypeId === data.id ? 'text-black' : ''}
                  onClick={() => _setSelectedTypeId(data.id)}
                >
                  {data.name}
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </div>
  );
};
