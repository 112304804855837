import { useState } from 'react';
import { createFileAndUpload } from '../../api/files';
import { FileType } from './../../api/files/enum';

export const useImageUpload = () => {
  const [isUploading, setIsUploading] = useState(false);

  const handleImageChange = async (file: File, fileType: FileType) => {
    if (!file) {
      return;
    }
    setIsUploading(true);

    const fileName = file.name;
    const createFileAndUploadDto = {
      fileType,
      fileName,
      file,
    };
    try {
      const result = await createFileAndUpload(createFileAndUploadDto);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  };
  return { handleImageChange, isUploading };
};
