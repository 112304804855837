import React from 'react';
import { map } from 'lodash';
import { useQuery } from 'react-query';
import { CommentsResponse } from '../../api/comments/type';
import { Avatar } from '../../components/Avatar';
import { TopNavBar } from '../../components/TopNavBar';
import { CommentCard } from '../../components/mission-community/CommentCard';
import { fetcher } from '../../plugins/react-query';
import { User } from '../../types';

export const MyCommentsPage = () => {
  const { data: me } = useQuery<User>('users/me', fetcher);
  const { data: commentData } = useQuery<CommentsResponse>(
    `/comments?receiverId=${me?.id}`,
    fetcher,
    { enabled: !!me?.id }
  );

  if (!commentData) return <></>;
  const { comments } = commentData;

  return (
    <>
      <TopNavBar />

      <div className="flex items-center space-x-3 pt-8">
        <Avatar src={me?.avatar} className="wh-16" />
        <h4>나를 위한 응원댓글이에요!</h4>
      </div>

      <div className="-mx-5 my-5 h-4 bg-gray-50" />

      <div className="space-y-4">
        {map(comments, (comment) => (
          <CommentCard key={comment.id} comment={comment} />
        ))}
      </div>
    </>
  );
};
