import { map } from 'lodash';
import { useMutation, useQueries } from 'react-query';
import { toast } from 'react-toastify';
import { createMission, getMission } from '../../api/missions';
import { CreateMissionDto, UpdateMissionDto } from '../../api/missions/type';

export const useCreateMission = (onSuccess?: () => void) => {
  return useMutation(
    (createMissionDto: CreateMissionDto) => createMission(createMissionDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
      },
      onError: (e) => {
        console.log('Error in useCreateMission: ', e);
        toast.error('미션 생성에 실패했습니다.');
      },
    }
  );
};

export const useUpdateMission = (onSuccess?: () => void) => {
  return useMutation(
    (updateMissionDto: UpdateMissionDto) => createMission(updateMissionDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
      },
      onError: (e) => {
        toast.error('미션 수정에 실패했습니다.');
      },
    }
  );
};

export const useMissionsFromGroups = (missionIds: number[]) => {
  return useQueries(
    map(missionIds, (missionId) => ({
      queryKey: `mission-${missionId}`,
      queryFn: () => getMission(missionId),
    }))
  );
};
