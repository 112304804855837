import React, { useEffect, useState } from 'react';
import { filter, map } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createSurveyAnswer,
  updateSurveyAnswer,
} from '../../../api/survey-question-answers';
import {
  CreateSurveyAnswerDto,
  SurveyAnswer,
  UpdateSurveyAnswerDto,
} from '../../../api/survey-question-answers/type';
import {
  createSurveyQuestion,
  updateSurveyQuestion,
} from '../../../api/survey-questions';
import { PATHS } from '../../../api/survey-questions/paths';
import {
  CreateSurveyQuestionDto,
  SurveyQuestion as Question,
  UpdateSurveyQuestionDto,
} from '../../../api/survey-questions/type';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Table } from '../../../components/Table';
import { useAdminQuestions } from '../../../hooks/survey-questions';
import { AdminH1 } from '../../components/AdminH1';
import { SurveyAnswerModal } from '../../components/SurveyAnswerModal';
import { SurveyQuestionModal } from '../../components/SurveyQuestionModal';

const EMPTY_QUESTION = {
  id: 0,
  question: '',
  isDuplicated: false,
  isOpen: true,
};

const EMPTY_ANSWER = {
  id: 0,
  body: '',
  isOpen: true,
};

export const SurveyQuestion = () => {
  const { id } = useParams<{ id: string }>();
  const [questionId, setQuestionId] = useState<number>(0);
  const [isQModalOpen, setIsQModalOpen] = useState<boolean>(false);
  const [isAModalOpen, setIsAModalOpen] = useState<boolean>(false);
  const [QData, setQData] = useState(EMPTY_QUESTION);
  const [AData, setAData] = useState(EMPTY_ANSWER);
  const [ADatas, setADatas] = useState<SurveyAnswer[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);

  const { data: surveyQuestions } = useAdminQuestions(+id);
  const { mutate: createQuestionMutate } = useCreateQuestion();
  const { mutate: updateQuestionMutate } = useUpdateQuestion();
  const { mutate: createAnswerMutate } = useCreateAnswer();
  const { mutate: updateAnswerMutate } = useUpdateAnswer();

  const mutateQuestion = (
    question: string,
    isDuplicated: boolean,
    isOpen: boolean,
    questionId?: number
  ) => {
    const dto = {
      question,
      isDuplicated,
      isOpen,
      surveyId: +id,
    };
    if (!questionId) {
      createQuestionMutate(dto);
    } else {
      const updateDto = { ...dto, id: questionId };
      updateQuestionMutate(updateDto);
    }
  };

  const mutateAnswer = (body: string, isOpen: boolean, answerId?: number) => {
    const dto = {
      body,
      isOpen,
      surveyQuestionId: questionId,
    };
    if (!answerId) {
      createAnswerMutate(dto);
    } else {
      const updateDto = { ...dto, id: answerId };
      updateAnswerMutate(updateDto);
    }
  };

  useEffect(() => {
    if (!surveyQuestions) return;
    setQuestions(surveyQuestions);

    if (questionId === 0) return;
    const answerDatas = filter(surveyQuestions, { id: questionId })[0];
    if (answerDatas) {
      setADatas(answerDatas.surveyQuestionAnswers);
    }
  }, [surveyQuestions, questionId]);

  if (!surveyQuestions) return <></>;

  return (
    <>
      <SurveyQuestionModal
        open={isQModalOpen}
        onClose={() => setIsQModalOpen(false)}
        mutate={mutateQuestion}
        data={QData}
      />
      <SurveyAnswerModal
        open={isAModalOpen}
        onClose={() => setIsAModalOpen(false)}
        mutate={mutateAnswer}
        data={AData}
      />
      <div className="grid grid-cols-3">
        <div className="col-span-2 space-y-3 p-6">
          <div className="flex items-center justify-between space-x-5">
            <AdminH1>질문 리스트</AdminH1>
            <Button
              text="추가"
              className="filled-gray-100 h-10 text-sm text-black"
              onClick={() => {
                setQData(EMPTY_QUESTION);
                setIsQModalOpen(true);
              }}
            />
          </div>

          <Card>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>질문리스트</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {map(questions, (data) => (
                  <Table.Row key={data.id}>
                    <Table.Td
                      className={questionId === data.id ? 'text-black' : ''}
                      onClick={() => setQuestionId(data.id)}
                    >
                      {data.question}
                    </Table.Td>
                    <Table.Td className="text-right">
                      <button
                        className="font-normal text-indigo-600 hover:text-indigo-900"
                        onClick={() => {
                          setQData(data);
                          setIsQModalOpen(true);
                        }}
                      >
                        수정
                      </button>
                    </Table.Td>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card>
        </div>

        <div className="space-y-3 p-6">
          <div className="flex items-center justify-between space-x-5">
            <AdminH1>관련 답변</AdminH1>
            <Button
              text="추가"
              className="filled-gray-100 h-10 text-sm text-black"
              onClick={() => {
                if (questionId > 0) {
                  setAData(EMPTY_ANSWER);
                  setIsAModalOpen(true);
                } else {
                  toast.error('질문을 선택해주세요');
                }
              }}
            />
          </div>

          <Card>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>관련 항목</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {map(ADatas, (answer) => (
                  <Table.Row key={answer.id}>
                    <Table.Td>{answer.body}</Table.Td>
                    <Table.Td className="text-right">
                      <button
                        className="font-normal text-indigo-600 hover:text-indigo-900"
                        onClick={() => {
                          setAData(answer);
                          setIsAModalOpen(true);
                        }}
                      >
                        수정
                      </button>
                    </Table.Td>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Card>
        </div>
      </div>
    </>
  );
};

const useCreateQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (createSurveyQuestionDto: CreateSurveyQuestionDto) =>
      createSurveyQuestion(createSurveyQuestionDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.ROOT);
        toast.success('질문이 생성되었습니다.');
      },
      onError: () => {
        toast.error('질문 생성에 실패했습니다.');
      },
    }
  );
};

const useUpdateQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateSurveyQuestionDto: UpdateSurveyQuestionDto) =>
      updateSurveyQuestion(updateSurveyQuestionDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.ROOT);
        toast.success('질문이 수정되었습니다.');
      },
      onError: () => {
        toast.error('질문 수정에 실패했습니다.');
      },
    }
  );
};

const useCreateAnswer = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (createSurveyAnswerDto: CreateSurveyAnswerDto) =>
      createSurveyAnswer(createSurveyAnswerDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.ROOT);
        toast.success('답변이 생성되었습니다.');
      },
      onError: () => {
        toast.error('답변 생성에 실패했습니다.');
      },
    }
  );
};

const useUpdateAnswer = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateSurveyAnswerDto: UpdateSurveyAnswerDto) =>
      updateSurveyAnswer(updateSurveyAnswerDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.ROOT);
        toast.success('답변이 수정되었습니다.');
      },
      onError: () => {
        toast.error('답변 수정에 실패했습니다.');
      },
    }
  );
};
