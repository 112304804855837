import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GetMissionResponse } from '../../../api/missions/type';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Section } from '../../../components/Section';
import { Table } from '../../../components/Table';
import { missionlist } from '../../../dummies';

interface MissionProps {
  selectedCategoryId: number;
  missions: GetMissionResponse[];
}

export const CategoryMission: FC<MissionProps> = ({
  selectedCategoryId,
  missions,
}) => {
  const { push } = useHistory();

  const goMissionDetail = (mission?: GetMissionResponse) => {
    // const missionState = {categoryId: selectedCategoryId}
    if (mission) {
      const { id, title, description, genoType, isOpen } = mission;
      const missionState = {
        missionId: id,
        title,
        description,
        genoType,
        isOpen,
        categoryId: selectedCategoryId,
      };
      push(`/admin/mission/missioninfo`, missionState);
    } else {
      push(`/admin/mission/missioninfo`, { categoryId: selectedCategoryId });
    }
  };

  return (
    <Section className="space-y-3">
      <div className="flex items-center justify-between pb-2">
        관련 항목
        <Button
          text="추가"
          className="h-10 bg-gray-200 px-6"
          onClick={() => goMissionDetail()}
        />
      </div>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>미션리스트</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {missions.map((mission) => (
              <Table.Row key={mission.id}>
                <Table.Td>{mission.title}</Table.Td>
                <Table.Td>
                  <p
                    className="font-normal text-indigo-600"
                    onClick={() => {
                      goMissionDetail(mission);
                    }}
                  >
                    상세보기
                  </p>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </Section>
  );
};
