import React, { FC, useEffect, useState } from 'react';
import { isEmpty, map } from 'lodash';
import { MissionStatus } from '../../../api/mission-groups/enum';
import { Button } from '../../../components/Button';
import { EmptyMissionCard } from '../../../components/EmptyMissionCard';
import { useModal } from '../../../components/modal/Modal';
import {
  getMissionGroupCard,
  useConfirmInvitation,
  useMissionGroups,
} from '../../../hooks/mission-groups';
import { MissionCard } from '../../../types';

export const InvitationsPage = () => {
  const [missionCard, setMissionCard] = useState<MissionCard[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: missionData } = useMissionGroups({
    missionStatus: MissionStatus.INVITED,
  });
  const { inviteConfirm, inviteComplete } = useModal();
  const { mutate: confirmInvitationMutate } = useConfirmInvitation(() =>
    inviteComplete()
  );

  const onClickConfirm = (
    missionId: number,
    title: string,
    groupId: number
  ) => {
    const confirmInvitationDto = {
      missionId,
      missionGroupId: groupId,
    };
    inviteConfirm(title, () => confirmInvitationMutate(confirmInvitationDto));
  };

  useEffect(() => {
    const missionGroups = missionData?.missionGroups;
    if (!missionGroups) return;
    getMissionGroupCard(missionGroups).then((res) => {
      setMissionCard(res as MissionCard[]);
      setIsLoading(false);
    });
  }, [missionData]);

  if (isLoading) return <></>;

  return (
    <>
      <div className="space-y-5">
        {isEmpty(missionCard) ? (
          <EmptyMissionCard status={MissionStatus.INVITED} />
        ) : (
          <>
            {map(missionCard, (mission) => (
              <InvitationsCard
                key={mission.groupId}
                data={mission}
                onClickConfirm={() =>
                  onClickConfirm(
                    mission.missionId,
                    mission.title,
                    mission.groupId
                  )
                }
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

interface InvitationsCardProps {
  data: MissionCard;
  onClickConfirm: () => void;
}

export const InvitationsCard: FC<InvitationsCardProps> = ({
  data,
  onClickConfirm,
}) => {
  const { title, category } = data;
  return (
    <div className="space-y-2 rounded-lg border border-gray-100 bg-gray-50 p-5">
      <h6 className="text-13 text-brand-1">{`[${category} 습관]`}</h6>

      <h4 className="pb-6 font-bold">{title}</h4>

      <Button
        text={`함께하기`}
        className="w-full bg-blue-50 text-brand-1"
        onClick={onClickConfirm}
      />
    </div>
  );
};
