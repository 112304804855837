import React from 'react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { PATHS } from '../../../api/contents/paths';
import { ContentsDetailResponse } from '../../../api/contents/type';
import { TopNavBar } from '../../../components/TopNavBar';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { fetcher } from '../../../plugins/react-query';

export const ContentDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: content } = useContent(id);

  if (!content) return null;

  const { thumbnail, title, createdAt, description } = content;

  return (
    <>
      <TopNavBar />

      <div className="space-y-5">
        <div className="-mx-5 mt-5 aspect-[2/1]">
          <img src={thumbnail} alt="" className="h-full w-full object-cover" />
        </div>

        <h3>{title}</h3>

        <span className="text-13 text-gray-500">
          {utcToLocalFormat(createdAt.toLocaleString(), MomentFormat.LLdd)}
        </span>

        <p className="whitespace-pre-wrap break-words text-15 leading-normal text-gray-700">
          {description}
        </p>
      </div>
    </>
  );
};

const useContent = (id: string) => {
  return useQuery<ContentsDetailResponse, AxiosError>(
    PATHS.getOneById(+id),
    fetcher,
    {
      enabled: !!id,
    }
  );
};
