import { Gender, HealthCare } from '../api/auth/enum';
import { CategoryType } from '../api/categories/enum';
import { SurveyStatus } from '../api/surveys/enum';
import { PointType } from './../api/points/enum';
import { FirstAnswer, SecondAnswer, ThirdAnswer } from './../api/reviews/enum';
export const DNA_TEST = {
  N: 0,
  Y: 1,
};

export const DNA_TEST_TYPES = [
  { id: 0, type: CategoryType.CANCER, name: '암(예측) 유전자검사' },
  { id: 1, type: CategoryType.NUTRIENT, name: 'DTC 유전자검사 - 영양소 결핍' },
  { id: 2, type: CategoryType.HEALTH, name: 'DTC 유전자검사 - 건강관리' },
  { id: 3, type: CategoryType.SKINCARE, name: 'DTC 유전자검사 - 피부& 모발' },
];

export const NO_DNA_TEST_TYPES = [
  { id: 0, type: CategoryType.CANCER, name: '암 예방' },
  { id: 1, type: CategoryType.NUTRIENT, name: '영양 불균형 예방' },
  { id: 2, type: CategoryType.HEALTH, name: '성인병 예방' },
  { id: 3, type: CategoryType.SKINCARE, name: '피부 / 모발 문제 예방' },
];

export const CATEGORY_TYPES = {
  [DNA_TEST.N]: NO_DNA_TEST_TYPES,
  [DNA_TEST.Y]: DNA_TEST_TYPES,
};

export const POINT_DESC = {
  [PointType.COMMENT]: '응원의 댓글',
  [PointType.SIGNUP]: '회원가입 완료',
  [PointType.REVIEW]: '습관만들기 성공',
  [PointType.SURVEY]: '오늘의 설문',
  [PointType.UPLOAD]: '유전자검사 사진등록',
};

export const REVIEWS = [
  {
    id: 1,
    title: '한달 미션 진행은 어떠셨나요?',
    options: [
      { id: 1, answer: '재밌었어요.', value: FirstAnswer.FUN },
      { id: 2, answer: '그저 그랬어요.', value: FirstAnswer.IFFY },
      { id: 3, answer: '힘들었어요.', value: FirstAnswer.HARD },
    ],
  },
  {
    id: 2,
    title: '미션이 도움이 되었나요?',
    options: [
      { id: 4, answer: '네, 도움이 되었어요.', value: SecondAnswer.HELPED },
      { id: 5, answer: '잘 모르겠어요.', value: SecondAnswer.NOPE },
    ],
  },
  {
    id: 3,
    title: '어떻게 도움이 되었나요?',
    options: [
      {
        id: 6,
        answer: '몸 상태에 좋은 변화가 있었어요.',
        value: ThirdAnswer.GOODCHANGE,
      },
      { id: 7, answer: '아직은 잘 모르겠어요.', value: ThirdAnswer.NOTYET },
      {
        id: 8,
        answer:
          '실제로 몸 상태의 변화는 없었지만 \n 꾸준히 해야겠다는 의지가 생겼어요.',
        value: ThirdAnswer.WILLING,
      },
    ],
  },
];

export const SURVEY_STATUS = {
  [SurveyStatus.SCHEDULED]: '진행예정',
  [SurveyStatus.INPROGRESS]: '진행중',
  [SurveyStatus.END]: '종료',
};

export const ITEMS_PER_PAGE = 30;

export const AGREEMENT_LINK = [
  'https://cloudy-brow-3b8.notion.site/3aa30072c73847fc92097ba1e6384fba',
  'https://cloudy-brow-3b8.notion.site/55d821c612394d2d94db2b3c5d668e3d',
  'https://cloudy-brow-3b8.notion.site/45fb0e2b1d8d4b25842ab4acedae74d5',
  'https://cloudy-brow-3b8.notion.site/304244c9d22e4e93a5ca220dbab16ddb',
];

export const GENDERS = [
  { id: 0, text: '여자', data: Gender.FEMALE },
  { id: 1, text: '남자', data: Gender.MALE },
];

export const HEALTHCARES = [
  { id: 0, text: '운동', data: HealthCare.EXERCISE },
  { id: 1, text: '영양제 복용', data: HealthCare.NUTRIENTS },
  { id: 2, text: '식단관리', data: HealthCare.FOODMANAGEMENT },
  { id: 3, text: '없음', data: HealthCare.NONE },
];

export const NAV_BAR = {
  HOME: 'HOME',
  MANAGE: 'MANAGE',
};
