import { stringify } from 'qs';
import { adminApi } from '../../plugins/axios';
import { PaginationDto } from '../types';
import { PATHS } from './paths';
import { CreateContentDto, UpdateContentDto } from './type';

export function getAdminContents(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}

export function createContent(createContentDto: CreateContentDto) {
  return adminApi.post(PATHS.ROOT, createContentDto);
}

export function updateContent(updateContentDto: UpdateContentDto) {
  return adminApi.patch(
    PATHS.getOneById(updateContentDto.id),
    updateContentDto
  );
}
