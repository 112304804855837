import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { appleLogin, kakaoLogin } from '../api/auth';
import { updateOneSignal } from '../api/users';
import { LOCAL_STORAGE_KEY, setItemInLocalStorage } from './local-storage';
import {
  currentRefState,
  historyState,
  meState,
  osState,
  tokenState,
  versionState,
} from './ridge';

export enum Action {
  ERROR = 'error',
  LOG = 'log',
  KAKAO_LOGIN = 'kakaoLogin',
  APPLE_LOGIN = 'appleLogin',
  CHECK_CAMERA_PERMISSION = 'checkCameraPermission',
  OPEN_LINK = 'openLink',
  SHOW_CAMERA = 'showCamera',
  BUILD_DYNAMIC_LINK = 'buildDynamicLink',
  GET_DYNAMIC_LINK = 'getDynamicLink',
  OPEN_APP_SETTING = 'openAppSetting',
  SEND_ONE_SIGNAL = 'sendOneSignal',
  GET_VERSION = 'getVersion',
  SUCCESS_LINK = 'successLink',
}

interface Command {
  kind?: 'webview';
  action: Exclude<
    keyof typeof Bridge,
    'prototype' | 'os' | 'postMessage' | 'handleMessageEvent'
  >;
  value?: any;
}

interface KakaoResponse {
  profile: {
    email: string;
  };
  token: {
    idToken: string;
  };
}

interface AppleResponse {
  idToken: string;
  email: string;
}

interface PermissionResponse {
  permission: number;
}

function tryParseJSON(jsonString: any) {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
}

function moveTo(path: string) {
  const history = historyState.get();
  if (history && history?.push) {
    history.push(path);
    historyState.set(history);
  } else {
    window.location.href = path;
  }
}

export class Bridge {
  static os: string;

  static postMessage(action: Action, value?: any) {
    const data = JSON.stringify({ action, value });
    console.log('postMessage in web : ', data);
    (window as any).ReactNativeWebView?.postMessage(data);
  }

  static handleMessageEvent({ data }: MessageEvent) {
    console.log('handleMessage in Web : ', data);
    const command = tryParseJSON(data) as Command;
    if (command?.kind !== 'webview') return;
    if (Bridge[command.action]) {
      Bridge[command.action](command.value);
    } else {
      Bridge.postMessage(
        Action.ERROR,
        `(RN -> WebView) Invalid action: ${data}`
      );
    }
  }

  static init({ os }: any) {
    osState.set(os ?? 'webView');
  }

  static async kakaoLogin(res: KakaoResponse) {
    const {
      token: { idToken },
      profile: { email },
    } = res;

    kakaoLogin(idToken)
      .then((res: { token: string }) => tokenState.set(res.token))
      .catch(() => {
        setItemInLocalStorage(LOCAL_STORAGE_KEY.SOCIAL_LOGIN, {
          type: 'kakao',
          email,
          idToken,
        });
        moveTo('/signup/social');
      });
  }

  static async appleLogin(res: AppleResponse) {
    const { idToken, email } = res;

    appleLogin(idToken)
      .then((res: { token: string }) => tokenState.set(res.token))
      .catch(() => {
        setItemInLocalStorage(LOCAL_STORAGE_KEY.SOCIAL_LOGIN, {
          type: 'apple',
          email,
          idToken,
        });
        moveTo('/signup/social');
      });
  }

  static async showCamera(res: PermissionResponse) {
    if (res.permission === 1) {
      const currentRef = currentRefState.get();
      if (currentRef) currentRef.click();
    }
  }

  static async buildDynamicLink(res: { link: string }) {
    toast.success('링크가 복사되었습니다.');
  }

  static async getDynamicLink(res: { originalId: string }) {
    if (!res.originalId) return;
    Bridge.postMessage(Action.SUCCESS_LINK);

    setItemInLocalStorage(LOCAL_STORAGE_KEY.DYNAMIC_LINK, res.originalId);
    const me = meState.get();
    if (me !== 0 && me !== -1) {
      moveTo('/mission');
    }
  }

  static async sendOneSignal(res: { oneSignalUserId: string }) {
    if (isEmpty(res?.oneSignalUserId)) return;
    updateOneSignal(res);
  }

  static async getVersion(res: { version: string }) {
    if (isEmpty(res?.version)) return;
    versionState.set(res.version);
  }
}

// @ts-ignore
document.addEventListener('message', Bridge.handleMessageEvent);
window.addEventListener('message', Bridge.handleMessageEvent);
