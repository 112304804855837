import { FC } from 'react';
import { isEmpty } from 'lodash';
import { SurveyQuestion } from '../api/survey-questions/type';
import { ChoiceButton } from './ChoiceButton';

interface QuestionSingleProps {
  data: SurveyQuestion;
  selectAnswer: (questionId: number, answerId: number) => void;
  answers: number[];
}

export const QuestionSingle: FC<QuestionSingleProps> = ({
  data,
  selectAnswer,
  answers,
}) => {
  let answer: number;
  if (isEmpty(answers)) {
    answer = -1;
  } else {
    answer = answers[0];
  }

  return (
    <div key={data.id} className="flex flex-col space-y-4">
      <h4>{data.question}</h4>

      <div className="space-y-3">
        {data.surveyQuestionAnswers.map((option) => (
          <ChoiceButton
            key={option.id}
            onClick={() => selectAnswer(data.id, option.id)}
            selected={answer === option.id}
            text={option.body}
          />
        ))}
      </div>
    </div>
  );
};
