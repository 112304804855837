import { FC } from 'react';
import { SurveyQuestion } from '../api/survey-questions/type';
import { ChoiceButton } from './ChoiceButton';

interface QuestionMultiProps {
  data: SurveyQuestion;
  selectAnswer: (questionId: number, answerId: number) => void;
  answers: number[];
}

export const QuestionMulti: FC<QuestionMultiProps> = ({
  data,
  selectAnswer,
  answers,
}) => {
  return (
    <div key={data.id} className="flex flex-col space-y-4">
      <h4>{data.question}</h4>

      <div className="space-y-3">
        {data.surveyQuestionAnswers.map((option) => (
          <ChoiceButton
            key={option.id}
            onClick={() => selectAnswer(data.id, option.id)}
            selected={answers.includes(option.id) ?? false}
            text={option.body}
          />
        ))}
      </div>
    </div>
  );
};
