import { stringify } from 'qs';
import { api } from '../../plugins/axios';
import { PATHS } from './paths';
import {
  PostMissionGroupsDto,
  PostMissionGroupsResponse,
  GetMissionGroupsDto,
  MissionGroupsResponse,
  InvitationResponse,
  UpdateInvitationDto,
  MissionGroup,
} from './type';

export async function postMissionGroups(
  postMissionGroupsDto: PostMissionGroupsDto
) {
  const { data } = await api.post<PostMissionGroupsResponse>(
    PATHS.ROOT,
    postMissionGroupsDto
  );
  return data;
}

export async function getMissionGroups(
  getMissionGroupsDto: GetMissionGroupsDto
) {
  const queryString = stringify(getMissionGroupsDto, {
    addQueryPrefix: true,
    encode: false,
  });
  const { data } = await api.get<MissionGroupsResponse>(
    `/${PATHS.ROOT}${queryString}`
  );
  return data;
}

export async function getMissionGroupsById(groupId: number) {
  const { data } = await api.get<MissionGroup>(
    PATHS.getMissionGroupsById(groupId)
  );
  return data;
}

export async function createInviteGroup(id: number) {
  const { data } = await api.post<{ originalId: string }>(
    PATHS.getInviteById(id)
  );
  return data;
}

export async function createInvitation(originalId: string) {
  const { data } = await api.post<InvitationResponse>(PATHS.INVITED, {
    originalId,
  });
  return data;
}

export async function updateInvitation(
  updateInvitationDto: UpdateInvitationDto
) {
  await api.put(PATHS.INVITED, updateInvitationDto);
}
