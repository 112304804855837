import { FC, Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from '../../components/Button';
import { Icon } from '../../components/Icon';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';

interface SurveyAnswerModalProps {
  open: boolean;
  onClose: () => void;
  mutate: (body: string, isOpen: boolean, answerId?: number) => void;
  data: { id: number; body: string; isOpen: boolean };
}

export const SurveyAnswerModal: FC<SurveyAnswerModalProps> = ({
  open,
  onClose,
  mutate,
  data,
}) => {
  const [body, setBody] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [answerId, setAnswerId] = useState(0);

  useEffect(() => {
    if (data.id > 0) {
      const { id, body, isOpen } = data;
      setBody(body);
      setIsOpen(isOpen);
      setAnswerId(id);
    } else {
      _resetData();
    }
    console.log('현재 들어온 데이터 : ', data);
  }, [data]);

  const onSave = () => {
    if (answerId > 0) {
      mutate(body, isOpen, answerId);
    } else {
      mutate(body, isOpen);
    }
    _resetData();
    onClose();
  };

  const _resetData = () => {
    setBody('');
    setIsOpen(true);
    setAnswerId(0);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto"
          onClose={onClose}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 "
              enterTo="opacity-100 translate-y-0 "
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-4"
            >
              <div className="inline-block w-full transform overflow-hidden rounded-lg bg-white pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:px-0 sm:py-6 sm:align-middle">
                <div className="relative mb-5 w-full border-gray-100 pb-3">
                  <Dialog.Title
                    as="h3"
                    className="subhead-4 px-4 font-medium leading-6 text-gray-900 sm:px-6"
                  >
                    관련 답변
                  </Dialog.Title>

                  <button
                    type="button"
                    className="absolute top-0 right-3 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:right-5"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <Icon.X className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="space-y-5 px-4 sm:px-6">
                  <TextField
                    label="관련답변"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                  />
                  <div className="flex items-center space-x-3">
                    <div>페이지 노출</div>
                    <Toggle
                      checked={isOpen}
                      onChange={(value) => setIsOpen(value)}
                    />
                  </div>
                  <Button
                    onClick={onSave}
                    text="저장하기"
                    className="filled-black w-full font-normal"
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
