import React, { useEffect, useState } from 'react';
import { filter, isEmpty, map } from 'lodash';
import { useQuery } from 'react-query';
import { UserCategory } from '../../../../api/categories/type';
import { BottomFixedButton } from '../../../../components/BottomFixedButton';
import { ChoiceButton } from '../../../../components/ChoiceButton';
import { TopNavBar } from '../../../../components/TopNavBar';
import { DNA_TEST_TYPES } from '../../../../constants';
import { useMyCategory } from '../../../../hooks/categories';
import { fetcher } from '../../../../plugins/react-query';
import { User } from '../../../../types';

export const SelectDnaTestPage = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<UserCategory | null>(
    null
  );

  const { data: me } = useQuery<User>('users/me', fetcher);
  const { data: categoryData } = useMyCategory();

  const setDnaTestCard = () => {
    if (!selectedCategory || isEmpty(selectedCategory.testResults)) {
      setTitle('유전자 검사 결과가 필요해요!');
      setDescription('유전자 검사결과 업로드를 진행해보세요!');
      return;
    }
    const {
      testResults: [{ testImageUrl, testGenome }],
      categoryType,
    } = selectedCategory;

    const categoryTypeName = filter(DNA_TEST_TYPES, {
      type: categoryType,
    })[0].name as string;

    if (testGenome) {
      setTitle(testGenome);
      setDescription(categoryTypeName);
    } else if (!isEmpty(testImageUrl)) {
      setTitle('검사 정보가 입력될 예정이에요!');
      setDescription(categoryTypeName);
    } else {
      setTitle('유전자 검사 결과가 필요해요!');
      setDescription('유전자 검사결과 업로드를 진행해보세요!');
    }
  };

  useEffect(() => {
    setDnaTestCard();
  }, [me, selectedCategory]);

  const categories = categoryData?.categories;
  if (!categories) return <></>;

  return (
    <>
      <TopNavBar />
      <h4 className="whitespace-pre-wrap pt-12 ">
        {'검사결과를 입력할 카테고리를 골라주세요.'}
      </h4>

      <div className="mt-4 space-y-3 rounded-md bg-gray-50 p-4">
        <h3 className="text-brand-1">{title}</h3>
        <p className="text-13 text-gray-600">{description}</p>
      </div>

      <div className="-mx-5 my-6 h-4 bg-gray-50" />

      {!isEmpty(categories) ? (
        <div className="mt-5 grid grid-cols-2 gap-4 pb-28">
          {map(categories, (category) => (
            <ChoiceButton
              key={category.id}
              onClick={() => setSelectedCategory(category)}
              selected={category.id === selectedCategory?.id}
              text={category.name}
            />
          ))}
        </div>
      ) : (
        <div className="flex h-48 items-center justify-center rounded-lg border border-gray-100 bg-gray-50 p-5">
          <div className="text-center text-15 font-bold text-brand-1">
            검사결과를 입력할 카테고리가 없습니다.
            <br />
            미션을 추가해주세요!
          </div>
        </div>
      )}

      <BottomFixedButton
        disabled={!selectedCategory}
        text="다음"
        to={`/mypage/edit/dna-test?categoryId=${selectedCategory?.id}`}
      />
    </>
  );
};
