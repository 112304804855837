import { useState } from 'react';
import CategoriesApiTest from './CategoriesApiTest';
import MissionApiTest from './MissionsApiTest';

export default function TestPage() {
  const [RenderComponent, setRenderComponent] = useState<JSX.Element | null>(
    null
  );

  const notificationMethods = [
    { id: 'categories', title: 'categories', component: <CategoriesApiTest /> },
    { id: 'missions', title: 'missions', component: <MissionApiTest /> },
  ];

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <p className="text-sm leading-5 text-gray-500">render TestPage</p>
      <fieldset className="mt-4">
        <legend className="sr-only">Notification method</legend>
        <div className="space-y-4">
          {notificationMethods.map((notificationMethod) => (
            <div key={notificationMethod.id} className="flex items-center">
              <input
                id={notificationMethod.id}
                value={notificationMethod.id}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                onChange={(e) => {
                  if (e.target.value === notificationMethod.id) {
                    setRenderComponent(notificationMethod.component);
                  }
                }}
                name="notificationMethod"
              />
              <label
                htmlFor={notificationMethod.id}
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {notificationMethod.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      {RenderComponent}
    </div>
  );
}
