import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Icon } from './Icon';
import { TextField } from './TextField';

interface SearchProps {
  className?: string;
  setValue: Dispatch<SetStateAction<string>>;
}

export const Search: FC<SearchProps> = ({ className, setValue }) => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <div
        className={`mt-4 flex w-full items-center rounded-md border border-gray-200 bg-gray-50 pr-4 ${className} h-12`}
      >
        <div className="w-full ">
          <TextField
            placeholder="이메일 검색"
            className=" h-full border-0 bg-gray-50 text-gray-500"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>

        <Icon.Search onClick={() => setValue(searchValue)} />
      </div>
    </>
  );
};
