import { FC } from 'react';
import { MissionStatus } from '../api/mission-groups/enum';

interface EmptyMissionCardProps {
  status: MissionStatus;
}

export const EmptyMissionCard: FC<EmptyMissionCardProps> = ({ status }) => {
  return (
    <div className="flex h-48 items-center justify-center rounded-lg border border-gray-100 bg-gray-50 p-5">
      <h4 className="text-center text-brand-1">
        {status === MissionStatus.INPROGRESS
          ? '현재 진행중인 미션이 없습니다.😥'
          : status === MissionStatus.INVITED
          ? '받은 초대장이 없습니다.😥'
          : '완료된 미션이 없습니다.😥'}
      </h4>
    </div>
  );
};
