import { useEffect, useState } from 'react';
import { get, isEmpty, map } from 'lodash';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { MissionStatus } from '../../api/mission-groups/enum';
import { SurveyQuestionResponse } from '../../api/survey-questions/type';
import { SurveyStatus } from '../../api/surveys/enum';
import { Survey } from '../../api/surveys/type';
import { Button } from '../../components/Button';
import DnaCard from '../../components/DnaCard';
import { EmptyMissionCard } from '../../components/EmptyMissionCard';
import { Icon } from '../../components/Icon';
import { MissionCard as MainMissionCard } from '../../components/MissionCard';
import { SurveyBottomDrawer } from '../../components/drawer/SurveyBottomDrawer';
import { useModal } from '../../components/modal/Modal';
import { NAV_BAR } from '../../constants';
import { useMainContent } from '../../hooks/contents';
import {
  getMissionGroupCard,
  useConfirmInvitation,
  useInvitation,
  useMissionGroups,
} from '../../hooks/mission-groups';
import { Action, Bridge } from '../../plugins/bridge';
import {
  getItemInLocalStorage,
  LOCAL_STORAGE_KEY,
} from '../../plugins/local-storage';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import {
  currentMissionState,
  dnaTestState,
  navBarState,
} from '../../plugins/ridge';
import { MissionCard } from '../../types';

export const HomePage = () => {
  const [showSurvey, setShowSurvey] = useState(false);
  const [currentMission, setCurrentMission] = currentMissionState.use();
  const [needUpdate, setNeedUpdate] = useState(false);
  const [hasDynamicLink, setHasDynamicLink] = useState(true);
  const [missionCard, setMissionCard] = useState<MissionCard[]>([]);
  const { inviteConfirm, inviteComplete } = useModal();
  const { push } = useHistory();
  const { data: content } = useMainContent();
  const { data: surveyResponse } = useSurvey();
  const { data: questionResponse } = useSurveyQuestion(
    surveyResponse?.id ? surveyResponse.id : 0
  );
  const { data: missionData } = useMissionGroups({
    missionStatus: MissionStatus.INPROGRESS,
  });

  useEffect(() => {
    const missionGroups = missionData?.missionGroups;
    if (!missionGroups) return;
    getMissionGroupCard(missionGroups).then((res) => {
      setMissionCard(res as MissionCard[]);
    });
  }, [missionData]);

  useEffect(() => {
    if (needUpdate) {
      const missionGroups = missionData?.missionGroups;
      if (!missionGroups) return;
      getMissionGroupCard(missionGroups).then((res) => {
        setMissionCard(res as MissionCard[]);
      });
      setNeedUpdate(false);
    }
  }, [needUpdate]);

  const setNeedUpdateTrue = () => {
    setNeedUpdate(true);
  };

  const onInvitationSuccess = (
    missionId: number,
    title: string,
    groupId: number
  ) => {
    const confirmInvitationDto = {
      missionId,
      missionGroupId: groupId,
    };
    inviteConfirm(title, () => confirmInvitationMutate(confirmInvitationDto));
  };
  const { mutate: invitationMutate } = useInvitation(onInvitationSuccess);
  const { mutate: confirmInvitationMutate } = useConfirmInvitation(() =>
    inviteComplete()
  );

  const goToDetail = (mission: MissionCard) => {
    const { groupId, missionId, userMissionId, userDailyMissionId } = mission;
    const missionState = {
      groupId,
      missionId,
      userMissionId,
      dailyMissionId: userDailyMissionId,
    };
    setCurrentMission(missionState);

    push({
      pathname: `/mission/${mission.userMissionId}`,
    });
  };

  const getHideSurvey = (surveyId: number) => {
    const surveyData = getItemInLocalStorage(LOCAL_STORAGE_KEY.HIDE_SURVEY);
    const id = get(surveyData, 'surveyId', 0);
    const date = get(surveyData, 'date', '');
    const today = utcToLocalFormat(
      new Date().toUTCString(),
      MomentFormat.YYYY_MM_DD
    );
    if (id === surveyId && date === today) {
      return true;
    }
    return false;
  };

  const goToAddMission = () => {
    dnaTestState.reset();
    push('/dna-test/1');
  };

  useEffect(() => {
    if (
      surveyResponse &&
      questionResponse &&
      !getHideSurvey(surveyResponse.id) &&
      !hasDynamicLink
    ) {
      setShowSurvey(true);
    }
  }, [surveyResponse, questionResponse, hasDynamicLink]);

  useEffect(() => {
    const dynamicLink = getItemInLocalStorage(LOCAL_STORAGE_KEY.DYNAMIC_LINK);
    if (dynamicLink) {
      invitationMutate(dynamicLink);
    } else {
      setHasDynamicLink(false);
    }
  }, []);

  if (!missionData) return null;

  return (
    <>
      {surveyResponse && questionResponse && (
        <SurveyBottomDrawer
          open={showSurvey}
          onClose={() => setShowSurvey(false)}
          surveyData={{
            survey: surveyResponse,
            questions: questionResponse.surveyQuestions,
          }}
        />
      )}

      <div className="my-8">
        <Icon.Logo />
      </div>

      {content && (
        <div
          onClick={() =>
            isEmpty(content.link)
              ? push(`/contents/${content.id}`)
              : Bridge.postMessage(Action.OPEN_LINK, content.link)
          }
        >
          <div className="aspect-[3/1] overflow-hidden rounded-md">
            <img
              src={content.thumbnail}
              alt=""
              className="h-full w-full bg-gray-100 object-cover"
            />
          </div>

          <h5 className="mt-3">{content.title}</h5>
        </div>
      )}

      <div className="-mx-5 my-6 h-4 bg-gray-50" />

      <h4>😊 미션을 25회 이상 성공하면 습관완성!</h4>

      <div className="mt-6 mb-4 flex justify-between text-gray-700">
        <p className="">
          <span className="font-bold">오후 9:00</span> {''}에 알려드려요
        </p>
      </div>

      {isEmpty(missionCard) ? (
        <>
          <EmptyMissionCard status={MissionStatus.INPROGRESS} />
          <Button
            text="+ 습관 추가하기"
            onClick={goToAddMission}
            className="filled-brand-1 mt-4"
          />
        </>
      ) : (
        map(missionCard, (mission) => {
          if (mission)
            return (
              <>
                <MainMissionCard
                  onClick={() => {
                    goToDetail(mission);
                    navBarState.set(NAV_BAR.HOME);
                  }}
                  update={setNeedUpdateTrue}
                  mission={mission}
                />
                <DnaCard mission={mission} />
              </>
            );
        })
      )}
    </>
  );
};

const useSurvey = () => {
  const date = utcToLocalFormat(
    new Date().toUTCString(),
    MomentFormat.YYYY_MM_DD
  );
  return useQuery<Survey>(
    `surveys?nowDate=${date}&status=${SurveyStatus.INPROGRESS}`,
    fetcher
  );
};

const useSurveyQuestion = (id: number) => {
  return useQuery<SurveyQuestionResponse>(
    `survey-questions?surveyId=${id}`,
    fetcher
  );
};
