import React, { useState } from 'react';
import { filter, get, map } from 'lodash';
import { useParams } from 'react-router-dom';
import { CreateCommentDto } from '../../../api/comments/type';
import { MissionStatus } from '../../../api/mission-groups/enum';
import { UserDailyMissionStatus } from '../../../api/user-daily-missions/enum';
import { UserDailyMission } from '../../../api/user-daily-missions/type';
import { Avatar } from '../../../components/Avatar';
import { TopNavBar } from '../../../components/TopNavBar';
import { CommentCard } from '../../../components/mission-community/CommentCard';
import { CreateCommentButton } from '../../../components/mission-community/CreateCommentButton';
import { MissionProgressBar } from '../../../components/mission-community/MissionProgressBar';
import { CreateCommentModal } from '../../../components/modal/CreateCommentModal';
import { useModal } from '../../../components/modal/Modal';
import {
  useCreateComments,
  useDeleteComment,
  useGetComments,
} from '../../../hooks/comments';
import { useUserMissionsByUser } from '../../../hooks/user-missions';
import { useUser } from '../../../hooks/users';
import {
  getItemInLocalStorage,
  LOCAL_STORAGE_KEY,
} from '../../../plugins/local-storage';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';

export type CommentData = Omit<CreateCommentDto, 'receiverId'>;

export const getHideCommentPointModal = () => {
  const hideComment = getItemInLocalStorage(
    LOCAL_STORAGE_KEY.HIDE_COMMENT_MODAL
  );
  const date = get(hideComment, 'date', '');
  const today = utcToLocalFormat(
    new Date().toUTCString(),
    MomentFormat.YYYY_MM_DD
  );
  return date === today;
};

export const CommentsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { createComment } = useModal();
  const { userId } = useParams<{ userId: string }>();

  const { data: comments } = useGetComments({ receiverId: Number(userId) });
  const { data: userMissions } = useUserMissionsByUser({ userId });
  const { data: user } = useUser({ id: userId });
  const { mutate: createCommentMutate } = useCreateComments(() =>
    setIsModalOpen(false)
  );
  const { mutate: deleteCommentMutate } = useDeleteComment();

  const getSuccessNumber = (dailyMission: UserDailyMission[]) => {
    const result = filter(dailyMission, {
      status: UserDailyMissionStatus.SUCCESS,
    });
    return result.length;
  };

  const clickCreateComment = () => {
    if (getHideCommentPointModal()) {
      setIsModalOpen(true);
      return;
    }

    createComment(() => setIsModalOpen(true));
  };

  const onCreateComment = (data: CommentData) => {
    createCommentMutate({ ...data, receiverId: parseInt(userId) });
  };

  if (!user || !comments || !userMissions) return <></>;

  return (
    <>
      <CreateCommentModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        onClick={onCreateComment}
      />
      <TopNavBar />

      <div className="flex items-center space-x-3 pt-8">
        <Avatar src={user.avatar} className="wh-16" />
        <h4>{`${user.name}님을 위한 응원댓글이에요!`}</h4>
      </div>

      <div className="my-5 space-y-3">
        {map(userMissions, (mission) => {
          if (mission.status === MissionStatus.INPROGRESS) {
            return (
              <MissionProgressBar
                title={mission.mission.title ?? ''}
                success={getSuccessNumber(mission.userDailyMissions) ?? 0}
              />
            );
          }
        })}
      </div>
      <CreateCommentButton onClick={clickCreateComment} />

      <div className="-mx-5 my-5 h-4 bg-gray-50" />

      <div className="space-y-4">
        {map(comments, (comment) => (
          <CommentCard
            key={comment.id}
            comment={comment}
            onDelete={deleteCommentMutate}
          />
        ))}
      </div>
    </>
  );
};
