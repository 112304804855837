import { FC, useEffect, useState } from 'react';
import { EmojiType } from '../../api/comments/enum';
import { CreateCommentDto } from '../../api/comments/type';
import { ReactComponent as LaughEmoji } from '../../assets/svg/emoji-laugh.svg';
import { ReactComponent as PartyEmoji } from '../../assets/svg/emoji-party.svg';
import { ReactComponent as StarStruckEmoji } from '../../assets/svg/emoji-starstruck.svg';
import { CommentData } from '../../pages/comments/[id]/CommentsPage';
import { Button } from '../Button';
import { TextArea } from '../TextArea';
import { AnimationLayout } from './AnimationLayout';

const EMOJIS = [
  { id: 1, type: EmojiType.TEARS, icon: <LaughEmoji className="wh-12" /> },
  { id: 2, type: EmojiType.BIRTHDAY, icon: <PartyEmoji className="wh-12" /> },
  { id: 3, type: EmojiType.STAR, icon: <StarStruckEmoji className="wh-12" /> },
];

interface CreateCommentModalProps {
  open: boolean;
  onClose: () => void;
  onClick: (data: CommentData) => void;
}

export const CreateCommentModal: FC<CreateCommentModalProps> = ({
  open,
  onClose,
  onClick,
}) => {
  const [selectedEmoji, setSelectedEmoji] = useState(EMOJIS[0]);
  const [body, setBody] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const _onClick = () => {
    setIsUploading(true);
    const data = {
      emoji: selectedEmoji.type,
      body,
    };
    onClick(data);
  };

  const init = () => {
    setBody('');
    setSelectedEmoji(EMOJIS[0]);
  };

  useEffect(() => {
    init();
  }, [open]);

  return (
    <>
      <AnimationLayout open={open}>
        <div className="my-8 w-full max-w-lg transform space-y-3 overflow-hidden rounded-lg bg-white p-6 text-center shadow-xl transition-all">
          <h3>응원의 댓글 남기기</h3>

          <p className="pb-2 text-15">응원 이모티콘을 선택해주세요!</p>

          <div className="flex justify-between pb-2">
            {EMOJIS.map((emoji) => (
              <button
                key={emoji.id}
                onClick={() => setSelectedEmoji(emoji)}
                className={`${
                  selectedEmoji === emoji ? 'opacity-100' : 'opacity-30'
                }`}
              >
                {emoji.icon}
              </button>
            ))}
          </div>

          <TextArea
            placeholder="응원의 댓글을  입력해주세요."
            className="h-32"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />

          <div className="flex flex-col space-y-3 pt-5">
            <Button
              disabled={isUploading}
              onClick={_onClick}
              text="댓글남기기"
              className="filled-brand-1"
            />
            <Button
              onClick={onClose}
              type="button"
              text="닫기"
              className="bg-gray-50"
            />
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};
