import { FC } from 'react';

interface Prevention {
  title: string;
  description: string;
}

interface SelectPreventionProps {
  prevention: Prevention;
  selected: boolean;
  onClick: () => void;
}

export const SelectPrevention: FC<SelectPreventionProps> = ({
  prevention,
  selected,
  onClick,
}) => {
  const { title, description } = prevention;
  return (
    <div
      onClick={onClick}
      className={`${
        selected ? ' border-brand-1 bg-brand-2 ' : 'border-gray-100'
      } space-y-3 rounded-xl border p-4`}
    >
      <div className={`flex items-start justify-between space-x-6`}>
        <h4 className={`${selected ? 'text-brand-1' : ''} flex-1`}>{title}</h4>
        <button
          type="button"
          className={`${
            selected
              ? 'filled-brand-1 border border-transparent'
              : 'outlined-brand-1'
          } rounded-full px-3 py-1 text-14`}
        >
          선택
        </button>
      </div>

      <p className="text-14 text-gray-600">{description}</p>
    </div>
  );
};
