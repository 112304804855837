import React, { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FileType } from '../../../../api/files/enum';
import { agreeSensitivePolicy } from '../../../../api/users';
import TestExampleImg from '../../../../assets/img/dna-test-example.png';
import { Button } from '../../../../components/Button';
import { TopNavBar } from '../../../../components/TopNavBar';
import { useModal } from '../../../../components/modal/Modal';
import { useOneCategory } from '../../../../hooks/categories';
import { useImageUpload } from '../../../../hooks/files';
import { useTestResults } from '../../../../hooks/test-results';
import { useUpdateTestResult } from '../../../../hooks/users';
import { Action, Bridge } from '../../../../plugins/bridge';
import { fetcher } from '../../../../plugins/react-query';
import { currentRefState } from '../../../../plugins/ridge';
import { User } from '../../../../types';

export const EditDnaTestPage = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    goBack,
    location: { search },
  } = useHistory();
  const categoryId = Number(search.split('=')[1]);
  const { checkSensitiveAgreement } = useModal();
  const { data: me } = useQuery<User>('users/me', fetcher);
  const { isUploading, handleImageChange } = useImageUpload();
  const { data: testResult } = useTestResults(categoryId);
  const { data: category } = useOneCategory(categoryId);
  const { mutate: testResultMutate } = useUpdateTestResult(() =>
    testUpdateSuccess()
  );

  const [imageUrl, setImageUrl] = useState(TestExampleImg);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [agreement, setAgreement] = useState(false);

  useEffect(() => {
    if (!testResult || isEmpty(testResult.testImageUrl)) return;
    setImageUrl(testResult.testImageUrl);
  }, [testResult]);

  const _handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newImage = e.target.files?.item(0);
    if (!newImage) return;
    const url = await handleImageChange(newImage, FileType.TEST_RESULT);
    if (!url) return;
    setImageUrl(url);
    setIsImageUploaded(true);
  };

  const onSave = () => {
    if (isImageUploaded && categoryId) {
      testResultMutate({
        categoryId,
        testResultImage: imageUrl,
        isTested: true,
      });
    }
  };

  const testUpdateSuccess = () => {
    toast.success('저장되었습니다');
    goBack();
  };

  const checkAgreement = () => {
    if (me?.sensitiveAgreement || agreement) {
      useCamera();
    } else {
      checkSensitiveAgreement(onAgreeSensitivePolicy, goBack);
    }
  };

  const onAgreeSensitivePolicy = () => {
    setAgreement(true);
    agreeSensitivePolicy();
    useCamera();
  };

  const useCamera = () => {
    currentRefState.set(inputRef.current);
    Bridge.postMessage(Action.CHECK_CAMERA_PERMISSION, '');
  };

  return (
    <>
      <TopNavBar />

      <h2 className="pt-8 pb-6 leading-normal">
        선택한 <span className="text-brand-1">‘{category?.name}’</span>에 대한
        검사
        <br />
        결과지를 예시와 같이 유전자 정보가 나오게 촬영하여 올려주세요.
      </h2>

      <div className="relative h-44 w-full overflow-hidden rounded-lg">
        <img
          src={`${imageUrl}`}
          alt=""
          className="absolute h-full w-full bg-gray-100 object-cover"
        />
        <input
          ref={inputRef}
          type="file"
          id="test-result"
          className="hidden"
          accept="image/*"
          onChange={_handleImageChange}
        />
      </div>

      <p className="pt-5 text-14 text-gray-500">
        유전자 정보 이외의 다른 개인정보는 나오지 않게 주의하여 촬영하여 주세요.
        유전자 정보는 맞춤형 건강정보 제공을 위해 필요합니다. 어떠한 경우에도 제
        3자에게 제공되지 않으며 아이힐미에서 안전하게 보관합니다.
      </p>

      <div className="fixed bottom-4 -mx-5 flex w-full flex-col space-y-4 px-5">
        {isImageUploaded ? (
          <Button className="filled-brand-1" text="업로드" onClick={onSave} />
        ) : (
          <Button
            disabled={isUploading}
            className="filled-brand-1"
            text={
              !isEmpty(testResult?.testImageUrl)
                ? '수정하기'
                : '촬영하고 +50P 받기'
            }
            onClick={() => {
              checkAgreement();
            }}
          />
        )}
        <Button
          className="outlined-brand-1  bg-white"
          text="나중에 올릴래요."
          onClick={() => goBack()}
        />
      </div>
    </>
  );
};
