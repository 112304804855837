import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import { getReviewsXlsx } from '../../../api/reviews';
import {
  FirstAnswer,
  QuestionType,
  SecondAnswer,
  ThirdAnswer,
} from '../../../api/reviews/enum';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Select } from '../../../components/Select';
import { Table } from '../../../components/Table';
import { REVIEWS } from '../../../constants';
import { useAdminReview } from '../../../hooks/reviews';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { AdminH1 } from '../../components/AdminH1';

interface Question {
  id: number;
  title: string;
  options: Option[];
}

interface Option {
  id: number;
  answer: string;
  value: AnswerType;
}

type AnswerType = FirstAnswer | SecondAnswer | ThirdAnswer;

export const MissionReview = () => {
  const [questionIdx, setQuestionIdx] = useState(-1);
  const [answerId, setAnswerId] = useState(0);
  const [questionType, setQuestionType] = useState<QuestionType>(
    QuestionType.FIRST
  );
  const [answerType, setAnswerType] = useState<AnswerType>(FirstAnswer.FUN);
  const { data: missionData } = useAdminReview({ questionType, answerType });
  const Reviews = REVIEWS as Question[];

  useEffect(() => {
    if (questionIdx === -1) {
      setAnswerId(0);
    }
  }, [questionIdx]);

  useEffect(() => {
    if (questionIdx < 0) return;
    switch (questionIdx) {
      case 0:
        setQuestionType(QuestionType.FIRST);
        break;
      case 1:
        setQuestionType(QuestionType.SECOND);
        break;
      case 2:
        setQuestionType(QuestionType.THIRD);
        break;
    }
    const answer = Reviews[questionIdx].options.find(
      (a) => a.id === answerId
    )?.value;
    answer && setAnswerType(answer);
  }, [answerId]);

  const exportXlsx = () => {
    getReviewsXlsx();
  };

  return (
    <div className="mt-5 space-y-5">
      <AdminH1>미션후기 관리</AdminH1>
      <Button
        text="엑셀 Export"
        className="filled-black h-10 font-normal"
        onClick={exportXlsx}
      />
      <Select
        label="미션질문"
        onChange={(e) => setQuestionIdx(+e.target.value)}
      >
        <option key={0} value={-1}>
          {'--- 선택 ---'}
        </option>
        {map(Reviews, (q, i) => (
          <option key={q.id} value={i}>
            {q.title}
          </option>
        ))}
      </Select>
      <Select label="관련답변" onChange={(e) => setAnswerId(+e.target.value)}>
        <option key={answerId} value={answerId}>
          {'--- 선택 ---'}
        </option>
        {questionIdx >= 0 &&
          map(Reviews[questionIdx].options, (a) => (
            <option key={a.id} value={a.id}>
              {a.answer}
            </option>
          ))}
      </Select>
      <div className="pt-8 text-20 font-semibold">미션 후기 답변리스트</div>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>NO.</Table.Th>
              <Table.Th>미션 완료일시</Table.Th>
              <Table.Th>유저명</Table.Th>
              <Table.Th>선택한 카테고리</Table.Th>
              <Table.Th>유전자 검사기록</Table.Th>
              <Table.Th>진행한 미션</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {answerId !== 0 &&
              map(missionData, (mission, i) => (
                <Table.Row key={`${mission.id}-${mission.user.id}`}>
                  <Table.Td>{i + 1}</Table.Td>
                  <Table.Td>
                    {utcToLocalFormat(mission.updatedAt, MomentFormat.YYYYMMDD)}
                  </Table.Td>
                  <Table.Td>{mission.user.name}</Table.Td>
                  <Table.Td>{mission.categoryName}</Table.Td>
                  <Table.Td>{mission.isTested ? '있음' : '없음'}</Table.Td>
                  <Table.Td>{mission.missionTitle}</Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card>
    </div>
  );
};
