import React, { ButtonHTMLAttributes, FC } from 'react';

interface AdminTabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  text: string;
  underline?: boolean;
}

export const AdminTab: FC<AdminTabProps> = ({
  selected,
  text,
  underline = true,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`${
        selected
          ? `${
              underline
                ? 'border-b-2 border-brand-1 font-semibold text-brand-1'
                : 'rounded-full bg-black text-black'
            }`
          : 'text-gray-500'
      } px-3 ${underline ? 'pb-1' : 'py-1.5'}`}
    >
      {text}
    </button>
  );
};
