import moment from 'moment';
import 'moment/locale/ko';

// example utc: '2021-05-28T08:21:28.475Z'

export enum MomentFormat {
  /** 17:21 */
  HHmm = 'HH:mm',
  /** 2021년 5월 28일 (금) */
  LLdd = 'LL (dd)',
  /** 2021년 5월 28일 17:21 */
  LLHHmm = 'LL HH:mm',
  /** 2021.05.28 5:21 오후 */
  YYYYMMDDhmma = 'YYYY.MM.DD h:mm a',
  /** 2021.05.28 17:21 */
  YYYYMMDDHmm = 'YYYY.MM.DD H:mm',
  /** 2021.05.28 */
  YYYYMMDD = 'YYYY.MM.DD',
  /** 2021-05-28 */
  YYYY_MM_DD = 'YYYY-MM-DD',
  /** 오후 */
  a = 'a',
  /** 5:21 */
  hmm = 'h:mm',
  /** 08.20 */
  MMDD = 'MM.DD',
  /** 20220722123512 */
  YYYYMMDDHHmmss = 'YYYYMMDDHHmmss',
}

export function utcToLocalFormat(utc: string, format?: MomentFormat) {
  return moment.utc(utc).local().format(format);
}

/** 3시간 전 */
export function utcToLocalFromNow(utc: string) {
  return moment.utc(utc).local().fromNow();
}

export function utcToDayDiff(utc: string | undefined) {
  if (!utc) return -1;
  const targetDate = moment.utc(utc);
  const dayDiff = moment().startOf('day').diff(targetDate, 'days');
  return dayDiff;
}

export function utcToDDay(utc: string | undefined) {
  if (!utc) return -1;
  const targetDate = moment.utc(utc);
  const dDay = moment().startOf('day').diff(targetDate, 'days');

  if (dDay < 0) return -dDay;
  return 0;
}

export function utcToIsAfter(utc: string | undefined) {
  if (!utc) return false;
  const targetDate = moment.utc(utc);
  return moment().isAfter(targetDate, 'day');
}
