import { FC } from 'react';
import { Action, Bridge } from '../plugins/bridge';
import { Checkbox } from './Checkbox';

interface AgreementProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  name: string;
  link?: string;
}

export const Agreement: FC<AgreementProps> = ({
  checked,
  onChange,
  label,
  name,
  link,
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox name={name} checked={checked} onChange={onChange} />
      <div>
        <span>{label} </span>
        {link && (
          <button
            type="button"
            onClick={() => Bridge.postMessage(Action.OPEN_LINK, link)}
          >
            (자세히)
          </button>
        )}
      </div>
    </div>
  );
};
