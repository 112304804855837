import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { AdminTab } from '../../components/AdminTab';
import { UserInfo } from './UserInfo';
import { UserMission } from './UserMission';
import { UserPoint } from './UserPoint';
import { UserTest } from './UserTest';

export const UserDetail = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { id } = useParams() as { id: string };
  return (
    <div>
      <div className="flex w-full space-x-4 border-b px-6 pt-6">
        <AdminTab
          text="기본 정보"
          selected={pathname === `/admin/users/${id}/info`}
          onClick={() => push(`/admin/users/${id}/info`)}
        />
        <AdminTab
          text="검사 정보"
          selected={pathname === `/admin/users/${id}/test`}
          onClick={() => push(`/admin/users/${id}/test`)}
        />
        <AdminTab
          text="진행 미션"
          selected={pathname === `/admin/users/${id}/mission`}
          onClick={() => push(`/admin/users/${id}/mission`)}
        />
        <AdminTab
          text="힐링스코어 히스토리"
          selected={pathname === `/admin/users/${id}/point`}
          onClick={() => push(`/admin/users/${id}/point`)}
        />
      </div>
      <Switch>
        <Route component={UserMission} path="/admin/users/:id/mission" />
        <Route component={UserPoint} path="/admin/users/:id/point" />
        <Route component={UserInfo} path="/admin/users/:id/info" />
        <Route component={UserTest} path="/admin/users/:id/test" />
        <Route path="/admin/users/:id">
          <Redirect to={`/admin/users/${id}/info`} />
        </Route>
      </Switch>
    </div>
  );
};
