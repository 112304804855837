import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { every, filter, includes, isEmpty, some } from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Gender, HealthCare } from '../../../api/auth/enum';
import { FileType } from '../../../api/files/enum';
import { BottomFixedButton } from '../../../components/BottomFixedButton';
import { ChoiceButton } from '../../../components/ChoiceButton';
import { EditAvatar } from '../../../components/EditAvatar';
import { TextField } from '../../../components/TextField';
import { GENDERS, HEALTHCARES } from '../../../constants';
import { useSignUp } from '../../../hooks/auth';
import { useImageUpload } from '../../../hooks/files';
import {
  getItemInLocalStorage,
  LOCAL_STORAGE_KEY,
} from '../../../plugins/local-storage';

interface FormValues {
  name: string;
  age: number;
  height: number;
  weight: number;
}

export const MyInfoPage = () => {
  const { goBack, replace } = useHistory();
  const [selectedGender, setSelectedGender] = useState(GENDERS[0]);
  const [selectedHealthCareIds, setSelectedHealthCareIds] = useState<number[]>(
    []
  );
  const [avatar, setAvatar] = useState('');
  const { isUploading, handleImageChange } = useImageUpload();
  const { mutate: signUpMutate } = useSignUp(() => onSuccessSignup());

  const getUserInfo = () => {
    return getItemInLocalStorage(LOCAL_STORAGE_KEY.CREATE_USER_INFO);
  };

  const onSuccessSignup = () => {
    const dynamicLink = getItemInLocalStorage(LOCAL_STORAGE_KEY.DYNAMIC_LINK);
    if (dynamicLink) {
      replace('/mission');
    } else {
      replace('/signup/success');
    }
  };

  useEffect(() => {
    const userInfo = getUserInfo();
    if (!userInfo) {
      toast.error('잘못된 접근입니다.');
      goBack();
    }
  }, []);

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required('이름을 입력해주세요.')
      .max(10, '최대 10자 까지만 가능합니다.'),
    age: Yup.number()
      .typeError('숫자만 입력해주세요')
      .required('값을 입력해주세요.'),
    height: Yup.number()
      .typeError('숫자만 입력해주세요')
      .required('값을 입력해주세요.'),
    weight: Yup.number()
      .typeError('숫자만 입력해주세요')
      .required('값을 입력해주세요.'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  const _handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newImage = e.target.files?.item(0);
    if (!newImage) return;
    const url = await handleImageChange(newImage, FileType.AVATAR);
    if (!url) return;
    setAvatar(url);
  };

  const _onClickHealthCareButton = (healthCare: {
    id: number;
    text: string;
    data: string;
  }) => {
    const { id } = healthCare;
    if (id === 3) {
      setSelectedHealthCareIds([id]);
      return;
    }
    const updateHealthCareSelectedIds = filter(
      selectedHealthCareIds,
      (selectedHealthCareIds) => selectedHealthCareIds !== 3
    );
    if (includes(updateHealthCareSelectedIds, id)) {
      setSelectedHealthCareIds(
        filter(
          updateHealthCareSelectedIds,
          (isHealthCareSelectedId) => isHealthCareSelectedId !== id
        )
      );
      return;
    }
    setSelectedHealthCareIds([...updateHealthCareSelectedIds, id]);
  };

  return (
    <>
      <div className="pt-16 pb-6">
        <h2>회원님에 대해 알려주세요!</h2>
        <h5 className="font-normal text-gray-400">
          아이힐미와 함께할 정보를 입력해주세요!
        </h5>
      </div>

      <form
        className="space-y-9 pb-32"
        onSubmit={handleSubmit((data) => {
          const userInfo = getUserInfo();
          signUpMutate({
            ...data,
            ...userInfo,
            avatar,
            gender: selectedGender.data,
            healthCare: selectedHealthCareIds.map((id) => HEALTHCARES[id].data),
          });
        })}
      >
        <h4>프로필 사진을 업로드해주세요!</h4>

        <EditAvatar src={avatar} handleImageChange={_handleImageChange} />

        <div className="space-y-4">
          <h4>사용하실 닉네임을 알려주세요!</h4>
          <TextField
            placeholder="10글자 이하로 입력해주세요."
            helper={errors.name?.message}
            {...register('name')}
          />
        </div>

        <div className="space-y-4">
          <h4>성별이 어떻게 되시나요?</h4>
          <div className="grid grid-cols-2 gap-4">
            {GENDERS.map((gender) => (
              <ChoiceButton
                key={gender.id}
                onClick={() => setSelectedGender(gender)}
                selected={selectedGender === gender}
                text={gender.text}
              />
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <h4>나이와 키,몸무게도 알려주세요!</h4>
          <div className="textfield flex items-center justify-between">
            <TextField
              type="number"
              removeGlobalCSS
              parentClassName="w-full -mr-10 pr-10"
              className="border-0"
              placeholder="나이"
              {...register('age')}
            />
            <span className="">세</span>
          </div>
          <div className="textfield flex items-center justify-between">
            <TextField
              type="number"
              removeGlobalCSS
              parentClassName="w-full -mr-10 pr-10"
              className="border-0"
              placeholder="키"
              {...register('height')}
            />
            <span className="">cm</span>
          </div>
          <div className="textfield flex items-center justify-between">
            <TextField
              type="number"
              removeGlobalCSS
              parentClassName="w-full -mr-10 pr-10"
              className="border-0"
              placeholder="몸무게"
              {...register('weight')}
            />
            <span className="">KG</span>
          </div>
          <p className="text-sm text-red-500">
            {errors.age?.message ||
              errors.height?.message ||
              errors.weight?.message}
          </p>
        </div>

        <div className="">
          <h4>평소 꾸준히 하고 있는 건강관리가 무엇인가요?</h4>
          <span className="text-13">(중복선택 가능)</span>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {HEALTHCARES.map((healthcare) => (
            <ChoiceButton
              key={healthcare.id}
              onClick={() => _onClickHealthCareButton(healthcare)}
              selected={includes(selectedHealthCareIds, healthcare.id)}
              text={healthcare.text}
            />
          ))}
        </div>
        <BottomFixedButton
          text="가입하고 +50P 받기"
          disabled={some([
            !every(Object.values(watch(['name', 'age', 'height', 'weight']))),
            isEmpty(selectedHealthCareIds),
            isEmpty(selectedGender),
            !isEmpty(errors),
            isUploading,
          ])}
        />
      </form>
    </>
  );
};
