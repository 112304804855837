import React, { useEffect, useState } from 'react';
import { filter, map } from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Gender, HealthCare } from '../../../api/auth/enum';
import { Avatar } from '../../../components/Avatar';
import { Button } from '../../../components/Button';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { Toggle } from '../../../components/Toggle';
import { HEALTHCARES } from '../../../constants';
import {
  useAdminUser,
  useAdminUserPoints,
  useUpdateAdminUser,
} from '../../../hooks/users';

interface FormValues {
  phoneNumber: string;
  age: number;
  weight: number;
  height: number;
  adminMemo: string;
}

export const UserInfo = () => {
  const { push } = useHistory();
  const [isMarketingChecked, setIsMarketingChecked] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { data: user } = useAdminUser({ id });
  const { data: userPoints } = useAdminUserPoints({ id });
  const { mutate: updateAdminUserMutate } = useUpdateAdminUser(() =>
    onUpdateSuccess()
  );

  const { handleSubmit, register, reset } = useForm<FormValues>();

  useEffect(() => {
    if (user) {
      setIsMarketingChecked(user?.marketingAgreement ?? false);
      const { age, weight, height, phoneNumber, adminMemo } = user;

      reset({
        age,
        weight,
        height,
        phoneNumber,
        adminMemo,
      });
    }
  }, [user]);

  const _handleSubmit = (data: FormValues) => {
    if (!window.confirm('저장하시겠습니까?')) return;

    const { age, weight, height, phoneNumber, adminMemo } = data;
    const body = {
      age,
      weight,
      height,
      phoneNumber,
      ...(adminMemo && { adminMemo }),
      id: parseInt(id),
      marketingAgreement: isMarketingChecked,
    };
    updateAdminUserMutate(body);
  };

  const onUpdateSuccess = () => {
    toast.success('정보가 수정되었습니다.');
    push(`/admin/users/${id}`);
  };

  const getHealthCareString = (healtCare: HealthCare) => {
    const data = filter(HEALTHCARES, { data: healtCare })[0];
    return data.text;
  };

  const getHealthCare = (healthCareList: HealthCare[]) => {
    const results = map(healthCareList, (item) => getHealthCareString(item));
    return results.join(', ');
  };

  if (!user || !userPoints) return <></>;
  const { points } = userPoints;

  return (
    <div className="space-y-3 p-6">
      <form onSubmit={handleSubmit((data) => _handleSubmit(data))}>
        <div className="flex items-center space-x-3 py-6">
          <p>마케팅 동의</p>
          <Toggle
            checked={isMarketingChecked}
            onChange={(value) => setIsMarketingChecked(value)}
          />
        </div>
        <Avatar className="wh-20" src={user.avatar} />
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <TextField label="닉네임" disabled value={user.name} />
          <TextField
            label="보유 힐링 스코어"
            disabled
            value={points[0]?.totalAmount}
          />
          <TextField label="이메일" disabled type="email" value={user.email} />
          <TextField label="휴대폰 번호" {...register('phoneNumber')} />
          <TextField
            label="성별"
            disabled
            value={user.gender === Gender.MALE ? '남자' : '여자'}
          />
          <TextField label="나이" {...register('age')} />
          <TextField label="몸무게" {...register('weight')} />
          <TextField label="키" {...register('height')} />
          <TextField
            disabled
            label="선택한 건강관리"
            value={getHealthCare(user.healthcare)}
          />
        </div>
        <div className="col-start-1 mt-10 md:col-span-2">
          <TextArea
            className="h-32"
            label="관리자메모"
            {...register('adminMemo')}
          />
        </div>
        <div className="flex justify-end space-x-4 py-4">
          <Button
            text="저장하기"
            className="filled-gray-900 h-10 text-sm"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
