import { stringify } from 'qs';
import { adminApi, api } from '../../plugins/axios';
import { PATHS } from './paths';
import {
  Category,
  GetCategoryDto,
  CreateCategoryDto,
  AdminCategoryResponse,
  UpdateCategoryDto,
  GetAdminCategoryDto,
  AdminCategory,
  UserCategoriesResponse,
  GetAdminUserCategoryDto,
} from './type';

export async function getOneCategory(id: number) {
  const { data } = await api.get<Category>(PATHS.getOneById(id));
  return data;
}

export async function getCategory(getCategoryDto: GetCategoryDto) {
  const queryString = stringify(getCategoryDto, {
    addQueryPrefix: true,
    encode: false,
  });
  const { data } = await api.get<{ categories: Category[] }>(
    `/${PATHS.ROOT}${queryString}`
  );
  return data.categories;
}

export function createCategory(createCategoryDto: CreateCategoryDto) {
  return adminApi.post(PATHS.ROOT, createCategoryDto);
}

export async function getAdminCategory(
  getAdminCategoryDto: GetAdminCategoryDto
) {
  const { data } = await adminApi.get<AdminCategory>(
    PATHS.getOneById(getAdminCategoryDto.id)
  );
  return data;
}

export async function getAdminCategories() {
  const queryString = stringify(
    { itemsPerPage: 500 },
    {
      addQueryPrefix: true,
      encode: false,
    }
  );
  const { data } = await adminApi.get<AdminCategoryResponse>(
    `/${PATHS.ROOT}${queryString}`
  );
  return data;
}

export function updateCategory(updateCategoryDto: UpdateCategoryDto) {
  const { id, body } = updateCategoryDto;
  return adminApi.patch(PATHS.getOneById(id), body);
}

export async function getAdminUserCategory(
  GetAdminUserCategoryDto: GetAdminUserCategoryDto
) {
  const queryString = stringify(GetAdminUserCategoryDto, {
    addQueryPrefix: true,
    encode: false,
  });
  const { data } = await adminApi.get<UserCategoriesResponse>(
    `/${PATHS.MISSIONS}${queryString}`
  );
  return data;
}
