import { AxiosError } from 'axios';
import { filter, map } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { getComment } from '../../api/comments';
import {
  createInvitation,
  createInviteGroup,
  getMissionGroups,
  getMissionGroupsById,
  postMissionGroups,
  updateInvitation,
} from '../../api/mission-groups';
import { PATHS } from '../../api/mission-groups/paths';
import { getTestResultsByCategory } from '../../api/test-results';
import { getTodayDailyMission as getFriendTodayDailyMission } from '../../api/user-daily-missions';
import { UserDailyMissionStatus } from '../../api/user-daily-missions/enum';
import { getUserMissionsByGroupId } from '../../api/user-missions';
import { utcToDayDiff, utcToDDay } from '../../plugins/moment';
import { meState } from '../../plugins/ridge';
import {
  PostMissionGroupsResponse,
  PostMissionGroupsDto,
  MissionGroup,
  GetMissionGroupsDto,
  InvitationResponse,
  UpdateInvitationDto,
  GroupMissionUser,
} from './../../api/mission-groups/type';
import { UserDailyMission } from './../../api/user-daily-missions/type';
import {
  LOCAL_STORAGE_KEY,
  removeItemInLocalStorage,
} from './../../plugins/local-storage';

export const usePostMissionGroups = (
  onSuccess?: (data: PostMissionGroupsResponse) => void,
  onError?: () => void
) => {
  return useMutation(
    (postMissionGroupsDto: PostMissionGroupsDto) =>
      postMissionGroups(postMissionGroupsDto),
    {
      onSuccess: (data: PostMissionGroupsResponse) => {
        onSuccess && onSuccess(data);
      },
      onError: (e: AxiosError) => {
        if (e.response?.data?.status === 409) {
          onError && onError();
        } else {
          toast.error('미션 추가에 실패했습니다.');
        }
      },
    }
  );
};

export const useMissionGroups = (getMissionGroupsDto: GetMissionGroupsDto) => {
  return useQuery([PATHS.ROOT, getMissionGroupsDto.missionStatus], () =>
    getMissionGroups(getMissionGroupsDto)
  );
};

export const useMissionGroupsById = (missionGroupId: number) => {
  return useQuery(
    [PATHS.ROOT, missionGroupId],
    () => getMissionGroupsById(missionGroupId),
    {
      enabled: !!missionGroupId,
    }
  );
};

const getTodayDailyMission = (dailyMissions: UserDailyMission[]) => {
  const [today] = filter(
    dailyMissions,
    (daily) => utcToDayDiff(daily.createdAt) === 0
  );
  // 일치하는 날이 없을 경우(미션 종료 후) 임시로 id: 0, status: 성공을 넘김
  if (!today) {
    return { userDailyMissionId: 0, status: UserDailyMissionStatus.SUCCESS };
  }
  return { userDailyMissionId: today.id, status: today.status };
};

const getMissionCardByGroup = async (
  missionGroup: MissionGroup | undefined
) => {
  const me = meState.get();
  if (!missionGroup || me === 0 || me === -1) return;
  const [userMission] = await getUserMissionsByGroupId({
    missionGroupId: missionGroup.id,
    userId: me?.id,
  });

  const { mission, userDailyMissions, startAt, endAt } = userMission;
  const { userDailyMissionId, status } =
    getTodayDailyMission(userDailyMissions);
  const { isTested, testImageUrl } = await getTestResultsByCategory(
    mission.categoryId
  );
  return {
    groupId: missionGroup.id,
    countUser: missionGroup.countUser,
    users: missionGroup.users,
    missionId: mission.id,
    category: mission.category.name,
    title: mission.title,
    userMissionId: userMission.id,
    userMissionStatus: userMission.status,
    dDay: utcToDDay(endAt),
    startAt,
    endAt,
    userDailyMissionId,
    status,
    isTested,
    testImageUrl,
    categoryId: mission.categoryId,
  };
};

export const getMissionGroupCard = async (missionGroups: MissionGroup[]) => {
  const missionCards = await Promise.all(
    map(missionGroups, (group) => getMissionCardByGroup(group))
  );
  return missionCards;
};

export const useInviteGroup = (
  onSuccess: (code: string) => void,
  onError: () => void
) => {
  return useMutation((id: number) => createInviteGroup(id), {
    onSuccess: (res: { originalId: string }) => {
      onSuccess(res.originalId);
    },
    onError: () => {
      onError();
    },
  });
};

export const useInvitation = (
  onSuccess: (groupId: number, title: string, missionGroupId: number) => void
) => {
  return useMutation((originalId: string) => createInvitation(originalId), {
    onSuccess: (res: InvitationResponse) => {
      onSuccess(res.id, res.title, res.missionGroupId);
    },
    onError: (e: AxiosError) => {
      console.log(e);
      if (e.response?.status === 409) {
        toast.error('이미 초대받거나 참여중인 미션입니다.');
      } else {
        toast.error('올바르지 않은 링크입니다.');
      }
      removeItemInLocalStorage(LOCAL_STORAGE_KEY.DYNAMIC_LINK);
    },
  });
};

export const useConfirmInvitation = (onSuccess: () => void) => {
  return useMutation(
    (updateInvitationDto: UpdateInvitationDto) =>
      updateInvitation(updateInvitationDto),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: (e: AxiosError) => {
        console.log(e);
        if (e.response?.status === 409) {
          toast.error('진행 중인 미션완료 후 수락이 가능합니다.');
        } else {
          toast.error('초대 수락에 실패했습니다');
        }
      },
    }
  );
};

export const getCommunityCardByUser = async (
  groupId: number,
  user: GroupMissionUser
) => {
  const { id: userId } = user;

  const comments = await getComment({ receiverId: userId });
  const { status, userMission } = await getFriendTodayDailyMission({
    userId,
    missionGroupId: groupId,
  });

  return {
    id: userId,
    name: user.name,
    avatar: user.avatar,
    comments,
    status,
    endAt: userMission.endAt,
  };
};

export const getCommunityCard = async (missionGroup: MissionGroup) => {
  const { id: groupId, users } = missionGroup;
  const communityCards = await Promise.all(
    map(users, (user) => getCommunityCardByUser(groupId, user))
  );
  return communityCards;
};

export const invalidateGroupMissions = () => {
  const queryClient = useQueryClient();
  queryClient.invalidateQueries([PATHS.ROOT]);
};
