import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { PaginationDto } from '../../api/types';
import {
  deleteUser,
  getAdminUser,
  getAdminUserMissions,
  getAdminUserPoints,
  getAdminUsers,
  getUser,
  isEmailExist,
  patchTestResult,
  updateAdminUser,
  updateUser,
} from '../../api/users';
import { PATHS } from '../../api/users/paths';
import { Paginated, User } from '../../types';
import {
  EmailExistDto,
  GetUserDto,
  TestResultDto,
} from './../../api/users/type';

export const useUser = (getUserDto: GetUserDto) => {
  return useQuery(PATHS.getUserById(getUserDto.id), () => getUser(getUserDto));
};

export const useEmailExist = (
  handleExistUser: () => void,
  handleNewUser: () => void
) => {
  return useMutation(
    (emailExistDto: EmailExistDto) => isEmailExist(emailExistDto),
    {
      onSuccess: (res) => {
        if (res.result) {
          handleExistUser();
        } else {
          handleNewUser();
        }
      },
      onError: (e) => {
        console.log(e);
      },
    }
  );
};

export const useUpdateTestResult = (onSuccess?: () => void) => {
  return useMutation(
    (testResultDto: TestResultDto) => patchTestResult(testResultDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
      },
      onError: () => {
        toast.error('검사 정보 업로드에 문제가 발생했습니다.');
      },
    }
  );
};

export const useUpdateUser = (onSuccess: () => void) => {
  return useMutation(
    (updateUserDto: Partial<User>) => updateUser(updateUserDto),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        toast.error('정보 수정에 실패했습니다.');
      },
    }
  );
};

export const useDeleteUser = (onSuccess: () => void) => {
  return useMutation(() => deleteUser(), {
    onSuccess: () => {
      onSuccess();
    },
    onError: () => {
      toast.error('회원 탈퇴에 실패했습니다.');
    },
  });
};

export const useAdminUsers = (paginationDto: PaginationDto) => {
  return useQuery<Paginated<User>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAdminUsers(paginationDto),
    { keepPreviousData: true }
  );
};

export const useAdminUser = (getUserDto: GetUserDto) => {
  return useQuery(PATHS.getUserById(getUserDto.id), () =>
    getAdminUser(getUserDto)
  );
};

export const useUpdateAdminUser = (onSuccess: () => void) => {
  return useMutation(
    (updateUserDto: Partial<User>) => updateAdminUser(updateUserDto),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        toast.error('정보 수정에 실패했습니다.');
      },
    }
  );
};

export const useAdminUserMissions = (getUserDto: GetUserDto) => {
  return useQuery(PATHS.getUserMissionsById(getUserDto.id), () =>
    getAdminUserMissions(getUserDto)
  );
};

export const useAdminUserPoints = (getUserDto: GetUserDto) => {
  return useQuery(PATHS.getUserPointsById(getUserDto.id), () =>
    getAdminUserPoints(getUserDto)
  );
};
