import { FC } from 'react';
import { UserDailyMissionStatus } from '../api/user-daily-missions/enum';
import { ReactComponent as DailyIcon } from '../assets/svg/icon-daily.svg';
import { ReactComponent as FailedIcon } from '../assets/svg/icon-failed.svg';
import { ReactComponent as SuccessIcon } from '../assets/svg/icon-success.svg';

interface DateStampProps {
  status: UserDailyMissionStatus;
  date: string;
  index: number;
}

export const DateStamp: FC<DateStampProps> = ({ status, date, index }) => {
  const day = index + 1;
  let stamp;

  if (status === UserDailyMissionStatus.FAILED) {
    stamp = <FailedIcon />;
  } else if (status === UserDailyMissionStatus.SUCCESS) {
    stamp = <SuccessIcon />;
  } else {
    stamp = (
      <div className="relative flex items-center justify-center">
        <span className="absolute text-12">{day}</span>
        <DailyIcon />
      </div>
    );
  }

  return (
    <div className="m-2">
      <>{stamp}</>
      <p className="mt-1 text-center text-11">{date}</p>
    </div>
  );
};
