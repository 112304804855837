import { useState } from 'react';
import { map } from 'lodash';
import { CategoryType } from '../../../api/categories/enum';
import { useCategory, useCreateCategory } from '../../../hooks/categories';

const typeList: CategoryType[] = [
  CategoryType.CANCER,
  CategoryType.HEALTH,
  CategoryType.NUTRIENT,
  CategoryType.SKINCARE,
];

export default function CategoriesApiTest() {
  const [selectedType, setSelectedType] = useState<CategoryType>(
    CategoryType.CANCER
  );
  const [categoryName, setCategoryName] = useState('');
  const { data: categories } = useCategory({ categoryType: selectedType });
  const { mutate: createCategoryMutate } = useCreateCategory();

  const createCategory = () => {
    createCategoryMutate({
      categoryType: selectedType,
      name: categoryName,
      isOpen: true,
    });
    setCategoryName('');
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <h2>Categories</h2>
      <fieldset className="my-4">
        <legend>select categoryType</legend>
        {typeList &&
          map(typeList, (type) => (
            <div key={type} className="m-2 flex">
              <input
                name={type}
                value={type}
                type="radio"
                onChange={() => {
                  setSelectedType(type);
                }}
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                checked={selectedType === type}
              />
              <label
                htmlFor={type}
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {type}
              </label>
            </div>
          ))}
      </fieldset>

      <h3>현재 타입에 카테고리 추가</h3>
      <div className="mt-1 mb-4 flex">
        <input
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
        <button
          className="m-1 h-10 w-10 bg-black text-white "
          disabled={categoryName === ''}
          onClick={createCategory}
        >
          추가
        </button>
      </div>
      <div>
        {categories &&
          map(categories, (category) => (
            <div
              key={category.id}
              className="rounded-min mr-3 inline-block bg-red-500 px-2 py-1 text-xs font-bold text-white"
            >
              {category.name}
            </div>
          ))}
      </div>
    </div>
  );
}
