import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Sidebar } from '../components/Sidebar';
import { AdminHeader } from './components/AdminHeader';
import { AdminMain } from './components/AdminMain';
import { ContentsAdd } from './pages/contents/ContentsAdd';
import { ContentsDetail } from './pages/contents/ContentsDetail';
import { ContentsList } from './pages/contents/ContentsList';
import { AdminLogin } from './pages/login/AdminLogin';
import { MissionInfo } from './pages/mission/MissionInfo';
import { MissionList } from './pages/mission/MissionList';
import { MissionReview } from './pages/mission/MissionReview';
import { SurveyAdd } from './pages/survey/SurveyAdd';
import { SurveyDetail } from './pages/survey/SurveyDetail';
import { SurveyList } from './pages/survey/SurveyList';
import { UserDetail } from './pages/users/UserDetail';
import { UserEdit } from './pages/users/UserEdit';
import { UserList } from './pages/users/UserList';
import { UserMission } from './pages/users/UserMission';

export const Admin = () => {
  const { pathname } = useLocation();
  return (
    <div className="flex h-screen">
      {pathname !== '/admin/login' && (
        <Sidebar>
          <Sidebar.Title>Admin</Sidebar.Title>
          <Sidebar.Menu>
            {/* <div className="pt-6 pb-2 font-semibold">회원 관리</div> */}
            <Sidebar.Menu.Item
              className="text-16 font-semibold"
              text="회원관리"
              to="/admin/users"
            />

            <div className="pt-4 pb-2 pl-2 font-semibold">미션 관리</div>
            <Sidebar.Menu.Item
              className="pl-4"
              text="카테고리 및 미션 관리"
              to="/admin/mission/list"
            />
            <Sidebar.Menu.Item
              className="pl-4"
              text="미션 후기 관리"
              to="/admin/mission/review"
            />

            <Sidebar.Menu.Item
              className="text-16 font-semibold"
              text="설문관리"
              to="/admin/survey/list"
              selected={pathname.startsWith('/admin/survey')}
            />

            <Sidebar.Menu.Item
              className="text-16 font-semibold"
              text="콘텐츠관리"
              to="/admin/contents/list"
              selected={pathname.startsWith('/admin/contents')}
            />
          </Sidebar.Menu>
        </Sidebar>
      )}

      <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
        <AdminHeader />
        <AdminMain>
          <Switch>
            <Route path="/admin/contents/list" component={ContentsList} />
            <Route path="/admin/contents/add" component={ContentsAdd} />
            <Route path="/admin/contents/:id" component={ContentsDetail} />
            <Route path="/admin/mission/list" component={MissionList} />
            <Route path="/admin/mission/review" component={MissionReview} />
            <Route path="/admin/mission/missioninfo" component={MissionInfo} />
            <Route path="/admin/survey/list" component={SurveyList} />
            <Route path="/admin/survey/add" component={SurveyAdd} />
            <Route path="/admin/survey/:id" component={SurveyDetail} />
            <Route path="/admin/users/:id/edit" component={UserEdit} />
            <Route path="/admin/users/:id" component={UserDetail} />
            <Route path="/admin/users/mission" component={UserMission} />
            <Route path="/admin/users" component={UserList} />
            <Route path="/admin/login" component={AdminLogin} />
            <Route path="/">
              <Redirect to="/admin/login" />
            </Route>
          </Switch>
        </AdminMain>
      </div>
    </div>
  );
};
