import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { PATHS as CategoryPath } from '../../api/categories/paths';
import {
  getTestResultsByCategory,
  updateTestResults,
} from '../../api/test-results';
import { PATHS } from '../../api/test-results/paths';
import { UpdateTestResultDto } from './../../api/test-results/type';

export const useTestResults = (categoryId: number) => {
  return useQuery([PATHS, categoryId], () =>
    getTestResultsByCategory(categoryId)
  );
};

export const useUpdateTestResults = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateTestResultDto: UpdateTestResultDto) =>
      updateTestResults(updateTestResultDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        queryClient.invalidateQueries(CategoryPath.MISSIONS);
      },
      onError: () => {
        toast.error('업데이트에 실패했습니다.');
      },
    }
  );
};
