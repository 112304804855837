import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BottomFixedButton } from '../../../../components/BottomFixedButton';
import { TextField } from '../../../../components/TextField';
import { TopNavBar } from '../../../../components/TopNavBar';
import { useFindEmail } from '../../../../hooks/auth';

interface StateType {
  phoneNumber: string;
}

export const FindEmailSuccessPage = () => {
  const [email, setEmail] = useState('');
  const { state } = useLocation<StateType>();
  const { goBack } = useHistory();
  const { mutate: findEmailMutate } = useFindEmail((result: string) =>
    setEmail(result)
  );
  useEffect(() => {
    if (state?.phoneNumber) {
      findEmailMutate({ phoneNumber: state?.phoneNumber });
    } else {
      toast.error('잘못된 접근입니다.');
      goBack();
    }
  }, []);

  return (
    <>
      <TopNavBar title="이메일 찾기" />

      <h2 className="mt-10 mb-6 leading-normal">가입한 이메일 주소입니다.</h2>

      <TextField label="이메일" value={email} disabled />

      <BottomFixedButton to="/login/email" text="로그인하기" />
    </>
  );
};
