import React, { forwardRef, InputHTMLAttributes } from 'react';
import { useId } from 'react-id-generator';
import { Label } from './Label';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassname?: string;
  helper?: string;
  removeGlobalCSS?: boolean;
  parentClassName?: string;
  isError?: boolean;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      removeGlobalCSS = false,
      parentClassName = '',
      className = '',
      label,
      labelClassname: labelClassName = '',
      helper,
      isError = true,
      ...props
    },
    ref
  ) => {
    const [id] = useId(1, 'textfield');
    const borderColor = helper && isError ? 'border-red-500' : '';

    return (
      <div className={`label-col ${parentClassName}`}>
        {label && (
          <Label htmlFor={id} text={label} className={labelClassName} />
        )}
        <input
          ref={ref}
          id={id}
          className={`${
            removeGlobalCSS ? '' : 'textfield'
          }  ${borderColor} ${className}`}
          {...props}
        />
        {helper && (
          <p
            className={`text-sm ${isError ? 'text-red-500' : 'text-green-500'}`}
          >
            {helper}
          </p>
        )}
      </div>
    );
  }
);
