import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { PATHS } from '../../../api/points/paths';
import {
  Point,
  PointHistoryResponse,
  PointTotalResponse,
} from '../../../api/points/type';
import { TopNavBar } from '../../../components/TopNavBar';
import { POINT_DESC } from '../../../constants';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { fetcher } from '../../../plugins/react-query';

export const MyPointsPage = () => {
  const { data: point } = useQuery<PointTotalResponse>(PATHS.TOTAL, fetcher);
  const { data: history } = useQuery<PointHistoryResponse>(
    PATHS.HISTORY,
    fetcher
  );
  if (!point || !history) return null;
  const pointHistory = history.points;

  return (
    <>
      <TopNavBar title="힐링 스코어" />
      <div className="-mx-5 mb-5 flex justify-between bg-brand-1 px-5 py-8 text-white">
        <span className="">현재 보유 힐링스코어</span>
        <h3>{point.total.toLocaleString()}P</h3>
      </div>

      {pointHistory.map((point) => (
        <PointsCard key={point.id} point={point} />
      ))}
    </>
  );
};

interface PointsCardProps {
  point: Point;
}

const PointsCard: FC<PointsCardProps> = ({ point }) => {
  const { createdAt, amount, type } = point;
  return (
    <div className="mb-4 flex flex-col space-y-2 border-b pb-4">
      <span className="text-12">
        {utcToLocalFormat(createdAt, MomentFormat.YYYYMMDD)}
      </span>

      <div className="flex items-center justify-between">
        <span className="">{POINT_DESC[type]}</span>
        <span className="font-bold">
          {amount > 0 ? '+' : ''}
          {amount.toLocaleString()}P
        </span>
      </div>
    </div>
  );
};
