import React, { useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import Onboarding1Img from '../../assets/img/onboarding-1.svg';
import Onboarding2Img from '../../assets/img/onboarding-2.svg';
import Onboarding3Img from '../../assets/img/onboarding-3.svg';
import Onboarding4Img from '../../assets/img/onboarding-4.svg';
import { BottomFixedButton } from '../../components/BottomFixedButton';
import { SwiperCard } from '../../components/swiper/SwiperCard';
import {
  LOCAL_STORAGE_KEY,
  setItemInLocalStorage,
} from '../../plugins/local-storage';

const ONBOARDING_DATA = [
  {
    id: 1,
    src: Onboarding1Img,
    description: `암이나 성인병 그리고 영양결핍 같은\n다양한 건강문제를 미리\n예방하기 위해서는 어떤 습관이 필요할까요?`,
  },
  {
    id: 2,
    src: Onboarding2Img,
    description: `유전자 검사를 통해 나에게\n예상되는 건강 문제를 미리\n알았다면 어떻게 해야 할까요?
  `,
  },
  {
    id: 3,
    src: Onboarding3Img,
    description: `건강문제를 예방할 수 있는\n습관을 꾸준히 실천하기 어렵다면\n어떻게 해야 할까요?
  `,
  },
  {
    id: 4,
    src: Onboarding4Img,
    title: '최선의 치료는 예방입니다!',
    description: `당신에게 꼭 필요한 건강 예방 습관 루틴을 만들어보세요.\n아이힐미로 쉽고 재미있게 건강한 삶을 지키세요.
  `,
  },
];

export const OnboardingPage = () => {
  const [isEnd, setIsEnd] = useState(false);

  const onReachEnd = () => {
    setItemInLocalStorage(LOCAL_STORAGE_KEY.ONBORDING, { end: 1 });
    setIsEnd(true);
  };

  return (
    <>
      <div className="-mx-5 -mb-8 min-h-screen bg-brand-2 pt-12 ">
        <SwiperCard onReachEnd={onReachEnd}>
          {ONBOARDING_DATA.map((splash) => (
            <SwiperSlide
              key={splash.id}
              className="flex flex-col items-center justify-center space-y-4 pb-4"
            >
              <div className="relative h-[240px] w-[240px]">
                <img
                  src={splash.src}
                  alt=""
                  className="absolute h-full w-full object-cover"
                />
              </div>

              <div className="space-y-3 text-center">
                {splash.title && <h3>{splash.title}</h3>}
                <p className="whitespace-pre-wrap text-15 text-gray-600">
                  {splash.description}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </SwiperCard>
      </div>
      <BottomFixedButton disabled={!isEnd} to="/login" text="지금 시작하기" />
    </>
  );
};
