import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { modalState } from '../../plugins/ridge';

interface AnimationLayoutProps {
  children: ReactNode;
  open: boolean;
  // left: number;
}

export const AnimationLayout: React.FC<AnimationLayoutProps> = ({
  children,
  open,
  // left,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 max-w-md overflow-y-auto"
        // style={{ left }}
        onClose={modalState.reset}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="fixed inset-0 max-w-md bg-gray-900 bg-opacity-50 transition-opacity"
              // style={{ left }}
            />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
